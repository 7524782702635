import React, { useEffect } from "react";
import "../../App.css";
import "./Finance.scss";
import CarValueMarket from "./1_CarValueMarket";
import CarInsuranceCalculator from "./2_CarInsuranceCalculator";
import RoadTaxCalculator from "./3_RoadTaxCalculator";
import DutyListDisplay from "./4_DutyListDisplay";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import LoanCalculator from "./6_LoanCalculator";

// main
function FinanceItem() {
  // slider settings
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`,
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="wrap-fin-hero">
        <div className="fin-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-fin">
        <div className="fin-container-1">
          <div className="fin-header">
            <h2>Finance</h2>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="fin-con-1-tab-row">
              <Col sm={3} className="fin-con-1-tab-row-col">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item className="fin-con-1-nav-item">
                    {/* <Nav.Link className="fin-con-1-nav-link" eventKey="first">Car Value Market</Nav.Link> */}
                    <Nav.Link className="fin-con-1-nav-link" eventKey="first">
                      Loan Calculator
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="fin-con-1-nav-item">
                    <Nav.Link className="fin-con-1-nav-link" eventKey="second">
                      Car Insurance Calculator
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="fin-con-1-nav-item">
                    <Nav.Link className="fin-con-1-nav-link" eventKey="third">
                      Road Tax Calculator
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="fin-con-1-nav-item">
                    <Nav.Link className="fin-con-1-nav-link" eventKey="fourth">
                      Duty List Display
                    </Nav.Link>
                  </Nav.Item>

                  {/* <Nav.Item className="fin-con-1-nav-item">
                                        <Nav.Link className="fin-con-1-nav-link" eventKey="fifth">Miscellaneous</Nav.Link>
                                    </Nav.Item> */}
                </Nav>
              </Col>

              <Col sm={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {/* <CarValueMarket /> */}
                    <LoanCalculator />
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    <CarInsuranceCalculator />
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    <RoadTaxCalculator />
                  </Tab.Pane>

                  <Tab.Pane eventKey="fourth">
                    <DutyListDisplay />
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="fifth">
                                        fifth Pane
                                    </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>

        <div className="fin-container-2">
          <h3>Useful Links</h3>

          <div className="fin-cont-2-item">
            <div className="fin-cont-2-item-1">
              <a href="https://scrut.my/" target="_blank" rel="noreferrer">
                <h4>Japan Auction History Checker</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="https://www.vehiclecheck.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                <h4>HPI Check</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="https://www.oto.my/tool/car-market-value-guide/"
                target="_blank"
                rel="noreferrer"
              >
                <h4>Car Market Value Guide</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="http://www.jpj.my/JPJ_Latest_Number_Plates.htm"
                target="_blank"
                rel="noreferrer"
              >
                <h4>JPJ Car Plate Checker Calculators</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinanceItem;
