export default function TermsAndConditionsComponent() {
  return (
    <>
      <div className="termscondition-content-1">
        <p>
          Autopitonline.com is pleased to provide you with access to the network
          of websites, apps and related services operated by Autopit.com Limited
          and its related bodies corporate (“Autopit”) the Autopit vehicle
          search engine wherever distributed on the internet and Autopit broader
          e-commerce platforms on the above websites allowing the sale of
          non-vehicle products by third parties.
        </p>
        <p>
          By accessing or using the Autopit, you agree to be bound by these
          terms and conditions of use (“Terms”) and any other terms and
          conditions referred to in these Terms or notified to you on the
          Autopit each as amended from time to time
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>1. About the Autopit</h4>
        <p>
          (a) Autopit publishes or makes available on the Autopit information,
          advertisements, products and services, vehicle data, valuations,
          software and other content supplied by Autopit.
        </p>
        <p>
          (b) Autopit does not warrant or endorse the accuracy or completeness
          of, or the representations made in, the Material, or any information
          received as a result of using the Autopit (including via links to
          third party websites). You are responsible for assessing the accuracy
          of the Material and any such information and rely on it at your own
          risk.
        </p>
        <p>
          (c) Unless expressly stated by Autopit, Autopit does not provide any
          warranty in relation to any goods or services advertised for sale on
          the Autopit by third parties including, but not limited to, any
          representation or warranty that the goods or services are of
          merchantable quality, fit or safe for the purpose intended, as
          described by the seller or owned by the seller.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>2. You are responsible for use of the Autopit</h4>
        <p>
          (a) The Material made available on (or via) the Autopit is provided as
          general information only. The Material is not professional, expert or
          other advice and it is not a substitute for such advice. The Material
          may not be appropriate, correct or sufficient for your circumstances,
          should not be relied on as the only reason you do or don't do
          anything, and may not be continually accessible or free from errors or
          viruses.
        </p>
        <p>
          (b) You indemnify Autopit against any liability, loss, claim or demand
          if Autopit (or any of its officers, representatives, employees or
          agents) suffers any loss or damage or incurs any cost in connection
          with a breach by you (or any of your officers, representatives,
          employees or agents) of these Terms- including any warranties you
          provide in them.
        </p>
        <p>
          (c) You must keep your username and password to access the Autopit
          secure and confidential and not provide those details to any third
          party. You accept all liability relating to unauthorised use of any
          username and password issued to you.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>3. Goods and services provided by Autopit</h4>
        <p>
          Any goods or services offered by Autopit (beyond access to the
          Autopit) are subject to additional terms and conditions. To the extent
          of any inconsistency between these Terms and the additional terms and
          conditions, the additional terms and conditions will prevail to the
          extent of the inconsistency.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>4. Autopit’ liability to you is limited</h4>
        <p>
          (a) Other than as set out in this clause, and to the full extent
          permitted by law, all rights, remedies, conditions, guarantees and
          implied warranties in respect of any goods or services provided by
          Autopit are hereby excluded.
        </p>
        <p>
          (b) Subject to subclause 6(c), and except to the extent that liability
          cannot be excluded under applicable laws, Autopit will not be liable
          to you for claims arising out of or in connection with your access and
          use of the Autopit and related services howsoever arising, including
          in contract, tort (including negligence), indemnity, strict liability,
          breach of warranty or statute.
        </p>
        <p>
          (c) To the full extent permitted by the law, Autopit will only be
          liable to you pursuant to any guarantee, right or contractual term
          that arises, is created or is implied by operation of law and that
          cannot be excluded provided that, any such liability of Autopit is
          limited, at Autopit’ option, to (A) replacing or repairing the
          relevant goods, (B) supplying goods equivalent to the relevant goods,
          (C) supplying the relevant services again or (D) paying the cost of
          such replacement, repairs or supply.
        </p>
        <p>
          (d) To the full extent permitted by law, Autopit will not be liable to
          you for any indirect or consequential loss or damage, including loss
          of use, production, profit, revenue, business, data, contractor
          anticipated savings, delay or for any financing costs, increase in
          operating costs or economic loss.
        </p>
        <p>
          (e) For the purposes of this section, the term Autopit means Autopit,
          its officers, employees, contractors and agents, whether individually
          or collectively.
        </p>
        <p>
          (f) This clause 6 will survive any expiry or termination of these
          Terms.
        </p>
        <p>
          (g) Where you have purchased a specific product or service from
          Autopit, please check your additional specific product or service
          terms for liability provisions which may prevail over this clause.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>5. Copyright, trademarks and licence</h4>
        <p>
          (a) Material on the Autopit is protected by copyright and intellectual
          property laws. All rights not expressly granted under these Terms are
          reserved by Autopit. Unless otherwise indicated on the Autopit, the
          Material is owned by or licensed to Autopit and is subject to
          copyright (the "Copyright Material").
        </p>
        <p>(b) You may: </p>
        <ul>
          <li>
            only copy or reproduce the Copyright Material for the purpose of
            browsing the Autopit or making a copy for your own personal, private
            or research use, provided that such use constitutes fair use under
            the Copyright Act 1968 (Cth); and not alter or modify the Copyright
            Material in any way or remove any legal notice associated with it.
          </li>
        </ul>
        <p>
          (c) Autopit and other names of Autopit’ products and services are
          registered or unregistered trademarks of Autopit. Other product and
          company names mentioned on the Autopit may be the trademarks of third
          party owners. Nothing displayed on the Autopit should be construed as
          granting you any intellectual property rights including the right to
          use any trade mark without the relevant owner’s express written
          consent.
        </p>
        <p>
          (d) In consideration of Autopit allowing you to access and use the
          Autopit, you grant to Autopit a non-exclusive, payment free,
          perpetual, irrevocable, sub-licensable licence to reproduce, modify,
          delete, adapt and publish any material you post, upload or otherwise
          transmit via the Autopit for such purposes as Autopit reasonably
          determines from time to time (subject to Autopit’ Privacy Policy). You
          warrant that you have all necessary rights in the content that you
          contribute and that the content does not infringe any law or third
          party rights. You agree to Autopit taking any actions that may
          otherwise infringe your moral rights in relation to the material.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>6. Your Autopit account (previously “member account”)</h4>
        <p>
          (a) If you open a Autopit account, additional products and services
          (e.g. fuel discounts) may be made available to you on specific
          supplementary terms.
        </p>
        <p>
          (b) By opening a Autopit account, you agree to receive electronic
          communications from Autopit about its products and services, however
          you may opt out of this via your account preference centre.
        </p>
        <p>
          (c) If you add details of an existing vehicle into your Autopit
          account, you agree to Autopit providing those details to car dealers
          along with any vehicle enquiry you make so that dealers can make you
          trade in offers. You can opt out of this at the time of enquiry.
        </p>
        <p>
          (d) If you create a username and user profile photo connected to your
          Autopit account, or choose to verify your identity, Autopit may
          display these publicly in your Autopit communications, including in
          adverts or one-to-one messages.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>7. Communications services</h4>
        <p>
          (a) Autopit may provide you with the ability to communicate with
          Autopit, sellers, third party advertisers and other users of the
          Autopit through online forms, interactive voice response, electronic
          requests and enquiries, bulletin boards, blogs, competition entries,
          online forums, inbound phone number services and other forms of
          communication (“Communications Services”).
        </p>
        <p>
          (b) Unless stated otherwise, please assume that Communications
          Services are public and not private communications, and you agree to
          publication of your communications. Autopit may, in its discretion,
          collect, store or scan communications, however you should not rely on
          it doing so.
        </p>
        <p>
          (c) Use of the Communications Services is provided on condition that
          you do not:{" "}
        </p>
        <ul>
          <li>
            transmit any information or material which is incorrect, misleading
            or deceptive;
          </li>
          <li>
            transmit any racist, sexist, defamatory, infringing, obscene,
            abusive, indecent or unlawful information or any material otherwise
            deemed by Autopit to be inappropriate;
          </li>
          <li>
            transmit spam, chain letters, contests, junk mail, surveys or other
            mass messaging;
          </li>
          <li>use the Communications Services for any unlawful purpose;</li>
          <li>
            post or upload files that contain software or other material or
            information in breach of any person's intellectual property or
            privacy rights;
          </li>
          <li>
            upload files that contain viruses, corrupted files, worms, defects
            or any other similar software or programs that may damage the
            operation of another user's computer or damage any of the Autopit
            (infrastructure or content);
          </li>
          <li>
            delete any author attributions, legal notices or proprietary
            designations or labels in any file that is uploaded;
          </li>
          <li>
            advertise or offer to sell any goods or services other than as
            approved in writing by Autopit;
          </li>
          <li>
            download any file posted by another user of the Communications
            Services that you know, or reasonably should know, is unlawful or
            contrary to these Terms;
          </li>
          <li>
            excessively use the Communications Services in a manner which may
            hinder or prevent Autopit from providing services to any other
            person or which may threaten the integrity or use by any person of
            the Autopit; or
          </li>
          <li>
            authorise, aid, abet encourage or incite any person to do any of the
            above acts.
          </li>
        </ul>
        <p>
          (d) Autopit may delete, edit, refuse to post or remove in whole or
          part any information or material uploaded or posted using the
          Communications Services. Autopit may also suspend or terminate the use
          of the Autopit of any person determined by Autopit to be in breach of
          these Terms.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>8. Autopit and your privacy</h4>
        <p>
          By using the Autopit, you agree to the Autopit Privacy Policy which
          sets out how Autopit collects, uses and discloses your personal
          information.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>9. Terms applicable to Autopit Stores</h4>
        <p>
          Autopit operates the Stores which allow third party retailers (each a
          “Seller”) to list and sell goods or services and transact directly
          with customers. As a result, Autopit is not a party to any transaction
          between you and the Seller that occurs via the Stores. In addition,
          Autopit does not control the content or accuracy of any product
          listing or the quality, shipping, delivery or returns approvals of any
          goods or services sold via the Stores. Without limiting any other
          provision of these Terms, the following additional terms apply to your
          use of and access to the Stores:
        </p>
        <p>
          As the Stores are platforms for Sellers to list for sale and sell
          their goods or services to customers, you agree that, to the maximum
          extent permitted by law, Autopit is not responsible for the
          transactions that occur between you and a Seller, nor is Autopit
          responsible for any of the Seller’s goods or services. To the maximum
          extent permitted by law, Autopit makes no warranty or representation
          regarding the standard of any goods or services to be supplied by a
          Seller or the actions, omissions or conduct of a Seller.
        </p>
        <p>
          All information about goods or services listed for sale in the Stores
          (including without limitation all product descriptions, images and
          specifications, pricing information and the policies referred to in
          paragraphs (e) and (f) below) is provided by the relevant Sellers.
          Except as required by law, Autopit is not responsible for any
          inaccuracies, misstatements or errors in this information. You agree
          to make your own enquiries to verify the information provided and to
          assess the suitability of goods or services before you order the goods
          or services through the Stores.
        </p>
        <p>
          A listing in the Stores will contain the relevant Seller’s details.
          When you make an order through the Stores, you acknowledge and agree
          that the Seller may impose their own terms and conditions in relation
          to the sale of their goods or services, which will be displayed or
          otherwise made available to you through the Store (including without
          limitation the policies that are described further in paragraphs (f)
          and (g) below). Unless expressly permitted by Autopit in writing,
          Autopit will not be a party to any agreement or additional terms that
          apply between you and a Seller.
        </p>
        <p>
          The promotion of goods or services via the Stores is an invitation to
          treat- not an offer to sell.
        </p>
        <p>
          The Seller’s applicable policy for cancellations, returns (including
          repairs and replacements) and refunds will apply to any transaction
          between you and that Seller via the Stores. The collection, use and
          disclosure of your personal information may also be subject to the
          Seller’s privacy policy. Before purchasing goods or services via the
          Stores, you should carefully read the Seller’s cancellation, return
          and refund policy, which will be displayed or otherwise made available
          to you through the Stores.
        </p>
        <p>
          The Seller’s applicable policy for shipping and delivery of goods or
          services will apply to any transaction between you and that Seller via
          the Stores. Before purchasing goods or services via the Stores, you
          should carefully read the Seller’s shipping and delivery policy, which
          will be displayed or otherwise made available to you through the
          Stores.
        </p>
        <p>
          When an order is placed for a Seller’s goods or services, you will
          receive an email confirmation that your order has been placed and
          Autopit will send the order to the Seller for confirmation. Whilst
          stock levels are regularly adjusted, on occasion a Seller may reject
          your order in its discretion. Where a Seller rejects your order, you
          will be notified by email and Autopit will refund you for the order
          and associated shipping charges to the relevant payment method used
          for purchase. You are responsible for any loss arising from
          discrepancies or errors in your order caused by you.
        </p>
        <p>
          The prices of goods or services, delivery and other charges are shown
          in Australian dollars. All payments must be received by Autopit in
          full prior to the dispatch of goods or services by a Seller. If your
          payment is not received or is declined for any reason, including where
          fraud is suspected, your order will be cancelled and, if applicable,
          you will receive a refund of your order to the relevant payment method
          used for purchase.
        </p>
        <p>
          To cancel, return or request a refund from a Seller or raise a problem
          with the goods or services purchased from a Seller, you should submit
          a request to us via the relevant ‘Help Centre’ for the Store you used.
          Autopit will then investigate the issue further, submit your request
          on your behalf to the relevant Seller (as necessary) and provide
          reasonable assistance to you in relation to pursuing resolution of the
          issue. Where a refund is permitted in accordance with the Seller’s
          policies, your refund will be issued using the same payment method
          used for purchase. Where Autopit considers that it cannot reasonably
          resolve the issue, it may require you to liaise directly with the
          Seller to pursue a resolution.
        </p>
        <p>
          You must not use the Stores unless you are aged 18-years-old or older
          and by making an order for goods or services through the Stores, you
          warrant to us that you are aged 18-years-old or older, and that you
          have the legal right and capacity to order goods or services through
          the Stores.
        </p>
      </div>

      <div className="termscondition-content-1">
        <h4>10. Autopit may change its terms</h4>
        <p>
          (a) Autopit may modify or amend its terms and conditions for its goods
          or services from time to time. Modifications of these Terms (and terms
          and conditions linked to these Terms) take effect once displayed on
          this website or app, however will only apply to your use of the
          Autopit after that date.
        </p>
        <p>
          (b) Autopit may, acting reasonably, cease to provide you with access
          to the Autopit or any of its services without notice.
        </p>
        <p>(c) All rights not expressly granted are reserved.</p>
      </div>
    </>
  );
}
