import React, { useState } from "react";
import "../../App.css";
import "./Finance.scss";

// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Form from 'react-bootstrap/Form';

function ImportResultComponent(props) {
  // const [carPrice, setCarPrice] = useState(100000);
  // const [downPayment, setDownPayment] = useState(10000);
  // const [interestRate, setInterestRate] = useState(350);
  // const [currencyRate, setCurrencyRate] = useState(0.0325);
  // const [jValue, setJValue] = useState(0);
  // const [mValue, setMValue] = useState(0);
  // const [chooseSite, setChooseSite] = useState(0);
  // const [currency, setCurrency] = useState(["Japan (Yen)", 1]);
  // const [brand, setBrand] = useState([ "Brand", 0 ]);
  // const [model, setModel] = useState([ "Model", 0]);
  // const [vehicleType, setVehicleType] = useState([ "Vehicle Type", 0 ]);
  // const [month, setMonth] = useState([ "Select Month", 0 ]);
  // const [year, setYear] = useState([ "Select Year", 0 ]);

  // const [monthlypayment, setMonthlyPayment] = useState(0);

  return (
    <>
      <div className={`fin-tab-pane-2 ${props.displayResult}`}>
        <div className="fin-tab-pane-2-header">
          <h3>Import Calculator</h3>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Car Model Specification</h4>
            {/* <input type="text" value='(Q0010) ALFA ROMEO Stelvio BiTurbo Quadrifoglio Nring ( S/S ) SUV 5D AWD ( 510 BHP ) AT [ 2,891cc ]' /> */}
            <p className="input_title_import-result">
              {props.Car_ModelSpecification}
            </p>
          </div>
          <div
            className="fin-tab-pane-2-input"
            style={{ marginBottom: "30px" }}
          >
            <h4 className="input_title_import">Car Age</h4>
            {/* <input type="text" value='56 Months Old [ 05/2018 ]' /> */}
            <p className="input_title_import-result">{props.Car_Age}</p>
          </div>
          <div
            className="imp-cal-item-3 flex gap-x-4 flex-nowrap"
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">Car Price</h4>
              {/* <input type="text" value='106,991.00 UK' className='input_import font-bold' style={{paddingLeft:'20px'}}/> */}
              <p className="input_title_import-result">{props.Car_Price}</p>
            </div>
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">Shipment Fees</h4>
              {/* <input type="text" value='2,300.00 UK' className='input_import font-bold' style={{paddingLeft:'20px'}}/> */}
              <p className="input_title_import-result">{props.Shipment_Fees}</p>
            </div>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">
              Port arrival Total CIF Fees before clearance{" "}
              <span>( {props.Port_Summary} )</span>
            </h4>
            {/* <input type="text" value='MYR 595,996.61' /> */}
            <p className="input_title_import-result">
              {props.Port_Arrival_Fee}
            </p>
            <p
              className="calculator_note"
              style={{ border: "none", paddingBottom: "0" }}
            >
              {props.Port_Conversion_Rate} (Exchange rates are updated every
              Sunday following PBB rates)
            </p>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Tax duty with documentation</h4>
            {/* <input type="text" value='MYR 176,439.70' /> */}
            <p className="input_title_import-result">{props.Tax_Duty}</p>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Cost Price</h4>
            {/* <input type="text" value='MYR 772,436.31' /> */}
            <p className="input_title_import-result">{props.Cost_Price}</p>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Approve Permit (AP)</h4>
            {/* <input type="text" value='MYR 772,436.31' /> */}
            <p className="input_title_import-result">{props.Approve_Permit}</p>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Service Fees (代购费)</h4>
            {/* <input type="text" value='3%' /> */}
            <p className="input_title_import-result">{props.Service_Fees}</p>
          </div>
          <div
            className="fin-tab-pane-2-input"
            style={{ marginBottom: "30px" }}
          >
            <h4 className="input_title_import">
              Import Final Price / Bidding Won Price
            </h4>
            {/* <input type="text" value='MYR 795,609.40' /> */}
            <p className="input_title_import-result">
              {props.Import_Final_Price}
            </p>
          </div>

          <div
            className="imp-cal-item-4 flex gap-x-4 flex-nowrap"
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">1st Payment</h4>
              {/* <input type="text" value='MYR 595,996.61' className='input_import font-bold' style={{paddingLeft:'20px'}}/> */}
              <p className="input_title_import-result">{props.First_Payment}</p>
              <p
                className="calculator_note"
                style={{ border: "none", paddingBottom: "0" }}
              >
                *Shall be paid within 3 working days upon car purchased
              </p>
            </div>
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">
                2nd Payment (Remaining balance)
              </h4>
              {/* <input type="text" value='MYR 199,612.79' className='input_import font-bold' style={{paddingLeft:'20px'}}/> */}
              <p className="input_title_import-result">
                {props.Second_Payment}
              </p>
              <p
                className="calculator_note"
                style={{ border: "none", paddingBottom: "0" }}
              >
                *Shall be paid upon car arrival in our showroom
              </p>
            </div>
          </div>

          <button
            onClick={props.Next_Button}
            className={`fin-tab-pane-calculate-btn ${props.next_button_classname}`}
          >
            Next
          </button>

          {/* <button onClick={e => {e.preventDefault();window.location.href="/import-result-final"}} className="fin-tab-pane-calculate-btn">
                        Next
                    </button> */}
        </div>
      </div>
    </>
  );
}

export default ImportResultComponent;
