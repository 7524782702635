import React from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import "./Footer.scss";
import { GoChevronRight, GoMail } from "react-icons/go";
import { AiFillPhone } from "react-icons/ai";
// import Dropdown from 'react-bootstrap/Dropdown';

export const NavLink = styled(Link)`
  &.active {
    color: #fff;
    font-weight: bold;
  }
`;

function Footer() {
  const download_app_1 = require("../../images/App-AppStore.png");
  const download_app_2 = require("../../images/App-GooglePlay.png");

  return (
    <>
      <div className="wrap-footer">
        <div className="footer-container-1">
          <div className="footer-content-1">
            <img src="/Autopit-02.png" alt="Autopit" />
            <p>
              Auto Pit was founded in the year 2022, focusing to provide
              customers with a professional car shopping experience, the primary
              position of the company's main service is to source used cars
              namely, recon cars imported from places such as Japan, Australia,
              or the UK. The price of these premium cars is cheaper than the
              official imports which provide customers with plenty of new car
              purchases.
            </p>
          </div>

          <div className="footer-content-2">
            <h3>Quick Link</h3>

            <div className="footer-cont-2-links">
              <NavLink to="/" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Home
              </NavLink>
              {/* <NavLink to="/about-us" target="_parent"><GoChevronRight className="f-cont-2-link-icon"/> About Us</NavLink> */}
              <NavLink to="/cars" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Cars
              </NavLink>
              <NavLink to="/finance" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Finance
              </NavLink>
              <NavLink to="/import-car" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Import Car
              </NavLink>
              <NavLink to="/contact-us" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Contact Us
              </NavLink>
              <NavLink to="/privacy-policy" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Privacy Policy
              </NavLink>
              <NavLink to="/terms-and-conditions" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Terms &
                Conditions
              </NavLink>
              <NavLink to="/refund-policy" target="_parent">
                <GoChevronRight className="f-cont-2-link-icon" /> Refund Policy
              </NavLink>
            </div>
          </div>

          <div className="footer-content-3">
            <h3>AUTOPIT GROUP SDN. BHD. (1195630-T)</h3>
            <address>
              {/* Address: NO. 1, JALAN DUTAMAS 1, <br/>
                            50480 KUALA LUMPUR W.P. <br/>
                            MALAYSIA  */}
              Address: Evolve Concept Mall,
              <br />
              Level G (EV-G-22, 22 AFI), Jalan PJU 1A/4, <br />
              47301 Petaling Jaya, <br />
              Selangor.
            </address>

            <div className="f-cont-3-item-1">
              <p>For general information and listing details</p>

              <a
                href="mailto:autopit.car@gmail.com"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <GoMail className="f-cont-3-icon" />
                autopit.car@gmail.com
              </a>

              {/* <a href="tel:+0338853511" > */}
              {/* <a href="tel:+60105201000" >
                                <AiFillPhone className="f-cont-3-icon"/>Customer Careline: +60 10-520 1000
                            </a> */}
              <a href="tel:+60377346069">
                <AiFillPhone className="f-cont-3-icon" />
                Customer Careline: +60 3-7734 6069
              </a>
            </div>

            <div className="f-cont-3-item-2">
              <img
                src={download_app_1}
                alt="Download AutoPit at App Store | AutoPit"
              />
              <a
                href="https://play.google.com/store/apps/details?id=com.autopit.app"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={download_app_2}
                  alt="Download AutoPit at Google Play | AutoPit"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-container-2">
          <p>
            Copyright © {new Date().getFullYear()} Autopit. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
