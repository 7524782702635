import React, { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";
import "./BackToTop.scss";

import { Modal, Button } from "react-bootstrap";
import { BsWhatsapp } from "react-icons/bs";

// export const Button = styled.div`
//     height: 50px;
//     color: #fff;
//     font-size: 30px;
//     cursor: pointer;

//     margin: -30px 0 0 -14px;
//     position: fixed;
//     bottom: 100px;
//     right: 0;
//     z-index: 15;
//     text-align: center;
//     background-color: rgba(0, 0, 0, 0.9);
//     border-radius: 10px 0 0 10px;
// `

const btt = {
  // width: "60px",
  height: "60px",
  color: "#fff",
  fontSize: "30px",
  cursor: "pointer",

  margin: "-30px 0 0 -14px",
  position: "fixed",
  bottom: "95px",
  right: "10px",
  zIndex: "15",
  textAlign: "center",
  // backgroundColor: "rgba(0, 0, 0, 0.9)",
  backgroundImage: "linear-gradient(to top, #000000, #323232)",
  borderRadius: "50%",
  border: 0,
};

const whatsapp = {
  cursor: "pointer",
  position: "fixed",
  bottom: "110px",
  right: "25px",
  width: "60px",
  height: "60px",
  backgroundImage: "linear-gradient(to top, #1faf38, #60d669)",
  borderRadius: "50%",
};

const whatsapp_icon = {
  color: "#fff",
  fontSize: "35px",
  position: "absolute",
  transform: "translate(13px,12px)",
};

const ChatWidgets = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openWhatsapp = () => {
    setShow(true);
  };

  const sendMessage = () => {
    setShow(false);
    window
      .open(
        "https://api.whatsapp.com/send?phone=+60123456789&text=" + message,
        "_blank",
      )
      .focus();
  };

  const processMessage = (e) => {
    setMessage(e);
  };

  const [message, setMessage] = useState("");

  return (
    <>
      <div
        style={whatsapp}
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send/?phone=60105201000&text=Hi%20there,%20I%27m%20interested%20to%20enquire%20more%20details%20about%20the%20vehicles%20in%20https://www.autopitonline.com/.",
            "_blank",
          )
        }
      >
        {/* <span style={whatsapp_icon} aria-hidden="true" data-icon="&#xe902;"></span> */}
        <BsWhatsapp style={whatsapp_icon} />
      </div>
      {/* <button style={btt}>
                <IoIosArrowUp className="backtotop_icon" onClick={scrollToTop}
                style={{display: visible ? 'inline' : 'none'}} />
            </button> */}

      <Modal
        className="whatsapp-modal"
        id="message_modal"
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <img src="/qflogo4.png" alt="Quick Fix" />
          {/* <Modal.Title>Send us a message!</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="7"
            placeholder="Send us a message"
            onChange={(e) => processMessage(e.target.value)}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={sendMessage}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChatWidgets;
