import React, { useEffect } from "react";
import "../../App.css";
import ImportCar from "../import-car/ImportResult2";

import { Helmet } from "react-helmet";

function ImportResult2() {
  useEffect(() => {
    document.title = "Finance | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/finance" />

        <meta name="canonical" content="/finance" />
        <meta property="og:url" content="/finance" />
      </Helmet>

      <ImportCar />
    </>
  );
}

export default ImportResult2;
