import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import "./Cars.scss";
// import styled from 'styled-components';
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import FilterComponent from "./Filter_Component";

import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import { GrLocation } from "react-icons/gr";
// import { FaWindows } from 'react-icons/fa';
import { Empty, Pagination } from "antd";

// main
const CarsItem = () => {
  const japan_flag = require("../../images/BG 43.png");
  const uk_flag = require("../../images/uk_flag.png");
  const [cookies] = useCookies(["accessToken"]);
  const mainDivRef = useRef(null);

  const filter_url = `https://api.autopitonline.com/api/v1/filters`;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // get cars
  const location = useLocation();
  // console.log("location", location);
  const [errorcars, setErrorCars] = useState(null);
  const [isLoadedCars, setIsLoadedCars] = useState(false);

  const [cars, setCars] = useState([]);

  const searchLocation = location.search.split("?")[1];
  const bodytype_Map = {
    "body_type=1": "Convertible",
    "body_type=2": "Coupe",
    "body_type=3": "Electric Vehicle",
    "body_type=4": "SUV",
    "body_type=5": "MPV",
    "body_type=6": "Hatchback",
    "body_type=7": "Sedan",
  };
  const getCars = () => {
    // console.log(searchLocation?.length);

    fetch(
      `https://api.autopitonline.com/api/v1/cars?per_page=100${
        searchLocation != undefined ? `&${searchLocation}` : ""
      }`,
    )
      .then((res) => res.json())
      .then(
        (response) => {
          setIsLoadedCars(true);
          setCars(response);
          // console.log('results', response);
        },
        (error) => {
          setIsLoadedCars(true);
          setErrorCars(error);
        },
      );
  };

  const [categories, setCategories] = useState([]);
  const getCategory = async () => {
    try {
      const response = await axios.get("categories", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      setCategories(response?.data?.data);
      // console.log('getCategory: ', response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCars();
    getCategory();
  }, []);

  // search cars
  const [searchCars, setSearchCars] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  // if (error) {
  //     return <div>Error: {error.message}</div>;
  // } else if (!isLoaded) {
  //     return <div>Loading...</div>;
  // } else {

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const handlePageChange = (newPage) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);

      // Scroll to the main div
      mainDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };
  const paginationData = cars.data?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  return (
    <>
      <div className="wrap-cars-hero">
        <div className="cars-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-cars" ref={mainDivRef}>
        <div className="sgcars-header">
          {/* { searchLocation != undefined ? (
                           <>
                            <div className="sgcars-breadcrumbs">
                                <a href="/cars" className="sgcars-breadcrumbs-item">Cars </a> 
                                <span className="sgcars-breadcrumbs-divider">{searchLocation}</span>
                                <h2 className="sgcars-breadcrumbs-item">{bodytype_Map[searchLocation]} </h2> 
                            </div> 
                           </>
                        ) : ( */}
          <div className="sgcars-breadcrumbs">
            <h2>Cars</h2>
          </div>
          {/* ) } */}
        </div>
        <div className="cars-container-1">
          <div className="cars-content-1">
            {location?.search == "" ? (
              <FilterComponent
                item_SearchPlaceholder="Search category"
                item_SetSearchCars={setSearchCategory}
              />
            ) : (
              <FilterComponent
                item_SearchPlaceholder="Search your dream cars"
                item_SetSearchCars={setSearchCars}
              />
            )}
          </div>

          <div className="cars-content-2">
            {errorcars ? (
              // <div>Error: {error.message}</div>
              <>
                {console.log("error", error.message)}
                <div className="prod-content">
                  <Alert variant="danger">
                    <Alert.Heading className="ErrorRequest_Head">
                      Too many requests!{" "}
                    </Alert.Heading>
                    <hr />
                    <p>Please try again later. </p>
                  </Alert>
                </div>
              </>
            ) : !isLoadedCars ? (
              // <div>Loading...</div>
              <div className="LoadingSpinner">
                <Spinner animation="border" role="status" variant="info">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {location?.search == "" ? (
                  <div className="cars-c-2-category">
                    {categories
                      .filter((item) => {
                        if (searchCategory === "") {
                          return item;
                        } else if (
                          item.title
                            .toLowerCase()
                            .includes(searchCategory.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, i) => (
                        <div
                          className="cars-c-2-cate-item"
                          style={{
                            backgroundImage: "url(" + item?.thumbnail + ")",
                          }}
                          key={i}
                        >
                          {/* <h3 onClick={() => window.location.href=`cars?${item.url_slug}` }>{item.title}</h3> */}
                          <h3
                            onClick={() =>
                              (window.location.href = `cars?body_type=${item.id}`)
                            }
                          >
                            {item.title}
                          </h3>
                        </div>
                      ))}
                  </div>
                ) : (
                  <>
                    <div className="cars-country">
                      <div className="cars-country-item japan">
                        <Link
                          to={`/cars?country=112`}
                          className="cars-country-item-1"
                          target="_top"
                        >
                          <img src={japan_flag} alt="Autopit Japan Cars" />
                          All the stocks ready in here
                        </Link>
                      </div>

                      <div className="cars-country-item uk">
                        <Link
                          to={`/cars?country=238`}
                          className="cars-country-item-1"
                          target="_top"
                        >
                          <img src={uk_flag} alt="Autopit UK Cars" />
                          All the stocks ready in here
                        </Link>
                      </div>
                    </div>

                    {cars?.data?.length === 0 ? (
                      <>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={`No cars found`}
                        />
                      </>
                    ) : (
                      <div className="cars-c-2-container">
                        {(searchCars === "" ? paginationData : cars.data)
                          .filter((item) => {
                            if (searchCars === "") {
                              return item;
                            } else if (
                              item.title
                                .toLowerCase()
                                .includes(searchCars.toLowerCase())
                            ) {
                              return item;
                            } else if (
                              item.product_attribute.brand_model_group.brand.title
                                .toLowerCase()
                                .includes(searchCars.toLowerCase())
                            ) {
                              return item;
                            } else if (
                              item.product_attribute.country.country_name
                                .toLowerCase()
                                .includes(searchCars.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((item, i) => (
                            <div
                              className="cars-card-item"
                              key={i}
                              onClick={() => {
                                window.location.href = `/cars/${item.url_slug}`;
                              }}
                            >
                              <figure>
                                <img
                                  src={item.thumbnail}
                                  alt={item.title + " | AutoPit"}
                                />
                              </figure>

                              <div className="cars-card-item-body">
                                <div className="cars-card-info-header">
                                  <span className="cars-card-item-brand">
                                    {
                                      item.product_attribute.brand_model_group
                                        .brand.title
                                    }
                                  </span>
                                  <h5 className="cars-card-item-title">
                                    {item.title}
                                  </h5>
                                </div>

                                <div className="cars-card-info-body">
                                  <div className="cars-card-item-country">
                                    <GrLocation className="cars-card-item-country-icon" />
                                    {
                                      item.product_attribute.country
                                        .iso_alpha2_code
                                    }
                                    ,{" "}
                                    {
                                      item.product_attribute.country
                                        .country_name
                                    }
                                  </div>

                                  <div className="cars-card-item-pricing">
                                    <p className="cars-card-item-pricing-default">
                                      {/* {item.product_attribute.price} */}
                                      {item.product_attribute.price != null
                                        ? `${
                                            item.product_attribute.currency
                                              .country.currency_symbol
                                          } ${Number(
                                            item.product_attribute.price,
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ",",
                                            )}`
                                        : "-"}
                                    </p>
                                    {item.product_attribute.currency.country
                                      .currency_symbol != "MYR" && (
                                      <p className="cars-card-item-pricing-convertedrate">
                                        {`≈ MYR ${Number(
                                          item.product_attribute.converted_rate,
                                        )
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ",",
                                          )} `}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {cars.data.length > 15 && searchCars === "" ? (
                      <>
                        <Pagination
                          className="cars-antd-pagination"
                          // simple
                          current={currentPage}
                          onChange={handlePageChange}
                          pageSize={15}
                          onShowSizeChange={handlePageSizeChange}
                          total={cars.data.length}
                          // showTotal={showTotal}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default CarsItem;
