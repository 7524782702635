import React, { useEffect } from "react";
import "../../App.css";
import { Helmet } from "react-helmet";
import SearchResult from "../profile/Search_Results";

function SearchResultPage() {
  useEffect(() => {
    document.title = "Search Results | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/search-results" />

        <meta name="canonical" content="/search-results" />
        <meta property="og:url" content="/search-results" />
      </Helmet>

      <SearchResult />
    </>
  );
}

export default SearchResultPage;
