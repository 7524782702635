import React from "react";
import "./App.css";
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* react-router-dom version -> Switch (v5) = Routes (v6) */
import Navbar from "./components/navigation/Navbar_V2";
import Footer from "./components/navigation/Footer";
import NotFoundPage from "./components/navigation/NotFoundPage"; // 404 page not found
import Home from "./components/pages/Home"; // home
import ContactUsPage from "./components/pages/ContactUs_Page"; // contact us
import FinancePage from "./components/pages/Finance_Page"; // finance
import ImportCar from "./components/pages/ImportCar"; // ImportCar
import ImportCarJapan from "./components/pages/ImportCarJapan"; // ImportCar Japan Page
import ImportCalculator from "./components/pages/ImportCalculator"; // ImportCalculator
import ImportResult from "./components/pages/ImportResult"; // Import Calculate Result
import ImportResult2 from "./components/pages/ImportResult2"; // Import Calculate Result 2
import CarsPage from "./components/pages/Cars_Page"; // cars
import SingleCarDetails from "./components/car/SingleCarDetails"; // car details
import MakePayment from "./components/car/MakePayment"; // make payment
import MyOrdersPage from "./components/pages/MyOrder_Page"; // my orders
import MyProfilePage from "./components/pages/MyProfile_Page";
import ImportRefDetails from "./components/pages/ImportReferenceDetails";
import SearchResultPage from "./components/pages/SearchResult_Page";

// import FinanceTest from './components/finance/Finance_Test';
// import IconsPage from './components/pages/IconsPage';
// import CarTest from './components/car/CarTest';
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import TermsAndConditions from "./components/policies/TermsConditions";
import DataDeletionGuide from "./components/policies/DataDeletionGuide";
import RefundPolicy from "./components/policies/RefundPolicy";

import ChatWidgets from "./components/navigation/BackToTop";
import ImportPaymentPage from "./components/pages/ImportPayment_Page";
// import ImportReference from './components/import-car/ImportReference';

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

  return (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/about-us" element={<FinancePage />} /> */}
          <Route path="/cars" element={<CarsPage />} />
          <Route path="/cars/:id" element={<SingleCarDetails />} />
          <Route path="/finance" element={<FinancePage />} />
          <Route path="/import-car" element={<ImportCar />} />
          <Route path="/import-car-japan" element={<ImportCarJapan />} />
          <Route path="/import-calculator" element={<ImportCalculator />} />
          <Route path="/import-reference" element={<ImportRefDetails />} />{" "}
          {/* For agent to check the import details */}
          <Route path="/import-result" element={<ImportResult />} />
          <Route path="/import-result-final" element={<ImportResult2 />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-deletion-guide" element={<DataDeletionGuide />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/import-payment" element={<ImportPaymentPage />} />
          {/* <Route path="/icons" element={<IconsPage />} /> */}
          {/* <Route path="/test" element={<CarsPage/>} />*/}
          {/* <Route path="/test" element={<CarTest />} /> */}
          {cookies?.accessToken ? (
            <>
              <Route path="/search-results" element={<SearchResultPage />} />
              <Route path="/payment/:id" element={<MakePayment />} />
              <Route path="/profile" element={<MyProfilePage />} />
              <Route path="/profile/orders" element={<MyOrdersPage />} />
            </>
          ) : (
            <Route path="/profile/orders" element={<NotFoundPage />} />
          )}
        </Routes>

        <Footer />
      </Router>

      <ChatWidgets />
    </>
  );
}

export default App;
