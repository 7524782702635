import React, { useEffect } from "react";
import "../../App.css";
import ImportCar from "../import-car/ImportCalculator";

import { Helmet } from "react-helmet";

function ImportCalculator() {
  useEffect(() => {
    document.title = "Import Car | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/import-calculator" />

        <meta name="canonical" content="/import-calculator" />
        <meta property="og:url" content="/import-calculator" />
      </Helmet>

      <ImportCar />
    </>
  );
}

export default ImportCalculator;
