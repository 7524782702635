import React, { useEffect } from "react";
import "../../App.css";
import ImportCarContent from "../import-car/ImportCar";

import { Helmet } from "react-helmet";

function ImportCar() {
  useEffect(() => {
    document.title = "Import Car | AutoPit";
    if (window.scrollY > 0) window.scrollTo(0, 700);
    else window.scrollBy(0, 700);
  }, []);

  return (
    <>
      <ImportCarContent />
    </>
  );
}

export default ImportCar;
