import React, { useEffect } from "react";
import "../../App.css";

import { Helmet } from "react-helmet";
import ImportReference from "../import-car/ImportReference";

function ImportRefDetails() {
  useEffect(() => {
    document.title = "Import Car | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/import-reference" />

        <meta name="canonical" content="/import-reference" />
        <meta property="og:url" content="/import-reference" />
      </Helmet>

      <ImportReference />
    </>
  );
}

export default ImportRefDetails;
