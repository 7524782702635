import axios from "axios";

import { useCookies } from "react-cookie";

const axiosInstance = axios.create({
  baseURL: "https://api.autopitonline.com/api/v1/",
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Redirect to login

      const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

      // setCookie( 'accessToken', '', { path: '/' } );
      removeCookie("accessToken", { path: "/" });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
