import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "../../App.css";
import "./Finance.scss";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
// import ImportResultComponent from "./ImportResultComponent";
// import Prompt_Modal from "../navigation/Modals/PromptLogin_Modal";

import { message, Empty } from "antd";
import { Modal } from "react-bootstrap";

function ImportReference() {
  const [cookies] = useCookies(["accessToken"]);
  const location = useLocation();
  // const [cookies] = useCookies(["accessToken"]);
  const [messageApi, contextHolder] = message.useMessage();

  const headers_default = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-localization": "en",
  };

  const [reference_found, setReferenceFound] = useState(false);

  const [referenceNumber, setReferenceNumber] = useState(null);
  const [carPrice, setCarPrice] = useState(0);
  const [car_age, setCarAge] = useState(null);
  const [currencyRate, setCurrencyRate] = useState(0);
  const [currency, setCurrency] = useState([]);
  const [model, setModel] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const yearMap = {
    1: 2018,
    2: 2019,
    3: 2020,
    4: 2021,
    5: 2022,
    6: 2023,
    2018: 2018,
    2019: 2019,
    2020: 2020,
    2021: 2021,
    2022: 2022,
    2023: 2023,
  };
  const [import_status, setImportStatus] = useState(1);
  const [shipping_fee, setShippingFee] = useState(null);
  const [convertedprice, setConvertedPrice] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [dutyPrice, setDutyListPrice] = useState(0);
  const [serviceFeePrice, setServiceFeePrice] = useState(0);
  const [serviceFeeRate, setServiceFeeRate] = useState(0);
  const [permit_fee, setPermitFee] = useState(0);
  const [roadtax_fee, setRoadTaxFee] = useState(0);
  const [insurance_fee, setInsuranceFee] = useState(0);
  const [misc_fee, setMiscFee] = useState(0);
  const [finalImportPrice, setFinalImportPrice] = useState(0);
  const [firstPayment, setFirstPayment] = useState(0);
  const [secondPayment, setSecondPayment] = useState(0);
  const [approvePermit, setApprovePermit] = useState(0);
  const [insurancePrice, setInsurancePrice] = useState(0);
  const [roadTaxPrice, setRoadTaxPrice] = useState(0);
  const [miscPrice, setMiscPrice] = useState(0);

  // 6. Calculate import price
  // const [import_price_1, setImportPrice1] = useState(null);
  const [refModal, setRefModal] = useState(false);
  const handleCloseRefModal = () => setRefModal(false);

  useEffect(() => {
    document.title = "Import Car | AutoPit";
    const reference_number = location.search.split("=")[1];

    if (location.search.split("=")[1] === undefined) {
      setRefModal(true);
    } else {
      // getImportResult(reference_number);
      fetch(
        `https://api.autopitonline.com/api/v1/calculators/import?reference=${reference_number}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log(result.data);
            if (result.data != null) {
              setReferenceFound(true);
            } else {
              setRefModal(true);
              setReferenceFound(false);
            }
            if (
              new Date() > new Date(result.data.valid_till) &&
              result.data.status === 1
            ) {
              setRefModal(true);
              setReferenceFound(false);
            }
            const currentYear = new Date().getFullYear();
            const getMonth = parseInt(new Date().getMonth() + 1);
            const carYear = yearMap[result.data.year];
            const carAge =
              (currentYear - carYear) * 12 +
              (getMonth - parseInt(result.data.month));
            setCarAge(carYear ? carAge : "-");

            setReferenceNumber(result.data.reference);
            setModel(result.data.model_group.title);
            setYear(result.data.year);
            setMonth(result.data.month);
            setCarPrice(result.data.original_price);

            setImportStatus(result.data.status);
            setCurrency(result.data.currency);
            setCurrencyRate(result.data.rate);
            setOriginalPrice(result.data.original_price);
            setServiceFeeRate(result.data.service_charge_rate);

            setConvertedPrice(result.data.converted_price);
            setDutyListPrice(result.data.duty_price);
            setShippingFee(result.data.shipping_price);
            setPermitFee(result.data.approve_permit);
            setServiceFeePrice(result.data.service_charge_price);
            setRoadTaxFee(result.data.road_tax_price);
            setInsuranceFee(result.data.insurance_price);
            setMiscFee(result.data.misc_price);
            setFinalImportPrice(result.data.final_price);

            setFirstPayment(result.data.first_payment);
            setSecondPayment(result.data.second_payment);
            setInsurancePrice(result.data.insurance_price);
            setRoadTaxPrice(result.data.road_tax_price);
            setApprovePermit(result.data.approve_permit);
            setMiscPrice(result.data.misc_price);

            console.log(result.data);
          },
          (error) => {
            console.error(error);
            setRefModal(true);
          }
        );
    }
    // console.log('clean', location.search.split( '=' )[1]);
  }, []);

  /* FOR LOGIN and etc */
  // login modal
  const [loginModal, setLoginModal] = useState(false);
  const handleCloseLoginModal = () => setLoginModal(false);
  const handleLoginModal = () => {
    setLoginModal(true);
    setRegisterModal(false);
    setForgotPassModal(false);
  };
  // register modal
  const [registerModal, setRegisterModal] = useState(false);
  const handleCloseRegisterModal = () => setRegisterModal(false);
  const handleRegisterModal = () => {
    setRegisterModal(true);
    setLoginModal(false);
    setForgotPassModal(false);
  };
  // forgotpassword
  const [forgotpassModal, setForgotPassModal] = useState(false);
  const handleClosePasswordModal = () => setForgotPassModal(false);
  const handlePasswordModal = () => {
    setForgotPassModal(true);
    setLoginModal(false);
    setRegisterModal(false);
  };
  // resetpassword modal
  const [showReset, setShowReset] = useState(false);
  const handleCloseReset = () => setShowReset(false);

  return (
    <>
      {contextHolder}
      <div className="wrap-fin-hero">
        <div className="fin-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-fin">
        <div className="fin-container-1">
          <div className="fin-header">
            <h2>Finance</h2>
          </div>

          {reference_found === true ? (
            <>
              <div className="fin-tab-pane-2-content">
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Reference Number</h4>
                  <p className="input_title_import-result">
                    {referenceNumber == null ? "-" : referenceNumber}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">
                    Car Model Specification
                  </h4>
                  <p className="input_title_import-result">
                    {model == null ? "-" : model}
                  </p>
                </div>
                <div
                  className="fin-tab-pane-2-input"
                  style={{ marginBottom: "30px" }}
                >
                  <h4 className="input_title_import">Car Age</h4>
                  <p className="input_title_import-result">
                    {`${car_age} Months Old [ ${month} / 
                      ${
                        year === "1"
                          ? "2018"
                          : year === "2"
                          ? "2019"
                          : year === "3"
                          ? "2020"
                          : year === "4"
                          ? "2021"
                          : year === "5"
                          ? "2022"
                          : year === "6"
                          ? "2023"
                          : year === "2018"
                          ? "2018"
                          : year === "2019"
                          ? "2019"
                          : year === "2020"
                          ? "2020"
                          : year === "2021"
                          ? "2021"
                          : year === "2022"
                          ? "2022"
                          : year === "2023"
                          ? "2023"
                          : "-"
                      } ]
                    `}
                  </p>
                </div>
                <div
                  className="imp-cal-item-3 flex gap-x-4 flex-nowrap"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="fin-tab-pane-2-input w-full relative">
                    <h4 className="input_title_import">Car Price</h4>
                    <p className="input_title_import-result">
                      {/* {currency.country.currency_symbol == "JPY"
                        ? `${ parseFloat(carPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } YEN`
                        : `${ parseFloat(carPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } GBP`} */}
                      {parseFloat(carPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {currency.country.currency_symbol}
                    </p>
                  </div>
                  <div className="fin-tab-pane-2-input w-full relative">
                    <h4 className="input_title_import">Shipment Fees</h4>
                    <p className="input_title_import-result">
                      {currency.country.currency_symbol === "JPY"
                        ? `${(
                            parseFloat(shipping_fee) * parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} JPY`
                        : `${(
                            parseFloat(shipping_fee) / parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GBP`}
                    </p>
                  </div>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">
                    Port arrival Total CIF Fees before clearance{" "}
                    <span>
                      ({" "}
                      {currency.country.currency_symbol === "JPY"
                        ? `${parseFloat(
                            parseFloat(originalPrice) +
                              parseFloat(shipping_fee) *
                                parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} JPY × ${Number(currencyRate).toFixed(4)} `
                        : `${parseFloat(
                            parseFloat(originalPrice) +
                              parseFloat(shipping_fee) /
                                parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} GBP × ${Number(currencyRate).toFixed(4)} `}
                      ){" "}
                    </span>
                  </h4>
                  <p className="input_title_import-result">
                    {`MYR ${(
                      parseFloat(convertedprice) + parseFloat(shipping_fee)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}{" "}
                  </p>
                  <p
                    className="calculator_note"
                    style={{ border: "none", paddingBottom: "0" }}
                  >
                    {currency.country.currency_symbol === "JPY"
                      ? `JPY 100 = MYR ${Number(currencyRate * 100).toFixed(4)}`
                      : `£ 1 = MYR ${Number(currencyRate).toFixed(4)}`}{" "}
                    (Exchange rates are updated every Sunday following PBB
                    rates)
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">
                    Tax duty with documentation
                  </h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(dutyPrice)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Cost Price</h4>
                  <p className="input_title_import-result">
                    {/* {props.Cost_Price} */}
                    {`MYR ${(
                      parseFloat(
                        parseFloat(convertedprice) + parseFloat(shipping_fee)
                      ) + parseFloat(dutyPrice)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Approve Permit</h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(approvePermit)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Insurance</h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(insurancePrice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Road Tax</h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(roadTaxPrice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Misc. Fees</h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(miscPrice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Service Fees (代购费)</h4>
                  <p className="input_title_import-result">
                    {/* {props.Service_Fees} */}
                    {`MYR ${parseFloat(serviceFeePrice)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${parseFloat(
                      serviceFeeRate
                    ).toFixed(0)}%)`}
                  </p>
                </div>
                <div
                  className="fin-tab-pane-2-input"
                  style={{ marginBottom: "30px" }}
                >
                  <h4 className="input_title_import">
                    Import Final Price / Bidding Won Price
                  </h4>
                  {/* <p className="input_title_import-result">{`MYR ${parseFloat(finalImportPrice).toFixed(2) }`}</p> */}
                  <p className="input_title_import-result">
                    {/* {`MYR ${ (
                        parseFloat(convertedprice) + parseFloat(shipping_fee) + 
                        parseFloat(serviceFeePrice) + parseFloat(dutyPrice) + parseFloat(permit_fee) +
                        parseFloat(misc_fee) + parseFloat(roadtax_fee) + parseFloat(insurance_fee) 
                    ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } `} */}
                    {import_status === 2 ? (
                      "MYR " +
                      parseFloat(finalImportPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    ) : import_status === 1 ? (
                      "MYR " +
                      (
                        parseFloat(convertedprice) +
                        parseFloat(shipping_fee) +
                        parseFloat(serviceFeePrice) +
                        parseFloat(dutyPrice) +
                        parseFloat(permit_fee) +
                        parseFloat(misc_fee) +
                        parseFloat(roadtax_fee) +
                        parseFloat(insurance_fee)
                      )
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      " (Pending)"
                    ) : (
                      <>Pending </>
                    )}
                  </p>
                </div>
                <div
                  className="imp-cal-item-4 flex gap-x-4 flex-nowrap"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="fin-tab-pane-2-input w-full relative">
                    <h4 className="input_title_import">1st Payment</h4>
                    {/* <input type="text" value='MYR 595,996.61' className='input_import font-bold' style={{paddingLeft:'20px'}}/> */}
                    <p className="input_title_import-result">
                      {`MYR ${firstPayment
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </p>
                  </div>
                  <div className="fin-tab-pane-2-input w-full relative">
                    <h4 className="input_title_import">
                      2nd Payment (Remaining balance)
                    </h4>
                    {/* <input type="text" value='MYR 199,612.79' className='input_import font-bold' style={{paddingLeft:'20px'}}/> */}
                    <p className="input_title_import-result">
                      {`MYR ${secondPayment
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </p>
                  </div>
                </div>
              </div>

              {import_status === 2 ? (
                // <button className={`impRef-to-payment-btn`}>Next</button>
                <a
                  href={`/import-payment?details=${referenceNumber}`}
                  className={`impRef-to-payment-btn`}
                >
                  Next
                </a>
              ) : (
                <button className={`impRef-to-payment-btn disabled`}>
                  Next
                </button>
              )}
            </>
          ) : (
            <Empty
              imageStyle={{
                height: 90,
              }}
              description={
                <span className="empty-results-span">No result found</span>
              }
            />
          )}
        </div>

        <div className="fin-container-2">
          <h3>Useful Links</h3>

          <div className="fin-cont-2-item">
            <div className="fin-cont-2-item-1">
              <a href="https://scrut.my/" target="_blank" rel="noreferrer">
                <h4>Japan Auction History Checker</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="https://www.vehiclecheck.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                <h4>HPI Check</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="https://www.oto.my/tool/car-market-value-guide/"
                target="_blank"
                rel="noreferrer"
              >
                <h4>Car Market Value Guide</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="http://www.jpj.my/JPJ_Latest_Number_Plates.htm"
                target="_blank"
                rel="noreferrer"
              >
                <h4>JPJ Car Plate Checker Calculators</h4>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <Prompt_Modal
        showloginModal={loginModal}
        onHideLoginModal={handleCloseLoginModal}
        login_Register_Btn={handleRegisterModal}
        login_ForgotPS_Btn={handlePasswordModal}
        showregisterModal={registerModal}
        onHideregisterModal={handleCloseRegisterModal}
        register_Login_Btn={handleLoginModal}
        register_ForgotPS_Btn={handlePasswordModal}
        showforgotpassModal={forgotpassModal}
        onHideforgotpassModal={handleClosePasswordModal}
        forgot_Login_Btn={handleLoginModal}
        showResetModal={showReset}
        onHideResetModal={handleCloseReset}
        reset_Login_Btn={handleLoginModal}
      /> */}

      <Modal
        show={refModal}
        onHide={handleCloseRefModal}
        className="login-modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="login-modal-header">
          <Modal.Title>
            Import Details
            <br />
            <p>Please ensure the reference provided is correct.</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <Empty
            imageStyle={{
              height: 90,
            }}
            description={``}
          />
          <div className="refpage-modalbody">
            <p>
              Import details for the reference is either invalid or expired.
            </p>
          </div>

          <div className="login-buttons">
            {/* <a href="/import-car" className="login-submit-btn"> */}
            <button className="login-submit-btn" onClick={handleCloseRefModal}>
              Close
            </button>
            {/* </a> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImportReference;
