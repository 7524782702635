import React, { useEffect, useState } from "react";
import "../../App.css";
import "./Finance.scss";
import axios from "../../api/axios";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function RoadTaxCalculator() {
  const [enginecapacity, setEngineCapacity] = useState(1600);
  const [location, setLocation] = useState(["Peninsular Malaysia", "West", 1]);
  const [ownerType, setOwnerType] = useState([
    "Saloon Cars / Individual Owned",
    1,
  ]);

  const [yearlyRoadtax, setYearlyRoadtax] = useState(0);

  // to follow api
  const CalculateRoadTax = async () => {
    try {
      const response = await axios.post(
        "calculators/road-tax",
        {
          cc: enginecapacity,
          region: location[2],
          type: ownerType[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );

      setYearlyRoadtax(response.data.data?.yearly_payment);

      console.log("response", response);
      console.log("setYearlyRoadtax", response.data.data?.yearly_payment);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Road Tax Calculator</h3>
          <p>
            Latest JPJ formula - calculate how much your vehicle’s road tax will
            cost.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Engine Capacity (cc)</h4>
            <input
              type="number"
              onChange={(e) => setEngineCapacity(e.target.value)}
              placeholder="1600"
            ></input>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Region</h4>

            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={location[0] ? location[0] : "Peninsular Malaysia "}
            >
              <Dropdown.Item
                onClick={() => setLocation(["Peninsular Malaysia", "West", 1])}
              >
                Peninsular Malaysia
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setLocation(["Sabah / Sarawak", "East", 2])}
              >
                Sabah / Sarawak{" "}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setLocation(["Pulau Pangkor & Langkawi", "Langkawi", 3])
                }
              >
                Pulau Pangkor & Langkawi
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setLocation(["Labuan", "Labuan", 4])}
              >
                Labuan
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Car / Ownership Type</h4>

            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={
                ownerType[0] ? ownerType[0] : "Saloon Cars / Individual Owned "
              }
            >
              <Dropdown.Item
                onClick={() =>
                  setOwnerType(["Saloon Cars / Individual Owned", 1])
                }
              >
                Saloon Cars / Individual Owned
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setOwnerType(["Saloon Cars / Company Owned", 2])}
              >
                Saloon Cars / Company Owned{" "}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setOwnerType([
                    "Non-Saloon Cars / Individual Or Company Owned",
                    3,
                  ])
                }
              >
                Non-Saloon Cars / Individual Or Company Owned
              </Dropdown.Item>
            </DropdownButton>

            <div className="fin-tab-pane-2-saloon-type">
              <div className="fin-tab-pane-2-saloon-type-1">
                <h5>Saloon:</h5>
                <p>Sedan / Hatchback / Coupe / Wagon / Converible</p>
              </div>
              <div className="fin-tab-pane-2-saloon-type-1">
                <h5>Non-Saloon:</h5>
                <p>MPV / SUV / Pick-up / Commercial</p>
              </div>
            </div>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Yearly Road Tax Repayment</h4>
            {/* <div className="fin-tab-pane-payment">MYR {yearlyRoadtax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> */}
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {yearlyRoadtax
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>

          <button
            onClick={CalculateRoadTax}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>
        </div>
      </div>
    </>
  );
}

export default RoadTaxCalculator;
