import React, { useState } from "react";
import "../Navbar.scss";
import axios from "../../../api/axios";
import { useCookies } from "react-cookie";
import Modal from "react-bootstrap/Modal";
import { message, Input, Form, Select } from "antd";

export default function Prompt_Modal(props) {
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const [messageApi, contextHolder] = message.useMessage();

  // login modal
  const [loginModal, setLoginModal] = useState(false);
  const handleCloseLoginModal = () => setLoginModal(false);
  const handleLoginModal = () => {
    setLoginModal(true);
    setRegisterModal(false);
    // setLoginDropdown(false);
    setForgotPassModal(false);
  };

  const [showLoginFailed, setShowLoginFailed] = useState(false);
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginDeviceType, setLoginDeviceType] = useState();

  const login = async (e) => {
    e.preventDefault();
    var successTimeout = null,
      failedTimeout = null;
    setLoginDeviceType(4);
    try {
      const response = await axios.post(
        "users/token",
        JSON.stringify({
          username: loginUsername,
          password: loginPassword,
          device_type: 4,
          register_token: "",
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-localization": "en",
          },
        }
      );
      const accessToken = response?.data?.token;
      const downloadKey = response?.data?.data.download_key;
      // setCookie( 'accessToken', accessToken, { path: '/', expires: new Date( new Date().getTime() + ( 7*24*60*60*1000 ) ) } );
      setCookie("accessToken", accessToken, {
        path: "/",
        expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      });
      setCookie("downloadKey", downloadKey, {
        path: "/",
        expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      });

      messageApi.open({
        type: "success",
        content: " Login Success",
      });
      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (error) {
      messageApi.open({
        type: "error",
        // content: error.response.data.message,
        content: "Login Failed",
      });
      console.log("error", error.response.data.message);
      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowLoginFailed(false);
      }, 2000);
    }
  };
  // register modal
  const [registerModal, setRegisterModal] = useState(false);
  const handleCloseRegisterModal = () => setRegisterModal(false);
  const handleRegisterModal = () => {
    setRegisterModal(true);
    setLoginModal(false);
    // setLoginDropdown(false);
    setForgotPassModal(false);
  };

  const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
  const [showRegisterFailed, setShowRegisterFailed] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");

  const [registerUsername, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState(0);
  const [registerAffiliate, setRegisterAffiliate] = useState("");
  const [registerDeviceType, setRegisterDeviceType] = useState(0);
  const [registerOTPcode, setRegisterOTPCode] = useState(null);

  const [veri_SendBtn, setVeriSendBtn] = useState(false);
  const [regist_step1, setRegistStep1] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [counter, setCounter] = useState(60);

  const regist_requestOTP = async (e) => {
    e.preventDefault();
    if (registerPhoneNumber != 0) {
      try {
        const response = await axios.post(
          "otp",
          { phone_number: parseInt(0 + registerPhoneNumber) },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        messageApi.open({
          type: "success",
          content: "Verification code has been sent",
        });
        setRegistStep1(response.data.data);
        setVeriSendBtn(true);
        setCountdown(true);
        var i = counter;
        const timer =
          counter > 0 &&
          setInterval(() => {
            setCounter((counter) => counter - 1);
            i--;
            if (i <= 0) {
              clearInterval(timer);
              setCountdown(false);
              setCounter(60);
            }
          }, 1000);
      } catch (error) {
        if (error?.response?.data?.errors) {
          const firstError = Object.values(error.response?.data.errors)[0][0];
          const splitMessage = firstError.split(".");
          const errorMessage = splitMessage[splitMessage.length - 1]
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          if (firstError) {
            messageApi.open({
              type: "error",
              content: errorMessage,
            });
          } else {
            messageApi.open({
              type: "error",
              content: error.message,
            });
          }
        }
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Phone number is required",
      });
    }
  };
  const register = async (e) => {
    e.preventDefault();
    var successTimeout = null,
      failedTimeout = null;
    setRegisterErrorMessage((pv) => "");
    setRegisterDeviceType(4);

    try {
      const response = await axios.post(
        "users",
        {
          otp_code: registerOTPcode,
          tmp_user: regist_step1.tmp_user,
          username: registerUsername,
          email: registerEmail,
          password: registerPassword,
          phone_number: parseInt(0 + registerPhoneNumber),
          affiliate: registerAffiliate,
          device_type: 4,
          register_token: "",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("message", response.data);
      messageApi.open({
        type: "success",
        content: "Register Success",
      });
      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        setShowRegisterSuccess(false);
        setRegisterModal(false);
        setLoginModal(true);
        setForgotPassModal(false);
      }, 2000);
    } catch (error) {
      if (error?.response?.data?.errors) {
        const firstError = Object.values(error.response?.data.errors)[0][0];
        if (firstError) {
          messageApi.open({
            type: "error",
            content: firstError,
          });
        } else {
          messageApi.open({
            type: "error",
            content: error.message,
          });
        }
      }

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowRegisterFailed(false);
      }, 2000);
    }
  };
  // forgotpassword modal
  const [showForgotFailed, setShowForgotFailed] = useState(false);
  const [forgotErrorMessage, setForgotErrorMessage] = useState("");

  const [forgotpassModal, setForgotPassModal] = useState(false);
  const handleClosePasswordModal = () => setForgotPassModal(false);
  const [fpass_phonenum, setFPassPhoneNumber] = useState("");
  const handlePasswordModal = () => {
    setForgotPassModal(true);
    setLoginModal(false);
    // setLoginDropdown(false);
    setRegisterModal(false);
  };
  const [verifyOTP, setVerifyOTP] = useState(true);

  const forgotpassword = async (e) => {
    e.preventDefault();
    var successTimeout = null,
      failedTimeout = null;

    try {
      const response = await axios.post(
        "users/" + "forgot",
        {
          phone_number: parseInt(60 + fpass_phonenum),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("message", response.data);
      setVerifyOTP(false);
    } catch (error) {
      console.log("error", error.response.data.errors.phone_number[0]);
      var errors = error.response.data.errors;
      let errorMessage = "";

      if (error.response.data.errors.phone_number[0] === "api.user_not_exist") {
        errorMessage += `User does not exist`;
      } else {
        errorMessage += `Please ensure data is correct`;
      }

      messageApi.open({
        type: "error",
        content: errorMessage,
      });

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowForgotFailed(false);
      }, 2000);
    }
  };
  // verifyOTP
  const [otp_code, setOTPCode] = useState(null);
  const [reset_info, setResetInfo] = useState([]);
  const [otp_errormessage, setOTPErrorMessage] = useState("");
  const [showOTPFailed, setShowOTPFailed] = useState(false);
  const verifyOTPcode = async (e) => {
    e.preventDefault();
    var failedTimeout = null;

    try {
      const response = await axios.post(
        "users/verify",
        {
          phone_number: parseInt(60 + fpass_phonenum),
          otp_code: otp_code,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("otp_demo", response.data);
      // setResetInfo(response.data);
      setForgotPassModal(false);
      setShowReset(true);
    } catch (error) {
      // console.log('error',error.response)
      // var errors = error.response.data.errors?.otp_code[0];
      var errors = error.response.data.message;
      let errorMessage = errors;

      messageApi.open({
        type: "error",
        content: errorMessage,
      });
      setOTPErrorMessage(errorMessage);
      // setShowOTPFailed(true);

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowOTPFailed(false);
      }, 2000);
    }
  };

  // resetpassword modal
  const [showReset, setShowReset] = useState(false);
  const handleCloseReset = () => setShowReset(false);
  const handleShowReset = () => setShowReset(true);
  const [resetPasswordOne, setResetPasswordOne] = useState("");
  const [resetPasswordTwo, setResetPasswordTwo] = useState("");
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showResetAlert, setShowResetAlert] = useState(false);
  const [reset_message, setResetMessage] = useState("");

  const resetPasswordPost = async (e) => {
    e.preventDefault();

    var successTimeout = null,
      failedTimeout = null;

    try {
      const response = await axios.post(
        "users/" + "reset",
        {
          id: reset_info.user_tmp,
          password: resetPasswordOne,
          password_confirmation: resetPasswordTwo,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log('response',response.data);
      messageApi.open({
        type: "success",
        content: "Reset password success",
      });

      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (error) {
      // console.log('error',error.response.data.errors?.password[0]);
      var errors = error.response.data.errors?.password[0];
      let errorMessage = errors;
      messageApi.open({
        type: "error",
        content: errorMessage,
      });
      setResetMessage(errorMessage);
      // setShowResetAlert(true);

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowResetAlert(false);
      }, 2000);
    }
  };

  return (
    <>
      {contextHolder}
      {/* <Modal show={loginModal} onHide={handleCloseLoginModal} className="login-modal" centered> */}
      <Modal
        show={props.showloginModal}
        onHide={props.onHideLoginModal}
        className="login-modal"
        centered
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Customer Login
            <br />
            <p>Please sign in to continue</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-username">
              <label for="username">Username</label>
              <br />
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Type Username"
                onChange={(e) => {
                  setLoginUsername(e.target.value);
                }}
              />
            </div>

            <div className="login-form-ps">
              <label for="password">Password</label>
              <br />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Type Password"
                onChange={(e) => {
                  setLoginPassword(e.target.value);
                }}
              />
            </div>

            <div className="login-form-etc">
              <button onClick={props.login_Register_Btn}>Register</button>
              <button onClick={props.login_ForgotPS_Btn}>
                Forgot Password
              </button>
            </div>

            <div className="login-buttons">
              <button
                className="login-submit-btn"
                type="submit"
                onClick={login}
              >
                Login
              </button>
              {/* <button className="login-submit-btn" >
                                I am Autopit Agent
                            </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={registerModal} onHide={handleCloseRegisterModal} className="login-modal" centered></Modal> */}
      <Modal
        show={props.showregisterModal}
        onHide={props.onHideregisterModal}
        className="login-modal"
        centered
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Customer Register
            <br />
            <p>Please register to be a member s</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-username">
              <label for="username">Username</label>
              <br />
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Type Username"
                onChange={(e) => {
                  setRegisterUsername(e.target.value);
                }}
              />
            </div>

            <div className="login-form-email">
              <label for="email">Email Address</label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Type Email Address"
                onChange={(e) => {
                  setRegisterEmail(e.target.value);
                }}
              />
            </div>

            <div className="login-form-ps">
              <label for="psw">Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Type Password"
                onChange={(e) => {
                  setRegisterPassword(e.target.value);
                }}
              />
            </div>

            <div className="login-form-phone">
              <label for="phone">Phone Number</label>
              <br />
              <div>
                {/* <Select defaultValue="60" style={{ width: 70, }} dropdownMatchSelectWidth={false} placement={"bottomLeft"}
                                    options={[ {
                                        value: '60',
                                        label: '60',
                                    }, ]}
                                /> */}
                <span>+60</span>
                <Input
                  className="login-form-phone-input"
                  showCount
                  maxLength={10}
                  onChange={(e) => {
                    setRegisterPhoneNumber(e.target.value);
                  }}
                  placeholder="Type Phone Number"
                />
              </div>
            </div>

            <div className="login-form-username">
              <label for="affiliate">Affiliate Code</label>
              <br />
              <input
                type="text"
                id="affiliate"
                name="affiliate"
                placeholder="Type Affiliate Code"
                onChange={(e) => {
                  setRegisterAffiliate(e.target.value);
                }}
              />
            </div>

            <div className={`register-verification`}>
              <div className="register-veri-header">
                <div className="veri-h5">Verification</div>
                <p className="">
                  6 digit code will sent to your registered phone number for
                  verification click/tap sent TAC code.
                </p>
              </div>
              <div className="register-veri-otp">
                <label for="verification">Verification Code</label>
                <br />
                <div>
                  <Input
                    className="login-veri-otp-input"
                    maxLength={6}
                    onChange={(e) => {
                      setRegisterOTPCode(e.target.value);
                    }}
                    placeholder="Type Verification Code"
                  />
                  <button
                    onClick={regist_requestOTP}
                    className={`${
                      veri_SendBtn == false ? "" : "hidden"
                    } login-veri-submit `}
                  >
                    Send Code
                  </button>
                  {counter < 60 ? (
                    <button
                      className={`${
                        veri_SendBtn == false ? "hidden" : ""
                      } login-veri-submit-disabled`}
                    >
                      Resend Code
                    </button>
                  ) : (
                    <button
                      onClick={regist_requestOTP}
                      className={`${
                        veri_SendBtn == false ? "hidden" : ""
                      } login-veri-submit`}
                    >
                      Resend Code
                    </button>
                  )}
                </div>
                <p
                  className={`resend-counter ${counter == 60 ? "hidden" : ""}`}
                >
                  Resend in {counter} seconds
                </p>
              </div>
            </div>

            <div className="login-form-etc">
              <button onClick={props.register_Login_Btn}>Login</button>
              <button onClick={props.register_ForgotPS_Btn}>
                Forgot Password
              </button>
            </div>

            <div className="login-buttons">
              {veri_SendBtn == false ? (
                <div className={`register-disabled-btn`}>Register Now</div>
              ) : (
                <button
                  className={`login-submit-btn`}
                  type="submit"
                  onClick={register}
                  disabled={disabled}
                >
                  Register Now
                </button>
              )}
              {/* <button className="login-submit-btn" type="submit" >
                                Register as Autopit Agent
                            </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={forgotpassModal} onHide={handleClosePasswordModal} className="login-modal" centered backdrop="static"> */}
      <Modal
        show={props.showforgotpassModal}
        onHide={props.onHideforgotpassModal}
        className="login-modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Forgot Password
            <br />
            <p>Reset your password</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-phone">
              <label for="phone">Phone Number</label>
              <br />
              <div>
                {/* +60<input type="tel" id="phone" name="phone" placeholder="Type Phone Number" onChange={(e)=> {setFPassPhoneNumber(e.target.value)}}></input> */}
                +60
                <Input
                  className="login-form-phone-input"
                  showCount
                  maxLength={10}
                  onChange={(e) => {
                    setFPassPhoneNumber(e.target.value);
                  }}
                  placeholder="Type Phone Number"
                />
              </div>
              <span className="fpass-input-info">
                *Request OTP to reset your password
              </span>
            </div>
            <div
              className={verifyOTP ? "hidden login-form-otp" : "login-form-otp"}
            >
              <label for="phone">Enter OTP</label>
              <br />
              <Input
                className="login-form-otp-input"
                showCount
                maxLength={6}
                onChange={(e) => {
                  setOTPCode(e.target.value);
                }}
                placeholder="Type OTP"
              />
              {/* <input type="text" id="otp" name="otp" placeholder="Type OTP" onChange={(e)=> {setOTPCode(e.target.value)}} ></input> */}
            </div>
            <div className="login-form-etc">
              <button onClick={props.forgot_Login_Btn}>Login</button>
              <button onClick={() => window.location.reload()}>
                Cancel Reset
              </button>
            </div>
            <div className="login-buttons">
              <button
                className={
                  verifyOTP ? "request-otp-btn" : "hidden request-otp-btn"
                }
                type="submit"
                onClick={forgotpassword}
              >
                Request OTP{" "}
              </button>
              <button
                className={
                  verifyOTP ? "hidden verify-otp-btn" : "verify-otp-btn"
                }
                onClick={verifyOTPcode}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={showReset} onHide={handleCloseReset} className="login-modal" centered backdrop="static"> */}
      <Modal
        show={props.showResetModal}
        onHide={props.onHideResetModal}
        className="login-modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Reset Password
            <br />
            <p>Type your new password</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-ps">
              <label for="psw">New Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Type New Password"
                onChange={(e) => {
                  setResetPasswordOne(e.target.value);
                }}
              />
            </div>

            <div className="login-form-ps">
              <label for="psw">Confirm New Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Re-enter New Password"
                onChange={(e) => {
                  setResetPasswordTwo(e.target.value);
                }}
              />
            </div>

            <div className="login-form-etc">
              <button onClick={props.reset_Login_Btn}>Login</button>
              <button onClick={() => window.location.reload()}>
                Cancel Reset
              </button>
            </div>
            <div className="login-buttons">
              <button type="submit" onClick={resetPasswordPost}>
                Reset Password{" "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
