import React from "react";
import "./Policies.scss";

function TermsAndConditions() {
  document.title = "Terms & Conditions | AutoPit";

  return (
    <>
      <div className="wrap-policies">
        <div className="policies-block"></div>

        <div className="policies-header">
          <h1>Terms & Conditions of Use</h1>
        </div>

        <div className="policies-container-1">
          <p>
            Autopit Group Sdn Bhd (‘Autopit’) is pleased to provide Users (as
            defined below) with access to its network of websites and related
            services on these terms and conditions.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Autopit</h2>
          <p>
            A. Autopit publishes or makes available on the Autopit Network
            information, advertisements, product and service offers, vehicle
            data, valuations, Software, and links and other contents supplied by
            Autopit and third parties (“Material”). Autopit does not warrant,
            represent, authorise or endorse the reliability, accuracy or
            completeness of any such Material published on the Autopit Network
            or on any web site that links from the Autopit Network (including
            third party display advertisements).
          </p>
          <p>
            B. Autopit does not and cannot provide any warranty in relation to
            any goods or services advertised for sale on the Autopit Network by
            third parties including, but not limited to, any representation or
            warranty that the goods or services are of merchantable quality, fit
            for the purpose intended, safe for the purpose intended, as
            described by the seller or owned by the seller.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Autopit, the Sites, the Agreement and the Services</h2>
          <p>
            The terms and conditions of use as set out herein below govern the
            use of Autopit’s services offered and available on this website and
            any other services offered and available in the Autopit Network and
            all related sub-sites (collectively “the Sites” and individually
            “the Site”). The User is advised to read them carefully as they
            affect the User's rights and liabilities under the law.
          </p>
          <p>
            By using the Sites, the User is deemed to have agreed to be bound by
            the terms and conditions set out in this agreement ('the
            Agreement'), as well as those terms and conditions incorporated by
            reference and/or implication.
          </p>
          <p>
            Autopit shall have the right to modify, update or amend the terms of
            the Agreement at any time by announcing the same at the Sites. If
            the User continues to use the Sites and/or services provided by
            Autopit on the Sites ('the Services') after the date on which any
            amendments come into effect, the User shall be deemed to have
            consented to be bound by the amended terms and conditions. In the
            event the User does not agree to the amendments, the User shall not
            continue to use the Sites and/or the Services.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>The User</h2>
          <p>
            The User may either be an Individual or Corporate Entity as defined
            herein below, and includes any person browsing and/or viewing the
            Sites, as well as any person posting any advertisement and listing
            of any vehicle/ goods/ services for sale on the Site, and the
            expression “Users” shall mean more than one User.
          </p>
          <p>
            <b>'Individual'</b> - The Services are available only to individuals
            who are 18 years or above of age and are capable of entering into a
            legally binding agreement under Malaysian law.
          </p>
          <p>
            <b>'Corporate Entity'</b>- The Services are available to companies
            and/or business entities. Any person using the Services on behalf of
            such corporate entities represents that he or she has the authority
            to bind the corporate entity to the terms and conditions set out in
            the Agreement.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Responsibilities of the User</h2>
          <p>
            The User is personally responsible for his/her use of the Site
            and/or Services.
          </p>
          <p>The User uses the Site and/or the Services at his/her own risk.</p>
          <p>
            The User shall use the Services in accordance with all laws at the
            time being in force in Malaysia.
          </p>
          <p>
            The User shall not list and/or advertise any item on the Site for
            sale and/or also shall not provide and/or cause to be provided
            information which:
          </p>
          <p
            style={{
              marginLeft: "20px",
            }}
          >
            1. infringes any intellectual property rights of any third party
            including copyright, patent, trade mark, trade name, designs, trade
            secret, confidential information, know-how, goodwill, rights of
            privacy or publicity or other proprietary rights or rights of
            publicity or privacy of any third party, or contents that the User
            does not have a right to make available under any law or under
            contractual or fiduciary relationships; <br />
            2. otherwise causes any damage or loss to any person including but
            not limited to a product that contains a defamatory statement; or{" "}
            <br />
            3. violates, or is illegal under any applicable law, statute,
            ordinance or regulation. <br />
            4. is unlawful, harmful, threatening, abusive, harassing,
            defamatory, libellous, invasive of another's privacy, or is harmful
            to any persons in any way;
            <br /> 5. is pornographic or depicts a human being engaged in any
            sexual conduct;
            <br /> 6. harasses, degrades, intimidates or is hateful toward an
            individual or group of individuals on the basis of political
            affiliation, religion, gender, sexual orientation, race, ethnicity,
            age, or disability;
            <br /> 7. impersonates any person or entity, including, but not
            limited to, Autopit’s employee(s), or falsely states or otherwise
            misrepresents the User’s affiliation with a person or entity;
            <br /> 8. includes personal or identifying information about another
            person without that person's explicit written consent;
            <br /> 9. is false, deceptive, misleading, deceitful,
            mis-informative, or constitutes "bait and switch";
            <br /> 10. constitutes or contains "affiliate marketing," "link
            referral code," "junk mail," "spam," "chain letters," "pyramid
            schemes," or unsolicited commercial advertisement;
            <br /> 11. constitutes or contains any forms of advertising or
            solicitation if: <br />
            <span
              style={{
                marginLeft: "20px",
              }}
            />
            a. posted in areas of the Site which are not designated for such
            purposes; or <br />
            <span
              style={{
                marginLeft: "20px",
              }}
            />
            b. emailed to Users who have requested not to be contacted about
            other services, products or commercial interests.
            <br />
            12. advertises any illegal services or the sale of any vehicle/
            goods/ services the sale of which is prohibited or restricted by
            applicable law, including without limitation such vehicles/ goods/
            services, the sale of which is prohibited or regulated by Malaysian
            law;
            <br /> 13. contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment;
            <br /> 14. disrupts the normal flow of dialogue with an excessive
            number of messages (flooding attack) to the Sites, or that otherwise
            negatively affects other users' ability to use the Site; or
            <br /> 15. creates liability for Autopit or causes Autopit to lose,
            in whole or in part, the services of its Internet Service Providers
            or other suppliers; or
            <br /> 16. employs misleading email addresses, or forged headers or
            otherwise manipulated identifiers in order to disguise the origin of
            contents transmitted through the Sites.
          </p>
          <p>Additionally, the User agrees not to:</p>
          <p
            style={{
              marginLeft: "20px",
            }}
          >
            1. contact anyone who has asked not to be contacted;
            <br /> 2. stalk or harass anyone;
            <br /> 3. collect personal information about other Users for
            commercial or unlawful purposes;
            <br /> 4. use automated means, including spiders, robots, crawlers,
            data mining tools, or the like;
            <br /> 5. copy or reproduce by any means any content on the Sites -
            unless expressly permitted by Autopit;
            <br /> 6. post non-local or otherwise irrelevant contents,
            repeatedly post the same or similar contents or otherwise impose an
            unreasonable or disproportionately large load on the Sites’
            infrastructure;
            <br /> 7. attempt to gain unauthorized access to Autopit’s computer
            systems or engage in any activity that disrupts, diminishes the
            quality of, interferes with the performance of, or impairs the
            functionality of, the Services or the Sites.
          </p>
          <p>
            Autopit reserves the right to remove any listing that it deems in
            violation of the above, at its sole discretion. The User is solely
            responsible for the accuracy of the information provided on the
            Sites.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Account</h2>
          <p>
            In order to access the Site, the User is required to provide
            current, accurate identification, contact, and other information
            ('User Information') as part of the account registration process
            and/or continued usage of the Site offered by Autopit and its
            subsidiaries or affiliated companies. "User Information" referred to
            hereinabove also includes but is not limited to any information
            provided by the User to Autopit or to other Users.
          </p>
          <p>
            The User is responsible for maintaining the confidentiality of the
            User’s account and password and for restricting access to the User’s
            devices, which includes but is not limited to computers, mobile
            devices and/or any other device a substantial purpose of which is
            facilitating telephonic communication, but which also incorporates
            functionality that allows viewing of the Sites and its contents. The
            User agrees to accept responsibility for all activities that occur
            under the User’s account or password.
          </p>
          <p>
            Autopit reserves the right to refuse service, terminate accounts,
            remove or edit contents, and/or cancel orders in Autopit’s sole
            discretion.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Use of Site Content</h2>
          <p>
            Autopit grants the User a limited license to access and make use of
            the Sites’ content (“Site Content”) and not to download (other than
            page caching) or modify it, or any portion of it, except with prior
            express written consent of Autopit.
          </p>
          <p>This license does not include:</p>
          <p
            style={{
              marginLeft: "20px",
            }}
          >
            1. any collection and use of any product listings, descriptions, or
            prices; or <br /> 2. any derivative use of the Sites or the Site
            Contents; or
            <br /> 3. any downloading or copying of account information for the
            benefit of another merchant; or <br /> 4. any use of data mining,
            robots, or similar data gathering and extraction tools.
          </p>
          <p>
            Other than for the purposes for which the Services are intended, the
            Sites or any portion of the Site Content or any portion of Autopit
            Network sites or site content may not be reproduced, duplicated,
            copied, sold, resold, visited, or otherwise exploited for any
            commercial purpose without express written consent of Autopit.
          </p>
          <p>
            The User may not frame or utilize framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of the Sites without Autopit’s express
            written consent
          </p>
          <p>
            The User may not use any Meta tags or any other "hidden text"
            utilizing the Sites’ name or trademarks without the express written
            consent of Autopit.
          </p>
          <p>
            For the avoidance of doubt, any unauthorised scrapping of data from
            the Sites by a User is subject to a penalty of RM25,000.00 per
            listing.
          </p>
          <p>
            Any unauthorized use immediately terminates the permission or
            license granted by Autopit.
          </p>
          <p>
            Users are granted a limited, revocable, and non-exclusive right to
            create a hyperlink to the home page of the Site so long as the link
            does not portray Autopit, its products or Services, or the Site in a
            false, misleading, derogatory, or otherwise offensive matter.
          </p>
          <p>
            The User may not use any of Autopit’s logo or other proprietary
            graphic or trademark forming part of the Sites without express
            written permission.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Restrictions of content and use of software services</h2>
          <p>
            1. When the User gives the Sites and/or Autopit content, the User
            grants Autopit a non-exclusive, worldwide, perpetual, irrevocable,
            royalty-free, sub licensable (through multiple tiers) right to
            exercise the copyright, trademark, publicity, and database rights
            the User has in the content, in any media known now or in the
            future. <br /> 2. The User represents and warrants that the User
            owns or otherwise controls all of the rights to the content that the
            User posts; that the content is accurate; that use of the content
            the User supplies does not violate this Agreement and will not cause
            injury to any person or entity; and that the User will fully
            indemnify and hold Autopit harmless against all claims, liabilities,
            damage, proceedings, costs and expenses resulting from, arising out
            of or related to the content the User supplies.
            <br /> 3. Autopit has the right but not the obligation to monitor
            and edit or remove any activity or content.
            <br /> 4. Autopit takes no responsibility and assumes no liability
            for any content posted by the User or any third party. 5. Any
            software (“Software”) used in and for the Site, including but not
            limited to HTML code, and/or mobile application (including but not
            limited to iOS and Android app), that is made available to download,
            is owned by Autopit and/or its suppliers.
            <br /> 6. Use by the User of the Software is governed by the terms
            of the end user license agreement, if any, which accompanies or is
            included with the Software ("License Agreement"). The User
            acknowledges that it must agree to the terms of the License
            Agreement before using the Software.
            <br /> 7. If there is no License Agreement, Autopit grants to the
            User a personal, non-transferable license to use the Software for
            viewing and otherwise using the Autopit Network in accordance with
            these terms and conditions, and for no other purpose.
            <br /> 8. The User may not do any of the following while accessing
            or using the Services: <br />
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              a. access, tamper with, or use non-public areas of the Services,
              Autopit’s computer systems, or the technical delivery systems of
              Autopit’s providers; <br />
              b. probe, scan, or test the vulnerability of any system or network
              or breach or circumvent any security or authentication measures;{" "}
              <br />
              c. access or search or attempt to access or search the Services by
              any means (automated or otherwise) other than through currently
              available, published interfaces that are provided by Autopit (and
              only pursuant to those terms and conditions), unless the User has
              been specifically allowed to do so in a separate agreement with
              Autopit; <br />
              d. use any automated process of any sort to query, access,
              retrieve, scrape, data-mine or copy any Material on the Autopit
              Network or generate or compile any document, index or database
              based on the Material published on the Autopit Network to forge
              any email or posting, or in any way use the Services to send
              altered, deceptive or false source-identifying information; or
              <br /> e. interfere with, or disrupt, (or attempt to do so), the
              access of any user, host or network, including, without
              limitation, sending a virus, overloading, flooding, spamming,
              mail-bombing the Services, or by scripting the creation of
              contents in such a manner as to interfere with or create an undue
              burden on the Services.
            </div>
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Cookies</h2>
          <p>
            The Sites use cookies. Users understand that they must have cookies
            enabled on their computer in order for all functionality on the
            Sites to work properly. This is the default setting for most modern
            browsers. Users are to refer to their browser's Help for more
            information about enabling cookies.
          </p>
          <p>
            A cookie is a small data file that is written to a User’s hard drive
            when the User visits certain websites. Cookie files contain certain
            information, such as a random number user ID that the Sites assign
            to a visitor to track the pages visited. The only information a
            cookie can contain is information that User’s supply themselves. A
            cookie cannot read data off a User’s hard disk or read cookie files
            created by other websites.
          </p>
          <p>
            Cookies allow a User to use more sophisticated interactive programs.
            Cookies, by themselves, cannot be used to find out the identity of
            any User. Only information provided voluntarily is stored, but this
            information is not personally identifiable.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Supported Browsers</h2>
          <p>
            In general, the Sites support the current and prior major release of
            Chrome, Firefox, Internet Explorer and Safari (desktop version) on a
            rolling basis. Using the latest version of these browsers is highly
            recommended.
          </p>
          <p>
            The Sites currently do not support any Internet Explorer versions
            lower than Internet Explorer 8. The mobile versions of Safari are
            also not supported.
          </p>
          <p>
            If a User uses an unsupported browser or a browser other than the
            aforementioned, the User might be unable to use the Sites or a part
            or all pages on the Sites might not be viewed as intended.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>No Warranty</h2>
          <p>
            Autopit does not guarantee continuous, uninterrupted or secure
            access to the Services, and operation of the Sites may be interfered
            with by numerous factors outside Autopit's control.
          </p>
          <p>
            The Sites and the Services are provided "as is" and as and when
            available, without any warranty of any kind whether express or
            implied including but not limited to warranties of merchantability,
            fitness for purpose, title or non-infringement.
          </p>
          <p>
            To the extent permissible by law Autopit excludes all implied
            warranties, conditions or other terms, whether implied by statute or
            otherwise, including without limitation any terms as to skill and
            care or timeliness of performance.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Links to Third Party Websites</h2>
          <p>
            The Sites may contain links and/or references to other websites
            ('Third Party Websites'). Autopit shall not be responsible for the
            contents, accuracy and/or opinions expressed in Third Party Websites
            (which are not investigated, monitored or checked for accuracy or
            completeness by Autopit).
          </p>
          <p>
            Inclusion of and/or reference to any links or internet addresses on
            the Sites do not imply approval or endorsement of those sites by
            Autopit.
          </p>
          <p>
            In the event the User decides to leave the Sites and access Third
            Party Sites, the User does so at his/her own risk.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Trademarks</h2>
          <p>
            The Sites’ graphics, logos, page headers, button icons, scripts, and
            service names are trademarks, registered trademarks or trade dress
            of Autopit in Malaysia and/or other countries.
          </p>
          <p>
            Autopit's trademarks and trade dress may not be used in connection
            with any product or service that is not Autopit’s in any manner that
            is likely to cause confusion among customers or in any manner that
            disparages or discredits Autopit and/or the Sites.
          </p>
          <p>
            All other trademarks not owned by Autopit that appear on the Sites
            are the property of their respective owners, who may or may not be
            affiliated with, connected to, or sponsored by Autopit.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Electronic Communications</h2>
          <p>
            When the User visits the Sites or sends e-mails to Autopit, the User
            is communicating with Autopit electronically. The User consents to
            receive communications from Autopit electronically.
          </p>
          <p>
            Autopit may communicate with the User by email, SMS or by posting
            notices on the Sites. The User agrees that all agreements, notices,
            disclosures and other communications that Autopit provides to User
            electronically satisfy any legal requirement that such
            communications be in writing.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Advertisements and Promotions</h2>
          <p>
            Autopit runs advertisements and promotions. By using the Sites, the
            User agrees that Autopit has the right to run such advertisements
            and promotions on the Sites. The manner, mode and extent of
            advertising by Autopit are subject to change.
          </p>
          <p>
            The User’s correspondence or business dealings with, or
            participation in promotions of, advertisers found on or through the
            Sites, including payment and delivery of related goods or services,
            and any other terms, conditions, warranties or representations
            associated with such dealings, are solely between the User and such
            advertiser.
          </p>
          <p>
            The User agrees that Autopit shall not be responsible or liable for
            any loss or damage of any sort incurred as the result of any such
            dealings or as the result of the presence of such advertisers on the
            Sites.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Exclusion of Liabilities</h2>
          <p>
            To the full extent allowed by applicable law, in no event shall
            Autopit, its parent company, affiliates, subsidiaries, associated
            companies, directors, officers, employees, servants, suppliers,
            agents or assigns, be liable under any circumstances for:-
          </p>
          <p
            style={{
              marginLeft: "20px",
            }}
          >
            <ol>
              <li>
                any punitive, incidental or consequential damages and/or losses
                arising out of, arising/resulting from or related to this
                Agreement including economic losses (including, without
                limitation, loss of revenues, profits, contracts, business or
                anticipated savings); or
              </li>
              <li>
                other Users' content, actions or inactions, or vehicles/ goods/
                services that the other Users list; or
              </li>
              <li>
                the quality, safety, suitability or legality of vehicles/ goods/
                services advertised, the truth or accuracy of Users' content or
                listings, the ability of sellers to sell such vehicles/ goods/
                services, the ability of buyers to pay for such vehicles/ goods/
                services, or that a buyer or seller will actually complete a
                transaction; or
              </li>
              <li>any loss of goodwill or reputation; or</li>
              <li>
                any special, indirect or consequential damage arising out of or
                in connection with this Agreement, including but not limited to
                actions for breach of contract, actions under the tort of
                negligence, defamation, copyright infringement, trade mark
                infringement, passing-off, infringement of registered industrial
                designs , patent infringement, breach of confidence; or
              </li>
              <li>any liability at common law; or</li>
              <li>
                any bugs, viruses, trojan horses, attack, interference, hacking,
                or other security intrusion or the like which may be transmitted
                to or through the Site or by any third party; or
              </li>
              <li>in any other way.</li>
            </ol>
          </p>
          <p>
            Autopit is not involved in the actual transaction between buyers and
            sellers. Autopit does not transfer legal ownership of vehicles/
            goods/ services from the seller to the buyer, and nothing in this
            Agreement shall modify the local jurisdiction, under which legal
            ownership of an item is transferred when the parties (i.e., seller
            and buyer) intend to transfer such legal ownership.
          </p>
          <p>
            Autopit cannot guarantee continuous or secure access to the
            Services, and operation of the Site may be interfered with by
            numerous factors outside of Autopit’s control. For avoidance of
            doubt, Autopit is not liable and shall not be liable for the acts or
            omissions of other providers of telecommunications services or for
            faults in or failure of their networks and equipment
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Indemnity</h2>
          <p>
            The User at all times agrees and undertakes to fully indemnify, hold
            harmless and defend Autopit, its parent company, subsidiaries,
            associated companies, directors, officers, employees, servants,
            suppliers, agents or assigns from any actions, claim, demand,
            proceedings, costs, liabilities and expenses (including reasonable
            legal fees and disbursements) sustained, incurred or paid by Autopit
            directly or indirectly, due to, arising out of, resulting from or in
            relation to the User's breach of this Agreement (including the
            documents incorporated by reference), or arising out of the User's
            violation of any law or the rights of a third party.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Release</h2>
          <p>
            In the event that one User has a dispute, claim and/or action
            against one or more other Users, the User hereby agrees to release
            Autopit, its parent company, affiliates, subsidiaries, associated
            companies, directors, officers, employees, servants, suppliers,
            agents or assigns to the full extent allowed by the applicable law
            from all claims, demands, expenses, costs and damages (actual and
            consequential) of every kind and nature, known and unknown,
            suspected and unsuspected, disclosed and undisclosed (including
            legal fees) arising out of or in any way connected with such
            disputes.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>Governing Laws</h2>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of Malaysia, and shall be subject to the non-exclusive
            jurisdiction of Malaysian courts.
          </p>
        </div>
        <div className="policies-container-2">
          <h2>General Provisions</h2>
          <p>
            <ol>
              <li>
                Any person who is not a party to this Agreement does not have
                any right to enforce any of the terms and conditions contained
                herein.
              </li>
              <li>
                If any provision of the Agreement is held to be invalid or
                unenforceable, such provision shall be struck and the remaining
                provisions shall be enforced.
              </li>
              <li>
                The User agrees that this Agreement and all incorporated
                agreements may be automatically assigned by Autopit, in its sole
                discretion, to a third party in the event of a merger or
                acquisition.
              </li>
              <li>
                The User and Autopit are independent, and no agency,
                partnership, joint venture, employee-employer or
                franchisor-franchisee relationship is intended or created by
                this Agreement.
              </li>
              <li>
                Failure of Autopit to act with respect to a breach by the User
                or others shall not constitute a waiver of its right to act with
                respect to subsequent or similar breaches. Autopit does not
                guarantee that action will be taken against all breaches of the
                Agreement.
              </li>
              <li>
                Headings and captions of this Agreement are included for ease of
                reference only and in no way define, limit, construe or describe
                the scope or extent of any provisions in this Agreement.
              </li>
              <li>
                Words importing one gender shall include any other gender unless
                stated otherwise.
              </li>
              <li>
                Words in the singular number shall include the plural and words
                in the plural number include the singular unless otherwise
                stated.
              </li>
              <li>
                The following Sections survive any termination of the Agreement:
                Release, Use of Site Content, Exclusion of Liabilities and
                Indemnity.
              </li>
            </ol>
          </p>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
