import React, { useEffect, useState } from "react";
import "../../App.css";
import "./Cars.scss";
import "./SingleCarDetails.scss";
import "./MakePayment.scss";
import axios from "../../api/axios";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { GrLocation } from "react-icons/gr";
import Dropdown from "react-bootstrap/Dropdown";
import { message, InputNumber } from "antd";
import Form from "react-bootstrap/Form";
import { Modal, Table } from "react-bootstrap";
import TermsAndConditionsComponent from "../policies/termscondition_component";

const CarAttribute = (props) => {
  return (
    <>
      <div className="sgcars-attri_item">
        <img src={props.attri_imgsrc} alt={props.attri_imgalttext} />

        <p>{props.attri_desc}</p>
        <h5>{props.attri_type}</h5>
      </div>
    </>
  );
};

function MakePayment() {
  // images
  const fpx_img = require("../../images/payment-fpx-03.png");
  const ipay88_img = require("../../images/payment-ipay88-03.png");
  // const creditcard_img = require("../../images/BG-42.png");
  const attri_1 = require("../../images/BG-29.png");
  const attri_2 = require("../../images/BG-30.png");
  const attri_3 = require("../../images/BG-31.png");
  const attri_4 = require("../../images/BG-32.png");
  const attri_5 = require("../../images/BG-33.png");
  const attri_6 = require("../../images/BG-34.png");
  const attri_7 = require("../../images/BG-35.png");

  // checkboxes & modal
  const [check_Privacy, setCheckPrivacy] = useState(false);
  const [check_Refund, setCheckRefund] = useState(false);
  const [check_Terms, setCheckTerms] = useState(false);
  const [check_PersonalData, setCheckPersonalData] = useState(false);
  const [show_privacypolicy, setShowPrivacyPolicy] = useState(false);
  const handleClosePrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };
  const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);
  const [show_Terms, setShowTerms] = useState(false);
  const handleCloseTerms = () => {
    setShowTerms(false);
  };
  const handleShowTerms = () => setShowTerms(true);

  useEffect(() => {
    document.title = "Make Payment | AutoPit";
  }, []);

  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const car_pathname = location.pathname.split("/")[2];

  // checkout id
  const checkout_path_id = location.search.split("=")[1];

  // location state for checkoutID & grandtotal
  const state_checkoutid = location?.state?.CheckoutID;
  const state_grandtotal = location?.state?.GrandTotal;
  // console.log("location", location);

  const base_url = `https://api.autopitonline.com/api/v1/cars/${car_pathname}`;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [carData, setCarData] = useState([]);

  useEffect(() => {
    fetch(`https://api.autopitonline.com/api/v1/cars/${car_pathname}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setCarData(result);

          getDutyTable(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  let carDetails = carData.data;
  // let DepositAmount = 0.1 * carData.data;

  // get dutylist table
  const [dutylistTable, setDutyListTable] = useState([]);
  const getDutyTable = async (data) => {
    // console.log('carData: ', carData.data);
    const dutyMonth =
      (Number(new Date().getFullYear()) -
        Number(data.data?.product_attribute.manufacture_year)) *
      12;
    try {
      const response = await axios.post(
        "calculators/duty-list",
        {
          brand: data.data?.product_attribute?.brand_model_group.brand_id,
          model: data.data?.product_attribute?.brand_model_group.model_group_id,
          // month: (Number(new Date().getFullYear()) - Number(data.data?.product_attribute.manufacture_year)) * 12,
          month: (dutyMonth > 60 ? "61" : dutyMonth).toString(),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setDutyListTable(response.data.duty_list);
    } catch (error) {
      console.error("error", error);
    }
  };

  const [checkoutID, setCheckoutID] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [depositAmount, setDepositAmount] = useState(0);

  const [depositInput, setDepositInput] = useState(0);
  const onChange_DepositInput = (deposit_value) => {
    console.log("deposit amount", deposit_value);
    setDepositInput(deposit_value);
    setDepositAmount(deposit_value);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "This is a success message",
    });
  };
  const SubmitDeposit = async () => {
    var successTimeout = null;
    try {
      const submitDeposit = await axios.post(
        "orders",
        {
          // checkout_id: "",
          checkout_id: state_checkoutid,
          deposit_amount: depositAmount,
          payment_method: paymentMethod,
          channel: "web",
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.accessToken,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // console.log('submitDeposit', submitDeposit);

      messageApi.open({
        type: "loading",
        content: "Processing payment, please do not leave this page.",
      });

      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        window.location.href = submitDeposit?.data?.data?.redirect_url;
      }, 3000);
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data?.errors?.deposit_amount) {
        messageApi.open({
          type: "warning",
          // content: 'Error processing payment, please ensure all fields are correct',
          content: `Warning: ${error?.response?.data?.errors?.deposit_amount[0]}`,
        });
      } else if (error?.response?.data?.errors?.payment_method) {
        messageApi.open({
          type: "warning",
          content: `Warning: ${error?.response?.data?.errors?.payment_method[0]}`,
        });
      } else {
        messageApi.open({
          type: "warning",
          content: `Error processing payment`,
        });
      }
    }
  };

  return (
    <>
      <div className="wrap-sgcars-hero">
        <div className="cars-hero-container">
          <h1>
            Make your payment with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-payment">
        <div className="sgcars-header">
          {error ? (
            <> {console.log("error", error.message)} </>
          ) : !isLoaded ? (
            <>{console.log("loading...")}</>
          ) : (
            <>
              <div className="sgcars-breadcrumbs">
                <a href="/cars" className="sgcars-breadcrumbs-item">
                  Buy Car{" "}
                </a>
                <span className="sgcars-breadcrumbs-divider">/</span>
                <a
                  href={
                    "/cars?brand=" +
                    carDetails.product_attribute.brand_model_group.brand.id
                  }
                  className="sgcars-breadcrumbs-item"
                >
                  {carDetails.product_attribute.brand_model_group.brand.title}{" "}
                </a>
                <span className="sgcars-breadcrumbs-divider">/</span>
                <a
                  href={"/cars/" + carDetails.url_slug}
                  className="sgcars-breadcrumbs-item"
                >
                  {carDetails.title}{" "}
                </a>
                <span className="sgcars-breadcrumbs-divider">/</span>
                <h2 className="sgcars-breadcrumbs-item"> Payment</h2>
              </div>
            </>
          )}
        </div>

        {error ? (
          // <div>Error: {error.message}</div>
          <>
            {console.log("error", error.message)}
            <div className="prod-content">
              <Alert variant="danger">
                <Alert.Heading className="ErrorRequest_Head">
                  Too many requests!{" "}
                </Alert.Heading>
                <hr />
                <p>Please try again later. </p>
              </Alert>
            </div>
          </>
        ) : !isLoaded ? (
          // <div>Loading...</div>
          <div className="LoadingSpinner">
            <Spinner animation="border" role="status" variant="info">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="pcars-container">
            {contextHolder}
            <figure className="pcars-img-holder">
              <img
                src={carDetails.thumbnail}
                alt={carDetails.title + " | AutoPit"}
              />
            </figure>

            <div className="pcars-details-header">
              <p>
                {carDetails.product_attribute.brand_model_group.brand.title}
              </p>
              <h3>{carDetails.title}</h3>

              <div className="cars-card-item-country">
                <GrLocation className="cars-card-item-country-icon" />
                {carDetails.product_attribute.country.iso_alpha2_code},{" "}
                {carDetails.product_attribute.country.country_name}
              </div>
            </div>

            <Dropdown.Divider className="sgcars-item-div pcars-item-div" />

            <div className="sgcars-item-attribute">
              <CarAttribute
                attri_imgsrc={attri_1}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.condition.title}
                attri_type="Condition"
              />
              <CarAttribute
                attri_imgsrc={attri_2}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.manufacture_year}
                attri_type="Manufacture Year"
              />
              <CarAttribute
                attri_imgsrc={attri_3}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.mileage}
                attri_type="Mileage"
              />
              <CarAttribute
                attri_imgsrc={attri_4}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.colour.title}
                attri_type="Colour"
              />
              <CarAttribute
                attri_imgsrc={attri_5}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.engine_capacity}
                attri_type="Engine Capacity"
              />
              <CarAttribute
                attri_imgsrc={attri_6}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.transmission.title}
                attri_type="Transmission"
              />
              <CarAttribute
                attri_imgsrc={attri_7}
                attri_imgalttext={carDetails.title + " | AutoPit"}
                attri_desc={carDetails.product_attribute.seats}
                attri_type="Car Seats"
              />
            </div>

            {dutylistTable?.length === 0 ? (
              <></>
            ) : (
              <>
                <Dropdown.Divider className="sgcars-item-div pcars-item-div" />

                <div className="sgcars-item-dutylist">
                  <h5>Duty List</h5>

                  <Table className="sgcars-item-dutylist-table">
                    <thead>
                      <tr>
                        <th>CC</th>
                        <th className="sgcars-item-dutylist-border">Year</th>
                        <th className="sgcars-item-dutylist-border">Months</th>
                        <th>Duty (SST)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dutylistTable?.duty_list?.map((item, i) => (
                        <tr key={i}>
                          <td
                            style={
                              i === 0
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                          >
                            {carDetails.product_attribute.engine_capacity}
                          </td>
                          <td className="sgcars-item-dutylist-border">
                            {item.year}
                          </td>
                          <td className="sgcars-item-dutylist-border">
                            {item.month}
                          </td>
                          <td>
                            MYR{" "}
                            {item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            <Dropdown.Divider className="sgcars-item-div pcars-item-div" />

            <div className="pcars-price-summary">
              <div className="pcars-summary-item-2">
                <h5>All Inclusive Price</h5>

                <div className="pcars-summary-item-2-wrap">
                  <p className="pcars-summary-item-2-1">Car Price</p>
                  <p className="pcars-summary-item-2-2">Included</p>
                </div>
                <div className="pcars-summary-item-2-wrap">
                  <p className="pcars-summary-item-2-1">Tax Duty</p>
                  <p className="pcars-summary-item-2-2">Included</p>
                </div>
                <div className="pcars-summary-item-2-wrap">
                  <p className="pcars-summary-item-2-1">Approve Permit</p>
                  <p className="pcars-summary-item-2-2">Included</p>
                </div>
                <div className="pcars-summary-item-2-wrap">
                  <p className="pcars-summary-item-2-1">Shipment Fees</p>
                  <p className="pcars-summary-item-2-2">Included</p>
                </div>

                {/* {carData.data?.road_tax === null ? (
                                <></>
                            ) : (
                                <div className="pcars-summary-item-2-wrap">
                                    <p className="pcars-summary-item-2-1">Road Tax</p>
                                    <p className="pcars-summary-item-2-2">Included</p>
                                </div>
                            ) }

                            {carData.data?.warranty === null ? (
                                <></>
                            ) : carData.data?.warranty === "0.00" ?(
                                <></>
                            ) : (
                                <div className="pcars-summary-item-2-wrap">
                                    <p className="pcars-summary-item-2-1">Warranty ({carData.data?.warranty} Years)</p>
                                    <p className="pcars-summary-item-2-2">Included</p>
                                </div>
                            ) }
                            
                            {carData.data?.puspakom_inspection_fee === null ? (
                                <></>
                            ) : carData.data?.puspakom_inspection_fee === "0.00" ?(
                                <></>
                            ) : (
                                <div className="pcars-summary-item-2-wrap">
                                    <p className="pcars-summary-item-2-1">Puspakom Inspection Fee</p>
                                    <p className="pcars-summary-item-2-2">Included</p>
                                </div>
                            ) }
                            
                            {carData.data?.loan_processing_fee === null ? (
                                <></>
                            ) : carData.data?.loan_processing_fee === "0.00" ?(
                                <></>
                            ) : (
                                <div className="pcars-summary-item-2-wrap">
                                    <p className="pcars-summary-item-2-1">Loan Processing Fee</p>
                                    <p className="pcars-summary-item-2-2">Included</p>
                                </div>
                            ) } */}
              </div>

              <div className="pcars-summary-item-3">
                <p>* Service Charges & Loan Is Not Included</p>
                <p>* Miscellaneous Charges please contact customer services</p>
              </div>
            </div>

            <div className="pcars-price-summary">
              <div className="pcars-summary-item-1">
                <h4 className="pcars-summary-item-1-1">1st Payment</h4>
                <p className="pcars-summary-item-1-2">
                  MYR{" "}
                  {state_grandtotal
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  <br />
                  <span>* Calculation result only valid for three days.</span>
                </p>
              </div>
            </div>

            <div className="pcars-price-payment">
              <div className="pcars-pricing-item-1">
                <div className="pcars-pricing-item-1-1">Pay Now</div>
                <div className="pcars-pricing-item-1-2">
                  <h6>Deposit Amount</h6>
                  <p>Minimum deposit amount is MYR 1,000.00</p>
                  {/* <p>Minimum deposit amount is 10% of grand total</p> */}
                </div>

                <div className="pcars-pricing-item-1-3">
                  MYR{" "}
                  <InputNumber
                    min={0.1 * state_grandtotal}
                    max={state_grandtotal}
                    onChange={onChange_DepositInput}
                    // placeholder={parseFloat(0.1*state_grandtotal).toFixed(2)}
                    placeholder={`1000.00`}
                  />
                </div>
              </div>
              <div className="pcars-pricing-item-2">
                <div className="pcars-pricing-item-2-1">Pay Later</div>
                <h6>Outstanding 1st Payment Balance</h6>
                <p>
                  MYR{" "}
                  {(state_grandtotal - depositAmount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
            </div>

            <div className="pcars-payment-method">
              <h5>Payment Methods</h5>

              <div className="pcars-p-method-container">
                <div className="pcars-p-method-1">
                  <button
                    className={
                      paymentMethod === "ipay88"
                        ? "selected paymethod-variation-1"
                        : "paymethod-variation-1"
                    }
                    onClick={() => setPaymentMethod("ipay88")}
                  >
                    <div className="paymethod-var-checkbox">
                      <span
                        aria-hidden="true"
                        data-icon="&#xe90e;"
                        className="paymethod-icon-1"
                      ></span>
                    </div>

                    <div className="paymethod-var-item-1">
                      <figure>
                        <img
                          src={fpx_img}
                          alt="Autopit FPX/iPay88 Payment Method"
                        />
                        <img
                          src={ipay88_img}
                          alt="Autopit FPX/iPay88 Payment Method"
                        />
                      </figure>

                      <div>
                        <h6>FPX Payment</h6>
                        <p>Payment via online banking</p>
                      </div>
                    </div>
                  </button>
                </div>

                {/* <div className="pcars-p-method-2">
                  <button className={ paymentMethod === 'others' ? 'selected paymethod-variation-1' : 'paymethod-variation-1' } onClick={()=>setPaymentMethod('others')}>
                    <div className="paymethod-var-checkbox"> 
                        <span aria-hidden="true" data-icon="&#xe90e;" className='paymethod-icon-1'></span>
                    </div>

                    <div className="paymethod-var-item-1">
                        <figure>
                            <img src={creditcard_img} alt="Autopit Debit / Credit Card Payment Method" />
                        </figure>

                        <div>
                            <h6>Others</h6>
                            <p>Other payment method</p>
                          </div>
                      </div> 
                  </button>
                </div> */}
              </div>
            </div>

            <div className="pcars-payment-details">
              <h5>Payment Details</h5>

              <div className="pcars-payment-details-item">
                <div className="pcars-payment-details-item-1">
                  <p className="pcars-payment-details-item-1-1">Subtotal</p>
                  <p className="pcars-payment-details-item-1-2">
                    MYR{" "}
                    {state_grandtotal
                      ?.toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
                <div className="pcars-payment-details-item-1">
                  <p className="pcars-payment-details-item-1-1">
                    Total Payment
                  </p>
                  <p className="pcars-payment-details-item-1-2">
                    MYR{" "}
                    {state_grandtotal
                      ?.toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>

                <div className="pcars-payment-details-item-2">
                  <p className="pcars-payment-details-item-2-1">
                    Total Deposit
                  </p>
                  <p className="pcars-payment-details-item-2-2">
                    MYR{" "}
                    {depositAmount
                      ?.toFixed(2)
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
              </div>
            </div>

            <div className="pcars-payment-refund">
              <p>* 7 days cooling off period refund</p>
            </div>

            <div className="pcars-payment-checkboxes">
              <Form.Check
                type="checkbox"
                id="privacyP"
                className="pcars-checkbox-item flex items-center"
              >
                <Form.Check.Input
                  type="checkbox"
                  value={check_Privacy}
                  onChange={(e) => {
                    setCheckPrivacy(!check_Privacy);
                  }}
                  required
                  isValid
                />
                {/* <Form.Check.Label htmlFor='privacyP' className='pcars-checkbox-label'>
                                I hereby read & agree with the <span>Privacy Policy</span>
                            </Form.Check.Label> */}
                <div className="pcars-checkbox-label">
                  I hereby read & agree with the{" "}
                  <span onClick={handleShowPrivacyPolicy}>Privacy Policy</span>
                </div>
              </Form.Check>
              {/* <Form.Check type='checkbox' id="refundP" className='pcars-checkbox-item flex items-center'>
                            <Form.Check.Input type='checkbox' onChange={()=> {setCheckRefund(!check_Refund)}} required isValid />
                            <div className='pcars-checkbox-label'>
                                I hereby read & agree with the <span>Refund Policy</span>
                            </div>
                        </Form.Check> */}
              <Form.Check
                type="checkbox"
                id="termsC"
                className="pcars-checkbox-item flex items-center"
              >
                <Form.Check.Input
                  type="checkbox"
                  onChange={() => {
                    setCheckTerms(!check_Terms);
                  }}
                  required
                  isValid
                />
                <div className="pcars-checkbox-label">
                  I hereby read & agree with the{" "}
                  <span onClick={handleShowTerms}>Terms & Conditions</span>
                </div>
              </Form.Check>
              {/* <Form.Check type='checkbox' id="personalD" className='pcars-checkbox-item flex items-center'>
                            <Form.Check.Input type='checkbox' onChange={()=> {setCheckPersonalData(!check_PersonalData)}} required isValid />
                            <div className='pcars-checkbox-label'>
                                I hereby read & agree with the <span> Personal Data Protection Act</span>
                            </div> 
                        </Form.Check> */}
            </div>

            {/* {check_Privacy === true && check_Refund === true && check_Terms === true && check_PersonalData === true ? ( */}
            {check_Privacy === true && check_Terms === true ? (
              <button onClick={SubmitDeposit} className={`pcars-payment-btn `}>
                Make Payment
              </button>
            ) : (
              <div className={`pcars-payment-btn disabled`}>Make Payment</div>
            )}
          </div>
        )}
      </div>

      <Modal
        className="checkbox-modal"
        size="lg"
        centered
        show={show_privacypolicy}
        onHide={handleClosePrivacyPolicy}
      >
        <Modal.Header className="checkbox-modal-header" closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="checkbox-modal-body">
          <p>
            This policy sets out how Autopitonline.com collect, use, disclose
            and manage your personal information. Other group companies of
            Autopit have their own privacy policies which can be found on their
            websites.
          </p>
          <p>
            Personal information is information or an opinion about an
            identified individual, or an individual who is reasonably
            identifiable.
          </p>
          <p>
            By accessing or using the network of websites, apps and related
            services operated by Autopit you agree to the collection, use and
            disclosure of your personal information in the manner described in
            this Privacy Policy.
          </p>
          <ul>
            <li>
              the Autopit vehicle search engine, including where hosted by
              Autopit on manufacturer or dealer websites; and
            </li>
            <li>the Autopit e-commerce stores;</li>
            (together the “Autopit”).
          </ul>
          <p>
            If, through using a Autopit website, app or our services you
            disclose personal information to a third party (including via their
            website), this Privacy Policy will not apply as between you and the
            third party and you will be subject to the privacy policy of that
            third party. Autopit is not responsible for third party privacy
            policies and recommends that you review them carefully before
            submitting personal information.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="checkbox-modal-btn"
            onClick={handleClosePrivacyPolicy}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="checkbox-modal"
        size="lg"
        centered
        show={show_Terms}
        onHide={handleCloseTerms}
      >
        <Modal.Header className="checkbox-modal-header" closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="checkbox-modal-body">
          <TermsAndConditionsComponent />
        </Modal.Body>
        <Modal.Footer>
          <button className="checkbox-modal-btn" onClick={handleCloseTerms}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MakePayment;
