import React, { useState, useEffect } from "react";
import "../App.css";
import "./Landing.scss";
import { useLocation } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-bootstrap/Modal";

// slide up animation
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

function Landing() {
  const right_arrow = require("../images/right-arrow-head.png");
  const whychooseus_1 = require("../images/BG-04.png");
  const whychooseus_2 = require("../images/BG-05.png");
  const whychooseus_3 = require("../images/BG-06.png");
  const whychooseus_4 = require("../images/BG-07.png");
  const visionmission_1 = require("../images/BG-14.png");
  const country_1 = require("../images/BG-13.png");
  const country_2 = require("../images/BG-11-01.png");
  const country_3 = require("../images/BG-12.png");
  const services_1 = require("../images/BG-09.png");
  const services_2 = require("../images/BG-26.png");
  const services_3 = require("../images/BG-10.png");
  const brands_1 = require("../images/BG-16.png");
  const brands_2 = require("../images/BG-17.png");
  const brands_3 = require("../images/BG-18.png");

  // slider settings
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`,
      );
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // show payment success/fail modal
  const location = useLocation();
  const clean_location = location.search.replace(/&/g, "=").split("=")[1];

  // console.log('location', clean_location);

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  const [showFailed, setShowFailed] = useState(false);
  const handleCloseFailed = () => setShowFailed(false);
  const handleShowFailed = () => setShowFailed(true);

  const handleOrderStatus = () => {
    var successTimeout = null;

    if (clean_location === "success") {
      setShowFailed(false);
      setShowSuccess(true);

      // clearTimeout( successTimeout );
      // successTimeout = setTimeout( function() {
      //     window.location.href="profile/orders";
      // }, 2000 );
    } else if (clean_location === "failed") {
      setShowFailed(true);
      setShowSuccess(false);
    } else {
      setShowFailed(false);
      setShowSuccess(false);
    }
  };

  useEffect(() => {
    handleOrderStatus();
  });

  return (
    <>
      <div className="wrap-hero">
        <div className="hero-container">
          <h1>
            We ensure that your decision of choosing <span>AutoPit</span> as
            your
          </h1>

          <div className="hero-linediv"></div>
          <p className="hero-text-1">
            Get the most attractive & flexible deals to finance your dream car.
          </p>
          <p className="hero-text-2">
            Prepare pre-loan application requirements to hasten the process.
          </p>
        </div>
      </div>

      <div className="wrap-landing">
        <div className="ld-container-1">
          <div className="ld-1-content">
            <div className="ld-1-item-1">
              <div className="ld-1-item-1-header">
                <h2>
                  01 <span>Step</span>
                </h2>
              </div>
              <div className="ld-1-item-1-body">
                <h3>Inquiry and research</h3>
                <p>
                  Explore hundreds of thousands of cars Worldwide, search for
                  the one that matches your preference.
                </p>
              </div>
            </div>

            <div className="ld-1-item-2">
              <img src={right_arrow} alt="Steps to choosing the right car" />
            </div>

            <div className="ld-1-item-1">
              <div className="ld-1-item-1-header">
                <h2>
                  02 <span>Step</span>
                </h2>
              </div>
              <div className="ld-1-item-1-body">
                <h3>Appointment with us</h3>
                <p>
                  Get the best deal today! Contact our professional advisor to
                  assure the quality of the car and enquire on buy/sell, car
                  insurance service.
                </p>
              </div>
            </div>

            <div className="ld-1-item-2">
              <img src={right_arrow} alt="Steps to choosing the right car" />
            </div>

            <div className="ld-1-item-1 ">
              <div className="ld-1-item-1-header">
                <h2>
                  03 <span>Step</span>
                </h2>
              </div>
              <div className="ld-1-item-1-body">
                <h3>You are all set!</h3>
                <p>
                  Complete and make the deal. Congratulations on getting a new
                  ride!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ld-container-2">
          <div className="ld-2-content">
            <div className="ld-2-item-1">
              <h2>AutoPit Malaysia's #1</h2>
              <h3>Source for Recon Automotive</h3>

              <p className="ld-2-content-text-1">
                AutoPit was founded in the year 2022, focusing to provide
                customers with a professional car shopping experience, the
                primary position of the company's main service is to source used
                cars namely, recon cars imported from places such as Japan,
                Australia, or the UK. The price of these premium cars is cheaper
                than the official imports which provide customers with plenty of
                new car purchases.
              </p>
              <p className="ld-2-content-text-2">
                We also provide professional consultation, insurance, one-stop
                service, and more with just a few short steps! Researching and
                buying a car has never been easier with AutoPit.
              </p>
            </div>
          </div>
        </div>

        <div className="ld-container-3">
          <div className="ld-3-content">
            <div className="ld-3-item-1">
              <img src={whychooseus_1} alt="Trustworthy Dealership" />
              <p>Trustworthy Dealership</p>
            </div>
            <div className="ld-3-item-1">
              <img src={whychooseus_2} alt="Professional 1-to-1 Consultation" />
              <p>Professional 1-to-1 Consultation</p>
            </div>
            <div className="ld-3-item-1">
              <img src={whychooseus_3} alt="Transparent Car Information" />
              <p>Transparent Car Information</p>
            </div>
            <div className="ld-3-item-1">
              <img src={whychooseus_4} alt="Hassle-Free E-bidding Process" />
              <p>Hassle-Free E-bidding Process</p>
            </div>
          </div>
        </div>

        <div className="ld-container-4">
          <div className="ld-4-content">
            <div className="ld-4-item-1">
              <img src={visionmission_1} alt="Our Vision & Mission - AutoPit" />
            </div>

            <div className="ld-4-item-2">
              <div className="ld-4-item-2-1">
                <h2>Vision</h2>
                <p>Be the first imported vehicle choice of Malaysian</p>
              </div>
              <div className="ld-4-item-2-2">
                <h2>Mission</h2>
                <p>
                  We strive to provide a hassle-free and transparent platform
                  for the customer in buying vehicles
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ld-container-5">
          <div className="ld-cont-5-header">
            <h2>Our Business</h2>
          </div>

          <div className="ld-5-content">
            <div className="ld-5-item-1">
              <h3>
                Find your match with <span>AutoPit</span>
              </h3>

              <p>Get your car’s best offer today!</p>
            </div>
          </div>
        </div>

        <div className="ld-container-6">
          <div className="ld-cont-6-header">
            <h2>Country</h2>
          </div>

          <div className="ld-6-content">
            <div className="ld-6-item-1">
              <div className="ld-6-item-1-1">
                <img src={country_1} alt="Japanese Automobiles - AutoPit" />
                <h4>Japan</h4>
              </div>
              <div className="ld-6-item-1-1">
                <img
                  src={country_3}
                  alt="United Kingdom Automobiles - AutoPit"
                />
                <h4>United Kingdom</h4>
              </div>
              {/* <div className="ld-6-item-1-1">
                                <img src={country_3} alt="United States Automobiles - AutoPit" />
                                <h4>United States</h4>
                            </div> */}
            </div>
          </div>
        </div>

        <div className="ld-container-7">
          <div className="ld-cont-7-header">
            <h2>Services</h2>
          </div>

          <div className="ld-7-content">
            <div className="ld-7-item-1">
              <div className="ld-7-item-1-1">
                <img src={services_1} alt="Japanese Automobiles - AutoPit" />
                <h4>Car Insurance</h4>
              </div>
              <div className="ld-7-item-1-2">
                <img
                  src={services_2}
                  alt="United Kingdom Automobiles - AutoPit"
                />
              </div>
              <div className="ld-7-item-1-1">
                <img
                  src={services_3}
                  alt="United States Automobiles - AutoPit"
                />
                <h4>Car Mortgage</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="ld-container-8">
          <div className="ld-8-content">
            <div className="ld-8-cont-header">
              <h2>Pick Your Preferred Brand</h2>
            </div>

            <div className="ld-8-item-1">
              <Slider {...settings}>
                <div className="ld-8-item-cards-1">
                  <img src={brands_1} alt="McLaren - AutoPit" />
                </div>
                <div className="ld-8-item-cards-1">
                  <img src={brands_2} alt="Aston Martin - AutoPit" />
                </div>
                <div className="ld-8-item-cards-1">
                  <img src={brands_3} alt="Porsche - AutoPit" />
                </div>
                {/* <div className="ld-8-item-cards-1">
                                    <img src={brands_2} alt="Aston Martin - AutoPit" />
                                </div>
                                <div className="ld-8-item-cards-1">
                                    <img src={brands_3} alt="Porsche - AutoPit" />
                                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showSuccess}
        onHide={handleCloseSuccess}
        centered
        backdrop="static"
        className="transaction-message-mod"
      >
        <Modal.Header>
          <Modal.Title>Check Out</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span
            aria-hidden="true"
            data-icon="&#xe90e;"
            className="transaction-mod-icon suc-transac"
          ></span>
          <h3>Successful</h3>
          <p>Transaction successful</p>
          <button onClick={() => (window.location.href = "/")}>
            Back to Home
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFailed}
        onHide={handleCloseFailed}
        centered
        backdrop="static"
        className="transaction-message-mod"
      >
        <Modal.Header>
          <Modal.Title>Check Out</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span
            aria-hidden="true"
            data-icon="&#xe90f;"
            className="transaction-mod-icon failed-transac"
          ></span>
          <h3>Transaction Failed</h3>
          <p>Error processing transaction</p>
          <button onClick={() => (window.location.href = "/")}>
            Back to Home
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Landing;
