import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "../../App.css";
import "./Finance.scss";
import { useCookies } from "react-cookie";
import axios from "../../api/axios";
// import ImportResultComponent from "./ImportResultComponent";
// import Prompt_Modal from "../navigation/Modals/PromptLogin_Modal";
import TermsAndConditionsComponent from "../policies/termscondition_component";

import { message, Empty, InputNumber } from "antd";
import { Modal, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

function ImportPayment() {
  const fpx_img = require("../../images/payment-fpx-03.png");
  const ipay88_img = require("../../images/payment-ipay88-03.png");

  const [cookies] = useCookies(["accessToken"]);
  const location = useLocation();
  // const [cookies] = useCookies(["accessToken"]);
  const [messageApi, contextHolder] = message.useMessage();

  const headers_default = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-localization": "en",
  };

  const [reference_found, setReferenceFound] = useState(false);

  const [referenceNumber, setReferenceNumber] = useState(null);
  const [carPrice, setCarPrice] = useState(0);
  const [car_age, setCarAge] = useState(null);
  const [currencyRate, setCurrencyRate] = useState(0);
  const [currency, setCurrency] = useState([]);
  const [model, setModel] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const yearMap = {
    1: 2018,
    2: 2019,
    3: 2020,
    4: 2021,
    5: 2022,
    6: 2023,
    2018: 2018,
    2019: 2019,
    2020: 2020,
    2021: 2021,
    2022: 2022,
    2023: 2023,
  };
  const [import_status, setImportStatus] = useState(1);
  const [shipping_fee, setShippingFee] = useState(null);
  const [convertedprice, setConvertedPrice] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [dutyPrice, setDutyListPrice] = useState(0);
  const [serviceFeePrice, setServiceFeePrice] = useState(0);
  const [serviceFeeRate, setServiceFeeRate] = useState(0);
  const [permit_fee, setPermitFee] = useState(0);
  const [roadtax_fee, setRoadTaxFee] = useState(0);
  const [insurance_fee, setInsuranceFee] = useState(0);
  const [misc_fee, setMiscFee] = useState(0);
  const [finalImportPrice, setFinalImportPrice] = useState(0);
  const [firstPayment, setFirstPayment] = useState(0);
  const [secondPayment, setSecondPayment] = useState(0);

  const [import_validity, setImportValidity] = useState(true);

  // 6. Calculate import price
  // const [import_price_1, setImportPrice1] = useState(null);
  const [refModal, setRefModal] = useState(false);
  const handleCloseRefModal = () => setRefModal(false);

  useEffect(() => {
    const reference_number = location.search.split("=")[1];

    if (location.search.split("=")[1] === undefined) {
      setRefModal(true);
    } else {
      // getImportResult(reference_number);
      fetch(
        `https://api.autopitonline.com/api/v1/calculators/import?reference=${reference_number}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            // console.log(result.data);
            if (result.data != null) {
              setReferenceFound(true);
            } else {
              setRefModal(true);
              setReferenceFound(false);
            }
            // if (new Date() > new Date(result.data.valid_till) && result.data.status === 1) {
            if (new Date() > new Date(result.data.valid_till)) {
              setRefModal(true);
              // setReferenceFound(false);
            }
            // setImportValidity(true);
            setImportValidity(new Date() < new Date(result.data.valid_till));
            const currentYear = new Date().getFullYear();
            const getMonth = parseInt(new Date().getMonth() + 1);
            const carYear = yearMap[result.data.year];
            const carAge =
              (currentYear - carYear) * 12 +
              (getMonth - parseInt(result.data.month));
            setCarAge(carYear ? carAge : "-");

            setReferenceNumber(result.data.reference);
            setModel(result.data.model_group.title);
            setYear(result.data.year);
            setMonth(result.data.month);
            setCarPrice(result.data.original_price);

            setImportStatus(result.data.status);
            setCurrency(result.data.currency);
            setCurrencyRate(result.data.rate);
            setOriginalPrice(result.data.original_price);
            setServiceFeeRate(result.data.service_charge_rate);

            setConvertedPrice(result.data.converted_price);
            setDutyListPrice(result.data.duty_price);
            setShippingFee(result.data.shipping_price);
            setPermitFee(result.data.approve_permit);
            setServiceFeePrice(result.data.service_charge_price);
            setRoadTaxFee(result.data.road_tax_price);
            setInsuranceFee(result.data.insurance_price);
            setMiscFee(result.data.misc_price);
            setFinalImportPrice(result.data.final_price);

            setFirstPayment(result.data.first_payment);
            setSecondPayment(result.data.second_payment);

            console.log("import result", result.data);
          },
          (error) => {
            console.error(error);
            setRefModal(true);
          }
        );
    }
    // console.log('clean', location.search.split( '=' )[1]);
  }, []);

  // make payment
  // const [error_message, setErrorMessage] = useState('');
  const [warning, setWarning] = useState(false);
  const [input_depositAmount, setDepositAmount] = useState(0);
  const onChange_DepositInput = (deposit_value) => {
    // console.log('deposit amount', deposit_value);
    setDepositAmount(deposit_value);
  };
  const [paymentMethod, setPaymentMethod] = useState("");
  const makeImportPayment = async () => {
    var successTimeout = null;
    try {
      const response = await axios.post(
        "calculators/import/order",
        {
          reference: referenceNumber,
          deposit_amount: input_depositAmount,
          payment_method: paymentMethod,
          channel: "web",
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      messageApi.open({
        type: "loading",
        content: "Processing payment, please do not leave this page.",
      });

      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        window.location.href = response?.data?.data?.redirect_url;
      }, 3000);
    } catch (error) {
      // console.error(error);
      if (error.message === "Request failed with status code 500") {
        messageApi.open({
          type: "warning",
          content: `Error processing payment`,
        });
      } else if (error?.response?.data?.errors) {
        console.log(error.response?.data?.errors);
        const firstError = Object.values(error.response?.data.errors)[0][0];
        if (firstError) {
          messageApi.open({
            type: "warning",
            content: firstError,
          });
        } else if (error?.response?.data?.message === "Unauthenticated.") {
          messageApi.open({
            type: "warning",
            content: "Please login to continue.",
          });
        } else {
          messageApi.open({
            type: "warning",
            content: error.message,
          });
        }
      } else {
        messageApi.open({
          type: "warning",
          content:
            "Details for import has expired or you are not logged in. Please login to continue.",
        });
      }
    }
  };

  // checkboxes & modal
  const [check_Privacy, setCheckPrivacy] = useState(false);
  const [check_Refund, setCheckRefund] = useState(false);
  const [check_Terms, setCheckTerms] = useState(false);
  const [check_PersonalData, setCheckPersonalData] = useState(false);
  const [show_privacypolicy, setShowPrivacyPolicy] = useState(false);
  const handleClosePrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };
  const handleShowPrivacyPolicy = () => setShowPrivacyPolicy(true);
  const [show_Terms, setShowTerms] = useState(false);
  const handleCloseTerms = () => {
    setShowTerms(false);
  };
  const handleShowTerms = () => setShowTerms(true);

  return (
    <>
      {contextHolder}
      <div className="wrap-fin-hero">
        <div className="fin-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-fin">
        <div className="fin-container-1">
          <div className="fin-header">
            <h2>Import Payment</h2>
          </div>

          {reference_found === true ? (
            <>
              <div className="fin-tab-pane-2-content">
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Reference Number</h4>
                  <p className="input_title_import-result">
                    {referenceNumber == null ? "-" : referenceNumber}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">
                    Car Model Specification
                  </h4>
                  <p className="input_title_import-result">
                    {model == null ? "-" : model}
                  </p>
                </div>
                <div
                  className="fin-tab-pane-2-input"
                  style={{ marginBottom: "30px" }}
                >
                  <h4 className="input_title_import">Car Age</h4>
                  <p className="input_title_import-result">
                    {`${car_age} Months Old [ ${month} / 
                      ${
                        year === "1"
                          ? "2018"
                          : year === "2"
                          ? "2019"
                          : year === "3"
                          ? "2020"
                          : year === "4"
                          ? "2021"
                          : year === "5"
                          ? "2022"
                          : year === "6"
                          ? "2023"
                          : year === "2018"
                          ? "2018"
                          : year === "2019"
                          ? "2019"
                          : year === "2020"
                          ? "2020"
                          : year === "2021"
                          ? "2021"
                          : year === "2022"
                          ? "2022"
                          : year === "2023"
                          ? "2023"
                          : "-"
                      } ]
                    `}
                  </p>
                </div>
                <div
                  className="imp-cal-item-3 flex gap-x-4 flex-nowrap"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="fin-tab-pane-2-input w-full relative">
                    <h4 className="input_title_import">Car Price</h4>
                    <p className="input_title_import-result">
                      {/* {currency.country.currency_symbol == "JPY"
                        ? `${ parseFloat(carPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } YEN`
                        : `${ parseFloat(carPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } GBP`} */}
                      {parseFloat(carPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {currency.country.currency_symbol}
                    </p>
                  </div>
                  <div className="fin-tab-pane-2-input w-full relative">
                    <h4 className="input_title_import">Shipment Fees</h4>
                    <p className="input_title_import-result">
                      {currency.country.currency_symbol === "JPY"
                        ? `${(
                            parseFloat(shipping_fee) * parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} JPY`
                        : `${(
                            parseFloat(shipping_fee) / parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GBP`}
                    </p>
                  </div>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">
                    Port arrival Total CIF Fees before clearance{" "}
                    <span>
                      ({" "}
                      {currency.country.currency_symbol === "JPY"
                        ? `${parseFloat(
                            parseFloat(originalPrice) +
                              parseFloat(shipping_fee) *
                                parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} JPY × ${Number(currencyRate).toFixed(4)}`
                        : `${parseFloat(
                            parseFloat(originalPrice) +
                              parseFloat(shipping_fee) /
                                parseFloat(currencyRate)
                          )
                            .toFixed(2)
                            .replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} GBP × ${Number(currencyRate).toFixed(4)}`}{" "}
                      ){" "}
                    </span>
                  </h4>
                  <p className="input_title_import-result">
                    {`MYR ${(
                      parseFloat(convertedprice) + parseFloat(shipping_fee)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}{" "}
                  </p>
                  <p
                    className="calculator_note"
                    style={{ border: "none", paddingBottom: "0" }}
                  >
                    {currency.country.currency_symbol === "JPY"
                      ? `JPY 100 = MYR ${Number(currencyRate * 100).toFixed(4)}`
                      : `£ 1 = MYR ${Number(currencyRate).toFixed(4)}`}{" "}
                    (Exchange rates are updated every Sunday following PBB
                    rates)
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">
                    Tax duty with documentation
                  </h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(dutyPrice)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Cost Price</h4>
                  <p className="input_title_import-result">
                    {/* {props.Cost_Price} */}
                    {`MYR ${(
                      parseFloat(
                        parseFloat(convertedprice) + parseFloat(shipping_fee)
                      ) + parseFloat(dutyPrice)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                {/* add 1st payment and 2nd payment */}
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">1st Payment</h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(firstPayment)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">2nd Payment</h4>
                  <p className="input_title_import-result">
                    {`MYR ${parseFloat(secondPayment)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  </p>
                </div>
                <div className="fin-tab-pane-2-input">
                  <h4 className="input_title_import">Service Fees (代购费)</h4>
                  <p className="input_title_import-result">
                    {/* {props.Service_Fees} */}
                    {`MYR ${parseFloat(serviceFeePrice)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${parseFloat(
                      serviceFeeRate
                    ).toFixed(0)}%)`}
                  </p>
                </div>
                {/* <div className="fin-tab-pane-2-input" style={{marginBottom:'30px'}}>
                  <h4 className='input_title_import'>Import Final Price / Bidding Won Price</h4>
                  <p className="input_title_import-result">
                    { import_status === 2 ? (
                        "MYR " + parseFloat(finalImportPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : import_status === 1 ? ( 
                        "MYR " + ( parseFloat(convertedprice) + parseFloat(shipping_fee) + 
                          parseFloat(serviceFeePrice) + parseFloat(dutyPrice) + parseFloat(permit_fee) +
                          parseFloat(misc_fee) + parseFloat(roadtax_fee) + parseFloat(insurance_fee) 
                        ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " (Pending)"
                      ) : (
                        <>Pending </>
                      )
                    }
                  </p>
              </div> */}
              </div>

              <div className="pcars-price-summary">
                <div className="pcars-summary-item-3">
                  <p>* Service Charges & Loan Is Not Included</p>
                  <p>
                    * Miscellaneous Charges please contact customer services
                  </p>
                  {import_status === 1 && (
                    <p>* Final Price are currently pending for approval</p>
                  )}
                </div>
              </div>

              <div className="pcars-price-summary">
                <div className="pcars-summary-item-1">
                  <h4 className="pcars-summary-item-1-1">
                    Import Final Price / Bidding Won Price
                  </h4>
                  <p className="pcars-summary-item-1-2">
                    {import_status === 2 ? (
                      "MYR " +
                      parseFloat(finalImportPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    ) : import_status === 1 ? (
                      "MYR " +
                      (
                        parseFloat(convertedprice) +
                        parseFloat(shipping_fee) +
                        parseFloat(serviceFeePrice) +
                        parseFloat(dutyPrice) +
                        parseFloat(permit_fee) +
                        parseFloat(misc_fee) +
                        parseFloat(roadtax_fee) +
                        parseFloat(insurance_fee)
                      )
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    ) : (
                      <>Pending </>
                    )}{" "}
                    <br />
                    <span>
                      * Calculation result only valid for three days.{" "}
                    </span>
                  </p>
                </div>
              </div>

              <div className="pcars-price-payment">
                <div className="pcars-pricing-item-1">
                  <div className="pcars-pricing-item-1-1">Pay Now</div>
                  <div className="pcars-pricing-item-1-2">
                    <h6>Booking Fees</h6>
                    <p>
                      Minimum deposit amount is MYR 1,000
                      {/* {parseFloat(0.1 * finalImportPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    </p>
                  </div>

                  <div className="pcars-pricing-item-1-3">
                    MYR{" "}
                    <InputNumber
                      min={1000}
                      max={finalImportPrice}
                      onChange={onChange_DepositInput}
                      placeholder={1000}
                    />
                  </div>
                </div>
                <div className="pcars-pricing-item-2">
                  <div className="pcars-pricing-item-2-1">Pay Later</div>
                  <h6>Outstanding 1st Payment Balance</h6>
                  <p>
                    MYR{" "}
                    {(Number(finalImportPrice) - Number(input_depositAmount))
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
              </div>

              <div className="pcars-payment-method">
                <h5>Payment Methods</h5>

                <div className="pcars-p-method-container">
                  <div className="pcars-p-method-1">
                    <button
                      className={
                        paymentMethod === "ipay88"
                          ? "selected paymethod-variation-1"
                          : "paymethod-variation-1"
                      }
                      onClick={() => setPaymentMethod("ipay88")}
                    >
                      <div className="paymethod-var-checkbox">
                        <span
                          aria-hidden="true"
                          data-icon="&#xe90e;"
                          className="paymethod-icon-1"
                        ></span>
                      </div>

                      <div className="paymethod-var-item-1">
                        <figure>
                          <img
                            src={fpx_img}
                            alt="Autopit FPX/iPay88 Payment Method"
                          />
                          <img
                            src={ipay88_img}
                            alt="Autopit FPX/iPay88 Payment Method"
                          />
                        </figure>

                        <div>
                          <h6>FPX Payment</h6>
                          <p>Payment via online banking</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div className="pcars-payment-details">
                <h5>Payment Details</h5>

                <div className="pcars-payment-details-item">
                  <div className="pcars-payment-details-item-1">
                    <p className="pcars-payment-details-item-1-1">Subtotal</p>
                    <p className="pcars-payment-details-item-1-2">
                      MYR{" "}
                      {parseFloat(finalImportPrice)
                        ?.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                  <div className="pcars-payment-details-item-1">
                    <p className="pcars-payment-details-item-1-1">
                      Total Payment
                    </p>
                    <p className="pcars-payment-details-item-1-2">
                      MYR{" "}
                      {parseFloat(finalImportPrice)
                        ?.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                  <div className="pcars-payment-details-item-1">
                    <p className="pcars-payment-details-item-1-1">
                      Outstanding for 2nd Payment
                    </p>
                    <p className="pcars-payment-details-item-1-2">
                      MYR{" "}
                      {(Number(finalImportPrice) - Number(input_depositAmount))
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>

                  <div className="pcars-payment-details-item-2">
                    <p className="pcars-payment-details-item-2-1">
                      Total Booking Fees For Deposit
                    </p>
                    <p className="pcars-payment-details-item-2-2">
                      MYR{" "}
                      {input_depositAmount !== null
                        ? input_depositAmount
                            ?.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : 0}
                    </p>
                  </div>
                </div>
              </div>

              <div className="pcars-payment-refund">
                <p>* 7 days cooling off period refund</p>
              </div>

              <div className="pcars-payment-checkboxes">
                <Form.Check
                  type="checkbox"
                  id="privacyP"
                  className="pcars-checkbox-item flex items-center"
                >
                  <Form.Check.Input
                    type="checkbox"
                    value={check_Privacy}
                    onChange={(e) => {
                      setCheckPrivacy(!check_Privacy);
                    }}
                    required
                    isValid
                  />
                  {/* <Form.Check.Label htmlFor='privacyP' className='pcars-checkbox-label'>
                    I hereby read & agree with the <span>Privacy Policy</span>
                </Form.Check.Label> */}
                  <div className="pcars-checkbox-label">
                    I hereby read & agree with the{" "}
                    <span onClick={handleShowPrivacyPolicy}>
                      Privacy Policy
                    </span>
                  </div>
                </Form.Check>
                {/* <Form.Check type='checkbox' id="refundP" className='pcars-checkbox-item flex items-center'>
                  <Form.Check.Input type='checkbox' onChange={()=> {setCheckRefund(!check_Refund)}} required isValid />
                  <div className='pcars-checkbox-label'>
                      I hereby read & agree with the <span>Refund Policy</span>
                  </div>
              </Form.Check> */}
                <Form.Check
                  type="checkbox"
                  id="termsC"
                  className="pcars-checkbox-item flex items-center"
                >
                  <Form.Check.Input
                    type="checkbox"
                    onChange={() => {
                      setCheckTerms(!check_Terms);
                    }}
                    required
                    isValid
                  />
                  <div className="pcars-checkbox-label">
                    I hereby read & agree with the{" "}
                    <span onClick={handleShowTerms}>Terms & Conditions</span>
                  </div>
                </Form.Check>
                {/* <Form.Check type='checkbox' id="personalD" className='pcars-checkbox-item flex items-center'>
                  <Form.Check.Input type='checkbox' onChange={()=> {setCheckPersonalData(!check_PersonalData)}} required isValid />
                  <div className='pcars-checkbox-label'>
                      I hereby read & agree with the <span> Personal Data Protection Act</span>
                  </div> 
              </Form.Check> */}
              </div>

              <Alert
                show={warning}
                variant="primary"
                onClose={() => setWarning(false)}
              >
                <p style={{ fontSize: "14px" }}>
                  Please confirm all details are correct before making payment
                </p>
              </Alert>

              {import_validity === false ? (
                <button className={`impRef-to-payment-btn disabled`}>
                  Make Payment
                </button>
              ) : import_status === 1 ? (
                <button className={`impRef-to-payment-btn disabled`}>
                  Make Payment
                </button>
              ) : check_Privacy === true && check_Terms === true ? (
                warning === false ? (
                  <button
                    className={`impRef-to-payment-btn`}
                    onClick={() => setWarning(true)}
                  >
                    Make Payment
                  </button>
                ) : (
                  warning === true && (
                    <button
                      className={`impRef-to-payment-btn`}
                      onClick={makeImportPayment}
                    >
                      Make Payment
                    </button>
                  )
                )
              ) : (
                <button className={`impRef-to-payment-btn disabled`}>
                  Make Payment
                </button>
              )}
            </>
          ) : (
            <Empty
              imageStyle={{
                height: 90,
              }}
              description={
                <span className="empty-results-span">No result found</span>
              }
            />
          )}
        </div>
      </div>

      <Modal
        show={refModal}
        onHide={handleCloseRefModal}
        className="login-modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="login-modal-header">
          <Modal.Title>
            Import Details
            <br />
            <p>Please ensure the reference provided is correct.</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <Empty
            imageStyle={{
              height: 90,
            }}
            description={``}
          />
          <div className="refpage-modalbody">
            <p>
              Import details for the reference is either invalid or expired.
            </p>
          </div>

          <div className="login-buttons">
            {/* <a href="/import-car" className="login-submit-btn"> */}
            <button className="login-submit-btn" onClick={handleCloseRefModal}>
              Close
            </button>
            {/* </a> */}
          </div>
        </Modal.Body>
      </Modal>

      {/*  T&C  */}
      <Modal
        className="checkbox-modal"
        size="lg"
        centered
        show={show_privacypolicy}
        onHide={handleClosePrivacyPolicy}
      >
        <Modal.Header className="checkbox-modal-header" closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="checkbox-modal-body">
          <p>
            This policy sets out how Autopitonline.com collect, use, disclose
            and manage your personal information. Other group companies of
            Autopit have their own privacy policies which can be found on their
            websites.
          </p>
          <p>
            Personal information is information or an opinion about an
            identified individual, or an individual who is reasonably
            identifiable.
          </p>
          <p>
            By accessing or using the network of websites, apps and related
            services operated by Autopit you agree to the collection, use and
            disclosure of your personal information in the manner described in
            this Privacy Policy.
          </p>
          <ul>
            <li>
              the Autopit vehicle search engine, including where hosted by
              Autopit on manufacturer or dealer websites; and
            </li>
            <li>the Autopit e-commerce stores;</li>
            (together the “Autopit”).
          </ul>
          <p>
            If, through using a Autopit website, app or our services you
            disclose personal information to a third party (including via their
            website), this Privacy Policy will not apply as between you and the
            third party and you will be subject to the privacy policy of that
            third party. Autopit is not responsible for third party privacy
            policies and recommends that you review them carefully before
            submitting personal information.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="checkbox-modal-btn"
            onClick={handleClosePrivacyPolicy}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="checkbox-modal"
        size="lg"
        centered
        show={show_Terms}
        onHide={handleCloseTerms}
      >
        <Modal.Header className="checkbox-modal-header" closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="checkbox-modal-body">
          <TermsAndConditionsComponent />
        </Modal.Body>
        <Modal.Footer>
          <button className="checkbox-modal-btn" onClick={handleCloseTerms}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportPayment;
