import React, { useState } from "react";
import "../../App.css";
import "./Finance.scss";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";

const twoDp = (num) => Math.round(num * 100) / 100;
const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeCommas = (num) => num.toString().replace(/,/g, "");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

function LoanCalculator() {
  const [carPrice, setCarPrice] = useState(100000);
  const [carPricePlaceholder, setCarPricePlaceholder] = useState(null);
  const [downPayment, setDownPayment] = useState(10000);
  const [downPaymentPlaceholder, setDownPaymentPlaceholder] = useState(null);
  const [interestRate, setInterestRate] = useState(350);
  const [loanPeriod, setLoanPeriod] = useState(7);

  const [monthlypayment, setMonthlyPayment] = useState(0);

  function handlePriceChange(e, setPrice, setPricePlaceholder) {
    const value = removeNonNumeric(e.target.value);
    setPrice(value);
    setPricePlaceholder(addCommas(value));
  }

  function handleCarPriceChange(e) {
    handlePriceChange(e, setCarPrice, setCarPricePlaceholder);
  }

  function handleDownPaymentChange(e) {
    handlePriceChange(e, setDownPayment, setDownPaymentPlaceholder);
  }

  const CalculateLoan = () => {
    let a = carPrice;
    let b = downPayment;
    let c = interestRate / 10000;
    let d = loanPeriod;

    let loanAmount = a - b;
    let totalInterestRate = c * loanAmount * d;
    let monthlyInterest = totalInterestRate / (d * 12);

    let MonthlyInstallment = (loanAmount + totalInterestRate) / (d * 12);

    setMonthlyPayment(MonthlyInstallment.toFixed(2));
  };

  let SampleInterest = interestRate / 100;

  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Car Loan Calculator</h3>
          <p>
            Calculate how much you will have to spend on your monthly loan
            repayment.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Total Amount</h4>

            <input
              value={carPricePlaceholder}
              onChange={handleCarPriceChange}
              placeholder="MYR 100,000"
            ></input>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Down Payment</h4>

            <input
              value={downPaymentPlaceholder}
              onChange={handleDownPaymentChange}
              placeholder="MYR 10,000"
            ></input>
          </div>

          <div className="fin-tab-pane-3-input">
            <h4>Interest Rate (%)</h4>

            <div className="fin-tab-pane-3-range">
              <span>{SampleInterest}</span>
              <Form.Range
                min="0"
                max="2000"
                onChange={(e) => setInterestRate(e.target.value)}
              />
            </div>
            {/* <input type="number" onChange={e => setInterestRate(e.target.value)} placeholder="3.5"></input> */}
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Loan Period (Years)</h4>

            <input
              type="number"
              onChange={(e) => setLoanPeriod(e.target.value)}
              placeholder="7"
            ></input>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Monthly Payment</h4>
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {monthlypayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>

          <button
            onClick={CalculateLoan}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>
        </div>
      </div>
    </>
  );
}

export default LoanCalculator;
