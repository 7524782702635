import React, { useEffect } from "react";
import "../../App.css";
import FinanceItem from "../finance/Finance_Item";

import { Helmet } from "react-helmet";

function FinancePage() {
  useEffect(() => {
    document.title = "Finance | AutoPit";
    if (window.scrollY > 0) window.scrollTo(0, 700);
    else window.scrollBy(0, 700);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/finance" />

        <meta name="canonical" content="/finance" />
        <meta property="og:url" content="/finance" />
      </Helmet>

      <FinanceItem />
    </>
  );
}

export default FinancePage;
