import React, { useState, useEffect } from "react";
import "../../App.css";
import "./Finance.scss";
import axios from "../../api/axios";
// import { useCookies } from 'react-cookie';

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { Empty } from "antd";

function DutyListDisplay() {
  const [showListItems, setShowListItems] = useState([]);
  const [modelList, setModelList] = useState([]);

  const countries = ["Japan", "UK"];

  const [country, setCountry] = useState("");
  const [brand, setBrand] = useState([]);
  const [model, setModel] = useState([]);
  const [month, setMonth] = useState("");

  // year
  const [yearRange, setYearRange] = useState("");
  let SampleInterest = yearRange / 100;

  // getDutyListItems
  const [errorList, setErrorList] = useState(null);
  const [isLoadedList, setIsLoadedList] = useState(false);
  const getDutyListItems = () => {
    fetch(`https://api.autopitonline.com/api/v1/cars/brands`)
      .then((res) => res.json())
      .then(
        (response) => {
          setIsLoadedList(true);
          setShowListItems(response);
          // console.log('results', response.brands);
        },
        (error) => {
          setIsLoadedList(true);
          setErrorList(error);
        },
      );
  };

  // get model
  const CarModels = async (id) => {
    try {
      const response = await axios.get(
        // `filters?brand=${id}`,
        // `cars/brands?id=${id}`,
        "calculators/duty-list-filter",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            currency: parseInt(country[0]),
            brand: parseInt(id),
          },
        },
      );
      setModel([]);
      setModelList(response.data.models);
      // console.log('CarModels.model_groups', response.data.model_groups);
    } catch (error) {
      console.error("error", error);
    }
  };

  // postDutyListItems
  const [dutylistdata, setDutyListData] = useState(null);
  const postDutyListItems = async () => {
    try {
      const dutylist_res = await axios.post(
        "calculators/duty-list",
        JSON.stringify({
          brand: parseInt(brand[0]),
          model: parseInt(model[0]),
          currency: parseInt(country[0]),
          // year: year,
          month: 0, // month.toString()
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );

      setDutyListData(dutylist_res.data.duty_list);
      console.log("dutylist_res", dutylist_res.data.duty_list);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDutyListItems();
    // CarModels();
  }, []);

  useEffect(() => {
    console.log("country", country);
  }, [country]);

  return (
    <>
      {errorList ? (
        // <div>Error: {error.message}</div>
        <>
          {/* {console.log('error',error.message)} */}
          <div className="prod-content">
            <Alert variant="danger">
              <Alert.Heading className="ErrorRequest_Head">
                Too many requests!{" "}
              </Alert.Heading>
              <hr />
              <p>Please try again later. </p>
            </Alert>
          </div>
        </>
      ) : !isLoadedList ? (
        // <div>Loading...</div>
        <div className="LoadingSpinner">
          <Spinner animation="border" role="status" variant="info">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="fin-tab-pane-2">
          <div className="fin-tab-pane-2-header">
            <h3>Duty List Display</h3>
          </div>

          <div className="fin-tab-pane-2-content">
            <div className="fin-tab-pane-2-input dutylist-input">
              <DropdownButton
                className="fin-cal-option dutylist"
                id="dropdown-basic-button uncontrolled-tab-example"
                title={country[1] ? country[1] : "Country"}
              >
                <Dropdown.Item style={{ color: "grey" }} disabled>
                  Country
                </Dropdown.Item>
                {countries.map((item, i) => (
                  <Dropdown.Item
                    onClick={() => {
                      setCountry([i + 1, item]);
                      // reset brand and model
                      setBrand([]);
                      setModel([]);
                    }}
                    key={i}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="fin-tab-pane-2-input dutylist-input">
              <DropdownButton
                className="fin-cal-option dutylist"
                id="dropdown-basic-button uncontrolled-tab-example"
                title={brand[1] ? brand[1] : "Brands"}
              >
                <Dropdown.Item style={{ color: "grey" }} disabled>
                  Brands
                </Dropdown.Item>
                {showListItems.brands.map((item, i) => (
                  <Dropdown.Item
                    onClick={() => {
                      setBrand([item.id, item.title]);
                      CarModels(item.id);
                    }}
                    key={i}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>

            <div className="fin-tab-pane-2-input dutylist-input">
              <DropdownButton
                className="fin-cal-option dutylist"
                id="dropdown-basic-button uncontrolled-tab-example"
                title={model[1] ? model[1] : "Models"}
              >
                <Dropdown.Item style={{ color: "grey" }} disabled>
                  Models
                </Dropdown.Item>
                {modelList?.length === 0 ? (
                  <>
                    <Dropdown.Item style={{ color: "grey" }} disabled>
                      Pease select a brand
                    </Dropdown.Item>
                  </>
                ) : (
                  modelList?.map((item, i) => (
                    <>
                      <Dropdown.Item
                        onClick={() => setModel([item.id, item.title])}
                        key={i}
                      >
                        {item.title}
                      </Dropdown.Item>
                    </>
                  ))
                )}
              </DropdownButton>
            </div>

            {/* <div className="fin-tab-pane-4-input">
                        <p><span>{month}</span> Months</p> 
                        <div className="fin-tab-pane-4-range">
                            <Form.Range step="12"
                                min="12"
                                max={`120`}
                                onChange={e => setMonth(e.target.value)}
                            />
                        </div>
                    </div> */}

            {brand.length === 0 || model.length === 0 ? (
              <div className="fin-tab-pane-calculate-btn disabled">Search</div>
            ) : (
              <button
                className="fin-tab-pane-calculate-btn"
                onClick={postDutyListItems}
              >
                Search
              </button>
            )}
          </div>

          <div className="fin-tab-duty-table">
            {dutylistdata === null ? (
              <Table className="fin-item-dutylist-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    {/* <th className="fin-item-dutylist-border">Year</th> */}
                    <th className="fin-item-dutylist-border">Months</th>
                    <th>Duty (SST)</th>
                  </tr>
                </thead>
                <tbody>
                  <td colSpan={3}>
                    <Empty
                      imageStyle={{
                        height: 90,
                      }}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>No duty</span>}
                    />
                  </td>
                </tbody>
              </Table>
            ) : (
              <Table className="fin-item-dutylist-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    {/* <th className="fin-item-dutylist-border">Year</th> */}
                    <th className="fin-item-dutylist-border">Months</th>
                    <th>Duty (SST)</th>
                  </tr>
                </thead>
                <tbody>
                  {dutylistdata.length != 0 ? (
                    dutylistdata.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        {/* <td className="fin-item-dutylist-border">{item.year}</td> */}
                        <td className="fin-item-dutylist-border">
                          {item.month > 60
                            ? "More than 60"
                            : item.month === "24"
                            ? "12-23"
                            : item.month === "36"
                            ? "24-35"
                            : item.month === "48"
                            ? "36-47"
                            : item.month === "60"
                            ? "48-59"
                            : item.month}
                        </td>
                        <td>
                          MYR{" "}
                          {item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={3}>
                      <Empty
                        imageStyle={{
                          height: 90,
                        }}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>No duty found</span>}
                      />
                    </td>
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DutyListDisplay;
