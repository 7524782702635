import React from "react";
import "./Policies.scss";

function PrivacyPolicy() {
  document.title = "Privacy Policy | AutoPit";

  return (
    <>
      <div className="wrap-policies">
        <div className="policies-block"></div>

        <div className="policies-header">
          <h1>Privacy Policy</h1>
        </div>

        <div className="policies-container-1">
          <p>
            This policy sets out how Autopitonline.com collect, use, disclose
            and manage your personal information. Other group companies of
            Autopit have their own privacy policies which can be found on their
            websites.
          </p>

          <p>
            Personal information is information or an opinion about an
            identified individual, or an individual who is reasonably
            identifiable.
          </p>

          <p>
            By accessing or using the network of websites, apps and related
            services operated by Autopit you agree to the collection, use and
            disclosure of your personal information in the manner described in
            this Privacy Policy.
            <ul>
              <li>
                the Autopit vehicle search engine, including where hosted by
                Autopit on manufacturer or dealer websites; and
              </li>
              <li>the Autopit e-commerce stores;</li>
            </ul>
            (together the “Autopit”).
          </p>

          <p>
            If, through using a Autopit website, app or our services you
            disclose personal information to a third party (including via their
            website), this Privacy Policy will not apply as between you and the
            third party and you will be subject to the privacy policy of that
            third party. Autopit is not responsible for third party privacy
            policies and recommends that you review them carefully before
            submitting personal information.
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
