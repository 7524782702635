import React, { useEffect } from "react";
import "../../App.css";
import "./Import.css";

// main
function ImportCarJapan() {
  const stock_direct = require("../../images/BG 51.png");
  const bidding = require("../../images/BG 50.png");
  const past_auction = require("../../images/BG 52.png");
  const right_arrow = require("../../images/right-arrow-01.png");

  return (
    <>
      <div className="wrap-fin-hero">
        <div className="fin-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-fin">
        <div className="fin-container-1">
          <div className="fin-header">
            <h2>CAR IMPORT</h2>
          </div>
          <div className="flex gap-x-4 justify-between flex-nowrap mobile-flex-wrap">
            <div className="fin-con-1-nav-item w-full">
              <a
                href="https://jpauc.com/oneprice/location"
                target="_blank"
                style={{ textDecoration: "none" }}
                className="pills_tab"
                eventKey="first"
              >
                <img
                  src={stock_direct}
                  alt="Stock Direct Purchase"
                  className=""
                  width={120}
                  height={120}
                />
                <h4 className="import-tab-p">Stock Direct Purchase</h4>
              </a>
            </div>

            <div className="fin-con-1-nav-item w-full">
              <a
                href="https://www.goo-net-exchange.com/usedcars/"
                target="_blank"
                style={{ textDecoration: "none" }}
                className="pills_tab"
                eventKey="second"
              >
                <img
                  src={bidding}
                  alt="Bidding Auction"
                  className=""
                  width={120}
                  height={120}
                />
                <h4 className="import-tab-p">Bidding Auction</h4>
              </a>
            </div>

            <div className="fin-con-1-nav-item w-full">
              <a
                href="#"
                className="pills_tab"
                style={{ textDecoration: "none" }}
                eventKey="third"
              >
                <img
                  src={past_auction}
                  alt="Past Auction"
                  className=""
                  width={120}
                  height={120}
                />
                <h4 className="import-tab-p">Past Auction (Coming Soon)</h4>
              </a>
            </div>
          </div>
          <h2 className="import_h2 mt-5">
            How to get <span>lowest price</span> car from Japan ?
          </h2>
          <div className="import_container">
            <p className="import_container_title">
              <a
                href="https://www.goo-net-exchange.com/usedcars/"
                className="import_link"
                target="_blank"
              >
                Goo-net Stock Direct Purchase
              </a>{" "}
              <span>- 368,000 units daily</span>
            </p>
            <p className="import_container_content">
              (Prices are slightly higher, but a lot of limited edition cars can
              be found here)
            </p>
          </div>
          <div className="import_container">
            <p className="import_container_title">
              <a
                href="https://jpauc.com/oneprice/location"
                className="import_link"
                target="_blank"
              >
                One prices Stock Direct Purchase
              </a>
              <span> - 87,196 units daily</span>
            </p>
            <p className="import_container_content">
              (Prices are average, but some of the car prices can be lower than
              Bidding Auction)
            </p>
          </div>
          <div className="import_container">
            <p className="import_container_title">
              Bidding Auction <span>- 36,000 units daily</span>
            </p>
            <p className="import_container_content">
              (Most of the prices are lower, but not all models are available)
            </p>
            <p className="import_container_content mt-4">
              - Refer{" "}
              <a
                href="https://jpauc.com/oneprice/location"
                className="import_link"
                target="_blank"
              >
                One Price
              </a>{" "}
              and
              <a
                href="https://www.goo-net-exchange.com/usedcars/"
                className="import_link"
                target="_blank"
              >
                {" "}
                Goo-net
              </a>{" "}
              to get current market selling prices. Sign up our{" "}
              <b>Calculator</b> to get a full transparency breakdown of cost &
              fees with On the Road Price
            </p>
            <p className="import_container_content">
              - Secondly go in <b>Bidding Auction</b> to refer 5 simple steps to
              start your bidding
            </p>
            <p className="import_container_content">
              - PM us your desired price in <b>YEN</b> or <b>RM</b> and ask us
              to place bid for you in <b>Bidding Auction</b>
            </p>

            <a
              href="/import-calculator"
              className="flex items-center gap-x-2 go_link_btn"
            >
              <span>Go to Import Calculator</span>
              <img src={right_arrow} alt="right arrow" width={20} height={20} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportCarJapan;
