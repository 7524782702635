import React, { useState, useEffect } from "react";
import "../../App.css";
import "./Cars.scss";
import "./SingleCarDetails.scss";
import axios from "../../api/axios";
// import styled from 'styled-components';
import { useCookies } from "react-cookie";
import { useLocation, Link } from "react-router-dom";
import FilterComponent from "./Filter_Component";
import Prompt_Modal from "../navigation/Modals/PromptLogin_Modal";

// import PieChart from 'react-pie-graph-chart';
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Table from "react-bootstrap/Table";
import Carousel from "react-bootstrap/Carousel";
import { message, InputNumber } from "antd";
import { GrLocation } from "react-icons/gr";

const CarAttribute = (props) => {
  return (
    <>
      <div className="sgcars-attri_item">
        <img src={props.attri_imgsrc} alt={props.attri_imgalttext} />

        <p>{props.attri_desc}</p>
        <h5>{props.attri_type}</h5>
      </div>
    </>
  );
};

// main
const SingleCarDetails = () => {
  {
    /* FOR LOGIN and etc */
  }
  // login modal
  const [loginModal, setLoginModal] = useState(false);
  const handleCloseLoginModal = () => setLoginModal(false);
  const handleLoginModal = () => {
    setLoginModal(true);
    setRegisterModal(false);
    setForgotPassModal(false);
  };
  // register modal
  const [registerModal, setRegisterModal] = useState(false);
  const handleCloseRegisterModal = () => setRegisterModal(false);
  const handleRegisterModal = () => {
    setRegisterModal(true);
    setLoginModal(false);
    setForgotPassModal(false);
  };
  // forgotpassword
  const [forgotpassModal, setForgotPassModal] = useState(false);
  const handleClosePasswordModal = () => setForgotPassModal(false);
  const handlePasswordModal = () => {
    setForgotPassModal(true);
    setLoginModal(false);
    setRegisterModal(false);
  };
  // resetpassword modal
  const [showReset, setShowReset] = useState(false);
  const handleCloseReset = () => setShowReset(false);

  //antd message
  const [messageApi, contextHolder] = message.useMessage();

  // images
  const attri_1 = require("../../images/BG-29.png");
  const attri_2 = require("../../images/BG-30.png");
  const attri_3 = require("../../images/BG-31.png");
  const attri_4 = require("../../images/BG-32.png");
  const attri_5 = require("../../images/BG-33.png");
  const attri_6 = require("../../images/BG-34.png");
  const attri_7 = require("../../images/BG-35.png");

  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const location = useLocation();
  const clean_location = location.pathname.replace(/&/g, " ").split("/")[2];
  // console.log("clean_location", clean_location);

  const base_url = `https://api.autopitonline.com/api/v1${location.pathname}`;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [carData, setCarData] = useState([]);
  const [ins_engine, setInsEngine] = useState(0);

  useEffect(() => {
    fetch(`https://api.autopitonline.com/api/v1/cars/${clean_location}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setCarData(result);

          if (result?.data.product_attribute?.engine_capacity <= 1400) {
            setInsEngine(1);
          } else if (
            result?.data.product_attribute?.engine_capacity > 1400 &&
            result?.data.product_attribute?.engine_capacity <= 1650
          ) {
            setInsEngine(2);
          } else if (
            result?.data.product_attribute?.engine_capacity > 1650 &&
            result?.data.product_attribute?.engine_capacity <= 2200
          ) {
            setInsEngine(3);
          } else if (
            result?.data.product_attribute?.engine_capacity > 2200 &&
            result?.data.product_attribute?.engine_capacity <= 3050
          ) {
            setInsEngine(4);
          } else if (
            result?.data.product_attribute?.engine_capacity > 3050 &&
            result?.data.product_attribute?.engine_capacity <= 4100
          ) {
            setInsEngine(5);
          } else if (
            result?.data.product_attribute?.engine_capacity > 4100 &&
            result?.data.product_attribute?.engine_capacity <= 4250
          ) {
            setInsEngine(6);
          } else if (
            result?.data.product_attribute?.engine_capacity > 4250 &&
            result?.data.product_attribute?.engine_capacity <= 4400
          ) {
            setInsEngine(7);
          } else {
            setInsEngine(8);
          }
          postDutyList(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const [dutylistTable, setDutyListTable] = useState([]);
  const getCarDetails = async () => {
    try {
      const result = await axios.get(`cars/${clean_location}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // console.log('result', result.data.data?.product_attribute?.brand_model_group.brand_id);
      const dutyMonth =
        (Number(new Date().getFullYear()) -
          Number(result.data.data?.product_attribute.manufacture_year)) *
        12;
      // try {
      //     const response = await axios.post(
      //         'calculators/duty-list',
      //         {
      //             brand: result.data.data?.product_attribute?.brand_model_group.brand_id,
      //             model: result.data.data?.product_attribute?.brand_model_group.model_group_id,
      //             // month: ((Number(new Date().getFullYear()) - Number(result.data.data?.product_attribute.manufacture_year)) * 12).toString(),
      //             // month: ( dutyMonth > 60 ? "61" : dutyMonth ).toString(),
      //             month: "0"
      //         } ,
      //         {
      //             headers: {
      //                 'Access-Control-Allow-Origin': '*',
      //                 'Content-Type': 'application/json',
      //                 "Accept": "application/json",
      //             },
      //         }
      //     );
      //     setDutyListTable(response.data.duty_list);
      //     // console.log('dutylistTable', response.data);

      // } catch ( error ) {
      //     console.error('error', error);
      // }
    } catch (error) {
      console.error("error", error);
    }
  };

  let carDetails = carData.data;
  // console.log("carData", carData.data);

  // payment modal
  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);
  const [warning, setWarning] = useState(false);

  // calculator
  // 1
  // GetDutyList
  const [dutylistData, setDutyListData] = useState([]);
  const [dutylistPricing, setDutyListPricing] = useState(0.01);
  const postDutyList = async (duty) => {
    const dutyMonth =
      (Number(new Date().getFullYear()) -
        Number(duty.data?.product_attribute.manufacture_year)) *
      12;
    try {
      const dutylist_res = await axios.post(
        "calculators/duty-list",
        {
          brand: duty.data?.product_attribute?.brand_model_group.brand_id, // car brand
          model: duty.data?.product_attribute?.brand_model_group.id, // model group
          // year: duty.data?.product_attribute.manufacture_year,
          // month: ((Number(new Date().getFullYear()) - Number(duty.data?.product_attribute.manufacture_year)) * 12).toString(),
          // month: ( dutyMonth > 60 ? "61" : dutyMonth ).toString(),
          month: "0",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (dutylist_res?.data?.duty_list.length == 0) {
        setDutyListPricing(0.01);
      } else {
        setDutyListPricing(dutylist_res?.data?.duty_list[0]?.price);
      }
      setDutyListData(dutylist_res);
      // console.log("dutylist_res", dutylist_res);
    } catch (error) {
      console.error("error", error);
    }
  };

  // getRoadTax
  const [rtlocation, setRTLocation] = useState([
    "Peninsular Malaysia",
    "West",
    1,
  ]);
  const [ownerType, setOwnerType] = useState([
    "Saloon Cars / Individual Owned",
    1,
  ]);
  const [yearlyRoadtax, setYearlyRoadtax] = useState(0);
  const CalculateRoadTax = async () => {
    try {
      const response = await axios.post(
        "calculators/road-tax",
        {
          cc: carData?.data.product_attribute?.engine_capacity,
          region: rtlocation[2],
          type: ownerType[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setYearlyRoadtax(response.data.data?.yearly_payment);

      // console.log('setYearlyRoadtax', response.data.data?.yearly_payment);
    } catch (error) {
      console.error("error", error);
    }
  };

  // getInsurance
  // const [ins_engine, setInsEngine] = useState(0);
  const [ins_coverageType, setInsCoverageType] = useState(["Comprehensive", 1]);
  const [ins_location, setInsLocation] = useState([
    "Peninsular Malaysia",
    "West",
    1,
  ]);
  const [ins_claimsdiscount, setInsClaimsDiscount] = useState(["None - 0%", 1]);
  const [ins_yearlypayment, setInsYearlyPayment] = useState(0);

  const CalculateInsurance = async () => {
    try {
      const response = await axios.post(
        "calculators/insurance",
        {
          cc: ins_engine,
          region: ins_location[2],
          type: ins_coverageType[1],
          market_price: carData?.data?.product_attribute?.price,
          no_claims_discount: ins_claimsdiscount[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setInsYearlyPayment(response.data.data?.yearly_payment);
    } catch (error) {
      console.error("error", error);
    }
  };

  // 2
  // const [yearlyRoadtax, setYearlyRoadtax] = useState(0);
  // const [yearlyInsurance, setYearlyInsurance] = useState(0);
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(3.5);
  const [tenure, setTenure] = useState(7);
  const [checkoutData, setCheckoutData] = useState(0);
  const [interestPie, setInterestPie] = useState(0);

  const onChange_LoanInput = (loan_value) => {
    // console.log('loanAmount changed', loan_value);
    setLoanAmount(loan_value);
  };
  const onChange_interestRate = (interest_value) => {
    // console.log('loanAmount changed', interest_value);
    setInterestRate(interest_value);
  };
  const onChange_tenure = (tenure_value) => {
    // console.log('loanAmount changed', tenure_value);
    setTenure(tenure_value);
  };

  // Calculate Checkout
  const CalculateCheckout = async () => {
    // GetInsEngineCapacity();
    // CalculateRoadTax();
    const dutyMonth =
      (Number(new Date().getFullYear()) -
        Number(carData.data?.product_attribute.manufacture_year)) *
      12;

    try {
      const dutylist_res = await axios.post(
        "calculators/duty-list",
        {
          brand: carData.data?.product_attribute.brand_model_group.brand_id, // car brand
          model: carData.data?.product_attribute.brand_model_group.id, // model group
          // year: carData.data?.product_attribute.manufacture_year,
          // month: ((Number(new Date().getFullYear()) - Number(carData.data?.product_attribute.manufacture_year)) * 12) ,
          // month: ( dutyMonth > 60 ? "61" : dutyMonth ).toString(),
          month: "0",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (dutylist_res?.data?.duty_list.length == 0) {
        setDutyListPricing(0.01);
      } else {
        setDutyListPricing(dutylist_res?.data?.duty_list[0]?.price);
      }
      setDutyListData(dutylist_res);

      const roadtax_res = await axios.post(
        "calculators/road-tax",
        {
          cc: carData?.data.product_attribute?.engine_capacity,
          region: rtlocation[2],
          type: ownerType[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setYearlyRoadtax(roadtax_res.data.data?.yearly_payment);

      const insurance_res = await axios.post(
        "calculators/insurance",
        {
          cc: ins_engine,
          region: ins_location[2],
          type: ins_coverageType[1],
          market_price: carData?.data?.product_attribute?.price,
          no_claims_discount: ins_claimsdiscount[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setInsYearlyPayment(insurance_res.data.data?.yearly_payment);

      const response = await axios.post(
        "orders/checkout",
        {
          car_id: carData?.data?.id,
          loan_amount: carData?.data?.product_attribute?.price, // (0.9 * carData?.data?.product_attribute?.price),
          interest_rate: carData?.data?.interest_rate,
          tenure: tenure,
          duty_amount: dutylistPricing, // dutylist_res?.data?.duty_list[0]?.price,
          insurance_amount: ins_yearlypayment, // insurance_res?.data?.data?.yearly_payment,
          roadtax_amount:
            carData?.data?.road_tax === null ||
            carData?.data?.road_tax === "" ||
            carData?.data?.road_tax === "0.00"
              ? yearlyRoadtax
              : parseFloat(carData.data.road_tax), // roadtax_res?.data?.data?.yearly_payment,
        },
        {
          headers: {
            Authorization: "Bearer " + cookies.accessToken,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setCheckoutData(response.data?.data);

      // console.log('setCheckoutData', response.data?.data);

      setShowPayment(true);
      setWarning(false);
    } catch (error) {
      console.error("error", error);
      setWarning(true);
    }
  };

  useEffect(() => {
    getCarDetails();
    // getDutyListTable();
    // CalculateRoadTax();
    // CalculateInsurance();
    // GetInsEngineCapacity();
  }, []);

  // redirect to makepayment page
  const nextpageURL = `/payment/${carData.data?.url_slug}?checkout=${checkoutData?.checkout_id}`;
  const nextpageData = {
    CheckoutID: checkoutData?.checkout_id,
    GrandTotal: checkoutData?.grandTotal,
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    <div className="LoadingSpinner">
      <Spinner animation="border" role="status" variant="info">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  } else {
    document.title = carDetails?.title + " | AutoPit";
  }

  return (
    <>
      <div className="wrap-sgcars-hero">
        <div className="cars-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-cars">
        <div className="sgcars-header">
          {error ? (
            <> {console.error("error", error.message)} </>
          ) : !isLoaded ? (
            <></>
          ) : (
            <>
              <div className="sgcars-breadcrumbs">
                {cookies?.accessToken ? (
                  <a href="/cars" className="sgcars-breadcrumbs-item">
                    Buy Car{" "}
                  </a>
                ) : (
                  <a href="/cars" className="sgcars-breadcrumbs-item">
                    Cars{" "}
                  </a>
                )}
                <span className="sgcars-breadcrumbs-divider">/</span>
                <a
                  href={
                    "/cars?brand=" +
                    carDetails?.product_attribute?.brand_model_group.brand.id
                  }
                  className="sgcars-breadcrumbs-item"
                >
                  {carDetails.product_attribute.brand_model_group.brand.title}{" "}
                </a>
                <span className="sgcars-breadcrumbs-divider">/</span>
                <h2 className="sgcars-breadcrumbs-item">{carDetails.title} </h2>
              </div>
            </>
          )}
        </div>

        <div className="cars-container-1">
          <div className="cars-content-1">
            <FilterComponent
              item_SearchPlaceholder="Search your dream cars"
              // item_SetSearchCars={setSearchCars}
            />
          </div>

          <div className="sgcars-container-1">
            {error ? (
              // <div>Error: {error.message}</div>
              <>
                {/* {console.log('error',error.message)} */}
                <div className="prod-content">
                  <Alert variant="danger">
                    <Alert.Heading className="ErrorRequest_Head">
                      Too many requests!{" "}
                    </Alert.Heading>
                    <hr />
                    <p>Please try again later. </p>
                  </Alert>
                </div>
              </>
            ) : !isLoaded ? (
              // <div>Loading...</div>
              <div className="LoadingSpinner">
                <Spinner animation="border" role="status" variant="info">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="sgcars-item-container">
                {contextHolder}
                {/* <figure className="sgcars-item-banner">
                                    <img src={carDetails.thumbnail} alt={carDetails.title + " | AutoPit"} />
                                </figure> */}
                {carDetails.product_images?.length > 1 ? (
                  <Carousel className="sgcars-item-carousel" interval={null}>
                    {carDetails.product_images.map((item, i) => (
                      <Carousel.Item key={i}>
                        <img
                          className="sgcars-item-carousel-img"
                          src={item.path}
                          alt={carDetails.title + " | AutoPit"}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <figure className="sgcars-item-banner">
                    <img
                      src={carDetails.thumbnail}
                      alt={carDetails.title + " | AutoPit"}
                    />
                  </figure>
                )}

                <div className="sgcars-item-header">
                  <p>
                    {carDetails.product_attribute.brand_model_group.brand.title}
                  </p>
                  <h4>{carDetails.title}</h4>

                  <div className="cars-card-item-country">
                    <GrLocation className="cars-card-item-country-icon" />
                    {carDetails.product_attribute.country.iso_alpha2_code},{" "}
                    {carDetails.product_attribute.country.country_name}
                  </div>
                </div>

                <div className="sgcars-item-attribute">
                  <CarAttribute
                    attri_imgsrc={attri_1}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.condition.title}
                    attri_type="Condition"
                  />
                  <CarAttribute
                    attri_imgsrc={attri_2}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.manufacture_year}
                    attri_type="Manufacture Year"
                  />
                  <CarAttribute
                    attri_imgsrc={attri_3}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.mileage}
                    attri_type="Mileage"
                  />
                  <CarAttribute
                    attri_imgsrc={attri_4}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.colour.title}
                    attri_type="Colour"
                  />
                  <CarAttribute
                    attri_imgsrc={attri_5}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.engine_capacity}
                    attri_type="Engine Capacity"
                  />
                  <CarAttribute
                    attri_imgsrc={attri_6}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.transmission.title}
                    attri_type="Transmission"
                  />
                  <CarAttribute
                    attri_imgsrc={attri_7}
                    attri_imgalttext={carDetails.title + " | AutoPit"}
                    attri_desc={carDetails.product_attribute.seats}
                    attri_type="Car Seats"
                  />
                </div>

                <Dropdown.Divider className="sgcars-item-div" />
                {dutylistTable?.length === 0 ? (
                  <></>
                ) : (
                  <div className="sgcars-item-dutylist">
                    <h5>Duty List</h5>

                    <Table className="sgcars-item-dutylist-table">
                      <thead>
                        <tr>
                          <th>CC</th>
                          <th className="sgcars-item-dutylist-border">Year</th>
                          <th className="sgcars-item-dutylist-border">
                            Months
                          </th>
                          <th>Duty (SST)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dutylistTable?.duty_list?.map((item, i) => (
                          <tr key={i}>
                            <td
                              style={
                                i === 0
                                  ? { visibility: "visible" }
                                  : { visibility: "hidden" }
                              }
                            >
                              {carDetails.product_attribute.engine_capacity}
                            </td>
                            {/* <td className="sgcars-item-dutylist-border">{item.year}</td> */}
                            <td className="sgcars-item-dutylist-border">
                              {item.month}
                            </td>
                            <td>
                              MYR{" "}
                              {item.price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}

                <div className="sgcars-item-description">
                  <h5>Description</h5>
                  <p>{carDetails.short_description}</p>
                </div>

                <Dropdown.Divider className="sgcars-item-div" />
                {/* <div className="sgcars-item-description">
                                    <h5>Full breakdowns</h5>
                                </div> */}

                <div className="sgcars-loan-calculator">
                  <div className="sgcars-loan-sec-1">
                    <div className="sgcars-loan-item-1">
                      <p>Car Loan Amount</p>
                      <div className="sgcars-loan-item-1-1">
                        MYR{" "}
                        <span>{carData?.data?.product_attribute?.price}</span>
                        {/* <InputNumber min={10000} max={5000000} placeholder="100,000" onChange={onChange_LoanInput}  /> */}
                      </div>
                    </div>

                    <div className="sgcars-loan-item-1 mob-sgcars-loan-item-2">
                      <p>Interest Rate P.A.</p>
                      <div className="sgcars-loan-item-1-2">
                        {/* <InputNumber min={1.0} max={20.0} step="0.01" onChange={onChange_interestRate} placeholder="3.5" /> */}
                        <span>{carData?.data?.interest_rate}</span> %
                      </div>
                    </div>

                    <div className="sgcars-loan-item-1 mob-sgcars-loan-item-2">
                      <p>Tenure</p>
                      <div className="sgcars-loan-item-1-2">
                        <InputNumber
                          min={1}
                          max={9}
                          onChange={onChange_tenure}
                          placeholder="7"
                        />
                        Year(s)
                      </div>
                    </div>
                  </div>

                  <div className="sgcars-loan-sec-2">
                    <Alert
                      show={warning}
                      variant="primary"
                      onClose={() => setWarning(false)}
                      dismissible
                    >
                      <p>Please ensure all breakdowns are correct</p>
                    </Alert>
                    <h4>Full breakdown</h4>
                    <Accordion className="sgcars-accord-etc" alwaysOpen>
                      <Accordion.Item
                        className="sgcars-accord-item"
                        eventKey="0"
                      >
                        <Accordion.Header>Insurance</Accordion.Header>
                        <Accordion.Body>
                          {/* <p>Yearly Insurance: <span>MYR {carDetails.road_tax}</span>  </p> */}
                          <div className="fin-tab-pane-2">
                            <div className="fin-tab-pane-2-content">
                              <div className="fin-tab-pane-2-input">
                                <h4>Coverage Type</h4>
                                <DropdownButton
                                  className="fin-cal-option"
                                  id="dropdown-basic-button uncontrolled-tab-example"
                                  title={
                                    ins_coverageType[0]
                                      ? ins_coverageType[0]
                                      : "Comprehensive "
                                  }
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      setInsCoverageType(["Comprehensive", 1])
                                    }
                                  >
                                    Comprehensive
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item onClick={() => setCoverageType( ["Third Party", 2] ) }>Third Party</Dropdown.Item> */}
                                </DropdownButton>
                              </div>
                              <div className="fin-tab-pane-2-input">
                                <h4>Location</h4>
                                <DropdownButton
                                  className="fin-cal-option"
                                  id="dropdown-basic-button uncontrolled-tab-example"
                                  title={
                                    ins_location[0]
                                      ? ins_location[0]
                                      : "Peninsular Malaysia "
                                  }
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      setInsLocation([
                                        "Peninsular Malaysia",
                                        "West",
                                        1,
                                      ])
                                    }
                                  >
                                    Peninsular Malaysia
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setInsLocation([
                                        "Sabah / Sarawak / Labuan",
                                        "East",
                                        2,
                                      ])
                                    }
                                  >
                                    Sabah / Sarawak / Labuan
                                  </Dropdown.Item>
                                </DropdownButton>
                              </div>
                              <div className="fin-tab-pane-2-input">
                                <h4>Yearly Insurance Payment</h4>
                                <div className="fin-tab-pane-payment">
                                  MYR{" "}
                                  {ins_yearlypayment
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                              </div>

                              <button
                                onClick={CalculateInsurance}
                                className="fin-tab-pane-calculate-btn"
                              >
                                Calculate Insurance
                              </button>
                            </div>
                            {/* <p className="disclaimer">* The premium quote provided by this Car Insurance Calculator is just an estimated premium amount, and the actual premium might differ from the amount shown here depending on the insurance provider and other factors.</p> */}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        className="sgcars-accord-item"
                        eventKey="1"
                      >
                        <Accordion.Header>Road Tax</Accordion.Header>
                        <Accordion.Body className="sgcars-accord-roadtax">
                          <div className="fin-tab-pane-2">
                            <div className="fin-tab-pane-2-content">
                              <div className="fin-tab-pane-2-input">
                                <h4>Region</h4>

                                <DropdownButton
                                  className="fin-cal-option"
                                  id="dropdown-basic-button uncontrolled-tab-example"
                                  title={
                                    rtlocation[0]
                                      ? rtlocation[0]
                                      : "Peninsular Malaysia "
                                  }
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      setRTLocation([
                                        "Peninsular Malaysia",
                                        "West",
                                        1,
                                      ])
                                    }
                                  >
                                    Peninsular Malaysia
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setRTLocation([
                                        "Sabah / Sarawak",
                                        "East",
                                        2,
                                      ])
                                    }
                                  >
                                    Sabah / Sarawak{" "}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setRTLocation([
                                        "Pulau Pangkor & Langkawi",
                                        "Langkawi",
                                        3,
                                      ])
                                    }
                                  >
                                    Pulau Pangkor & Langkawi
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setRTLocation(["Labuan", "Labuan", 4])
                                    }
                                  >
                                    Labuan
                                  </Dropdown.Item>
                                </DropdownButton>
                              </div>

                              <div className="fin-tab-pane-2-input">
                                <h4>Car / Ownership Type</h4>

                                <DropdownButton
                                  className="fin-cal-option"
                                  id="dropdown-basic-button uncontrolled-tab-example"
                                  title={
                                    ownerType[0]
                                      ? ownerType[0]
                                      : "Saloon Cars / Individual Owned "
                                  }
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      setOwnerType([
                                        "Saloon Cars / Individual Owned",
                                        1,
                                      ])
                                    }
                                  >
                                    Saloon Cars / Individual Owned
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setOwnerType([
                                        "Saloon Cars / Company Owned",
                                        2,
                                      ])
                                    }
                                  >
                                    Saloon Cars / Company Owned{" "}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setOwnerType([
                                        "Non-Saloon Cars / Individual Or Company Owned",
                                        3,
                                      ])
                                    }
                                  >
                                    Non-Saloon Cars / Individual Or Company
                                    Owned
                                  </Dropdown.Item>
                                </DropdownButton>

                                <div className="fin-tab-pane-2-saloon-type">
                                  <div className="fin-tab-pane-2-saloon-type-1">
                                    <h5>Saloon:</h5>
                                    <p>
                                      Sedan / Hatchback / Coupe / Wagon /
                                      Converible
                                    </p>
                                  </div>
                                  <div className="fin-tab-pane-2-saloon-type-1">
                                    <h5>Non-Saloon:</h5>
                                    <p>MPV / SUV / Pick-up / Commercial</p>
                                  </div>
                                </div>
                              </div>

                              <div className="fin-tab-pane-2-input">
                                <h4>Yearly RoadTax Repayment</h4>
                                {/* <div className="fin-tab-pane-payment">MYR {yearlyRoadtax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> */}
                                <div className="fin-tab-pane-payment">
                                  {carData?.data?.road_tax === null ||
                                  carData?.data?.road_tax === "" ||
                                  carData?.data?.road_tax === "0.00"
                                    ? `MYR ${yearlyRoadtax
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                    : `MYR ${Number(carData?.data?.road_tax)
                                        .toFixed(2)
                                        .toString()
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}`}
                                </div>
                              </div>
                              {carData?.data?.road_tax === null ||
                              carData?.data?.road_tax === "" ||
                              carData?.data?.road_tax === "0.00" ? (
                                <button
                                  onClick={CalculateRoadTax}
                                  className="fin-tab-pane-calculate-btn"
                                >
                                  Calculate Road Tax
                                </button>
                              ) : (
                                <div className="fin-tab-pane-calculate-btn disabled">
                                  Road Tax Included
                                </div>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* <Accordion.Item className="sgcars-accord-item" eventKey="2" onClick={postDutyList}> */}
                      <Accordion.Item
                        className="sgcars-accord-item"
                        eventKey="2"
                      >
                        <Accordion.Header>Duty List</Accordion.Header>
                        <Accordion.Body>
                          <div className="fin-tab-pane-2">
                            <div className="fin-tab-pane-2-content">
                              <div className="fin-tab-pane-2-input">
                                {dutylistData?.data?.duty_list.length == 0 ? (
                                  <div className="fin-tab-pane-payment">
                                    MYR 0.00 <br />
                                    <p>Details: No duty </p>
                                  </div>
                                ) : (
                                  <div className="fin-tab-pane-payment">
                                    {/* MYR {dutylistData?.data?.duty_list[0]?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <br /> */}
                                    MYR{" "}
                                    {dutylistPricing
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    <br />
                                    {/* <p>Details: <span>{dutylistData?.data?.duty_list[0]?.year} | {dutylistData?.data?.duty_list[0]?.month} Months</span> </p> */}
                                    <p>
                                      Details:{" "}
                                      <span>
                                        {
                                          dutylistData?.data?.duty_list[0]
                                            ?.month
                                        }{" "}
                                        Months
                                      </span>{" "}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  {cookies?.accessToken ? (
                    <button
                      onClick={() => {
                        CalculateRoadTax();
                        CalculateCheckout();
                      }}
                      className="sgcars-calculate-btn"
                    >
                      Calculate
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setLoginModal(true)}
                      className="sgcars-calculate-btn"
                    >
                      Calculate
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        className="sgcars-calculate-modal"
        show={showPayment}
        onHide={handleClosePayment}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="sgcars-modalbody-2">
            <p className="sgcars-modalbody-2-1">Monthtly Installment Amount</p>
            <p className="sgcars-modalbody-2-2">
              MYR{" "}
              {checkoutData?.monthly_installment
                ?.toFixed(2)
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
          <div className="sgcars-modalbody-2">
            <p className="sgcars-modalbody-2-1">Duty List</p>
            <p className="sgcars-modalbody-2-2">
              MYR{" "}
              {dutylistPricing
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
          <div className="sgcars-modalbody-3">
            <div className="sgcars-modalbody-3-item">
              <p className="sgcars-modalbody-3-1">Yearly Roadtax Payment</p>
              <p className="sgcars-modalbody-3-2">
                {/* MYR {yearlyRoadtax?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                {carData?.data?.road_tax === null ||
                carData?.data?.road_tax === "" ||
                carData?.data?.road_tax === "0.00"
                  ? `MYR ${yearlyRoadtax
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  : `MYR ${Number(carData?.data?.road_tax)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </p>
            </div>
            <div className="sgcars-modalbody-3-item">
              <p className="sgcars-modalbody-3-1">Yearly Insurance Payment</p>
              <p className="sgcars-modalbody-3-2">
                MYR{" "}
                {ins_yearlypayment
                  ?.toFixed(2)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </div>
          </div>
          <div className="sgcars-modalbody-2">
            <p className="sgcars-modalbody-2-1">Total Interest</p>
            <p className="sgcars-modalbody-2-2">
              MYR{" "}
              {checkoutData?.total_interest
                ?.toFixed(2)
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
          <div className="sgcars-modalbody-2">
            <p className="sgcars-modalbody-2-1">Grand Total</p>
            <p className="sgcars-modalbody-2-2">
              MYR{" "}
              {checkoutData?.grandTotal
                ?.toFixed(2)
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Link
            to={`/payment/${carData.data?.url_slug}?checkout=${checkoutData?.checkout_id}`}
            state={{
              CheckoutID: checkoutData?.checkout_id,
              GrandTotal: checkoutData?.grandTotal,
            }}
          > */}
          <button
            className="sgcars-deposit-btn"
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send/?phone=60105201000&text=Hi there, I am interested to inquire more details for ${carDetails.title} (${carDetails.product_attribute.brand_model_group.model_group.title})`
              );
              console.log(carDetails.product_attribute.brand_model_group);
            }}
          >
            Contact Agent
          </button>
          {/* </Link> */}
        </Modal.Footer>
      </Modal>

      <Prompt_Modal
        showloginModal={loginModal}
        onHideLoginModal={handleCloseLoginModal}
        login_Register_Btn={handleRegisterModal}
        login_ForgotPS_Btn={handlePasswordModal}
        showregisterModal={registerModal}
        onHideregisterModal={handleCloseRegisterModal}
        register_Login_Btn={handleLoginModal}
        register_ForgotPS_Btn={handlePasswordModal}
        showforgotpassModal={forgotpassModal}
        onHideforgotpassModal={handleClosePasswordModal}
        forgot_Login_Btn={handleLoginModal}
        showResetModal={showReset}
        onHideResetModal={handleCloseReset}
        reset_Login_Btn={handleLoginModal}
      />
    </>
  );
};

export default SingleCarDetails;
