import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./Finance.scss";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import ImportResultComponent from "./ImportResultComponent";
import Prompt_Modal from "../navigation/Modals/PromptLogin_Modal";

import { message } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";

const twoDp = (num) => Math.round(num * 100) / 100;
const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeCommas = (num) => num.toString().replace(/,/g, "");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

function CarInsuranceCalculator({
  setInsuranceBool,
  yearlypayment,
  setYearlyPayment,
}) {
  const [carPrice, setCarPrice] = useState(100000);
  const [carPricePlaceholder, setCarPricePlaceholder] = useState();
  const [coverageType, setCoverageType] = useState(["Comprehensive", 1]);
  const [location, setLocation] = useState(["Peninsular Malaysia", "West", 1]);
  const [enginecapacity, setEngineCapacity] = useState(["0 - 1400 cc", 1]);
  const [claimsdiscount, setClaimsDiscount] = useState(["None - 0%", 1]);

  function handleMarketPriceChange(e) {
    const value = removeNonNumeric(e.target.value);
    setCarPrice(value);
    setCarPricePlaceholder(addCommas(value));
  }

  const CalculateInsurance = async () => {
    try {
      const response = await axios.post(
        "calculators/insurance",
        {
          cc: enginecapacity[1],
          region: location[2],
          type: coverageType[1],
          market_price: carPrice,
          no_claims_discount: claimsdiscount[1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-localization": "en",
          },
        }
      );
      setYearlyPayment(response.data.data?.yearly_payment);
      setInsuranceBool(true);
      console.log("setYearlyPayment: ", response);
      // console.log('setYearlyPayment', response.data.data?.yearly_payment);
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Car Insurance Calculator (Optional)</h3>
          <p>
            Calculate how much you will have to spend on your car insurance.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Market Price</h4>
            <input
              onChange={handleMarketPriceChange}
              value={carPricePlaceholder}
              placeholder="MYR 100,000"
            ></input>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Coverage Type</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={coverageType[0] ? coverageType[0] : "Comprehensive "}
            >
              <Dropdown.Item
                onClick={() => setCoverageType(["Comprehensive", 1])}
              >
                Comprehensive
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setCoverageType( ["Third Party", 2] ) }>Third Party</Dropdown.Item> */}
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Location</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={location[0] ? location[0] : "Peninsular Malaysia "}
            >
              <Dropdown.Item
                onClick={() => setLocation(["Peninsular Malaysia", "West", 1])}
              >
                Peninsular Malaysia
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setLocation(["Sabah / Sarawak / Labuan", "East", 2])
                }
              >
                Sabah / Sarawak / Labuan
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Engine Capacity</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={enginecapacity[0] ? enginecapacity[0] : "0 - 1400 cc"}
            >
              <Dropdown.Item
                onClick={() => setEngineCapacity(["0 - 1400 cc", 1])}
              >
                0 - 1400 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["1401 - 1650 cc", 2])}
              >
                1401 - 1650 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["1651 - 2200 cc", 3])}
              >
                1651 - 2200 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["2201 - 3050 cc", 4])}
              >
                2201 - 3050 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["3051 - 4100 cc", 5])}
              >
                3051 - 4100 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["4101 - 4250 cc", 6])}
              >
                4101 - 4250 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["4251 - 4400 cc", 7])}
              >
                4251 - 4400 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["Over 4400 cc", 8])}
              >
                Over 4400 cc
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>No Claims Discount</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={claimsdiscount[0] ? claimsdiscount[0] : "None - 0%"}
            >
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["None - 0%", 1])}
              >
                None - 0%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["First Year - 25%", 2])}
              >
                First Year - 25%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Second Year - 30%", 3])}
              >
                Second Year - 30%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Third Year - 38.33%", 4])}
              >
                Third Year - 38.33%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Fourth Year - 45%", 5])}
              >
                Fourth Year - 45%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setClaimsDiscount(["Five Years or more - 55%", 6])
                }
              >
                Five Years or more - 55%
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <button
            onClick={CalculateInsurance}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>

          <div className="fin-tab-pane-2-input">
            <h4>Yearly Insurance Payment</h4>
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {yearlypayment
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function RoadTaxCalculator({
  setRoadTaxBool,
  yearlyRoadtax,
  setYearlyRoadtax,
}) {
  const [enginecapacity, setEngineCapacity] = useState(1600);
  const [location, setLocation] = useState(["Peninsular Malaysia", "West", 1]);
  const [ownerType, setOwnerType] = useState([
    "Saloon Cars / Individual Owned",
    1,
  ]);

  const CalculateRoadTax = async () => {
    try {
      const response = await axios.post(
        "calculators/road-tax",
        {
          cc: enginecapacity,
          region: location[2],
          type: ownerType[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setYearlyRoadtax(response.data.data?.yearly_payment);
      setRoadTaxBool(true);
      console.log("response", response);
      console.log("setYearlyRoadtax", response.data.data?.yearly_payment);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Road Tax Calculator (Optional)</h3>
          <p>
            Latest JPJ formula - calculate how much your vehicle’s road tax will
            cost.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Engine Capacity (cc)</h4>
            <input
              type="number"
              onChange={(e) => setEngineCapacity(e.target.value)}
              placeholder="1600"
            ></input>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Region</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={location[0] ? location[0] : "Peninsular Malaysia "}
            >
              <Dropdown.Item
                onClick={() => setLocation(["Peninsular Malaysia", "West", 1])}
              >
                Peninsular Malaysia
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setLocation(["Sabah / Sarawak", "East", 2])}
              >
                Sabah / Sarawak{" "}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setLocation(["Pulau Pangkor & Langkawi", "Langkawi", 3])
                }
              >
                Pulau Pangkor & Langkawi
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setLocation(["Labuan", "Labuan", 4])}
              >
                Labuan
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Car / Ownership Type</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={
                ownerType[0] ? ownerType[0] : "Saloon Cars / Individual Owned "
              }
            >
              <Dropdown.Item
                onClick={() =>
                  setOwnerType(["Saloon Cars / Individual Owned", 1])
                }
              >
                Saloon Cars / Individual Owned
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setOwnerType(["Saloon Cars / Company Owned", 2])}
              >
                Saloon Cars / Company Owned{" "}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setOwnerType([
                    "Non-Saloon Cars / Individual Or Company Owned",
                    3,
                  ])
                }
              >
                Non-Saloon Cars / Individual Or Company Owned
              </Dropdown.Item>
            </DropdownButton>
            <div className="fin-tab-pane-2-saloon-type">
              <div className="fin-tab-pane-2-saloon-type-1">
                <h5>Saloon:</h5>
                <p>Sedan / Hatchback / Coupe / Wagon / Converible</p>
              </div>
              <div className="fin-tab-pane-2-saloon-type-1">
                <h5>Non-Saloon:</h5>
                <p>MPV / SUV / Pick-up / Commercial</p>
              </div>
            </div>
          </div>

          <button
            onClick={CalculateRoadTax}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>
          <div className="fin-tab-pane-2-input">
            <h4>Yearly Road Tax Payment</h4>
            {/* <div className="fin-tab-pane-payment">MYR {yearlyRoadtax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> */}
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {yearlyRoadtax
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ImportCalculator() {
  const [cookies] = useCookies(["accessToken"]);

  const [messageApi, contextHolder] = message.useMessage();

  const headers_default = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-localization": "en",
  };

  const [carPrice, setCarPrice] = useState(0);
  const [car_age, setCarAge] = useState(null);
  const [downPayment, setDownPayment] = useState(10000);
  const [interestRate, setInterestRate] = useState(350);
  const [currencyRate, setCurrencyRate] = useState(0.0325);
  const [jValue, setJValue] = useState(null);
  const [mValue, setMValue] = useState(null);
  const [checked, setChecked] = useState(false);
  const [chooseSite, setChooseSite] = useState(1);
  const [currency, setCurrency] = useState(["Japan (JPY)", 1]);
  const [brand, setBrand] = useState(["Brand", null]);
  const [model, setModel] = useState(["Model", null]);
  const [vehicleType, setVehicleType] = useState(["Vehicle Type", null]);
  const [serviceFee, setServiceFee] = useState(["Service Fee", null]);
  const [month, setMonth] = useState(["Select Month", null]);
  const [year, setYear] = useState(["Select Year", null]);
  const [show_result, setShowResult] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [insuranceBool, setInsuranceBool] = useState(false);
  const [roadTaxBool, setRoadTaxBool] = useState(false);
  const [yearlypayment, setYearlyPayment] = useState(0);
  const [yearlyRoadtax, setYearlyRoadtax] = useState(0);

  useEffect(() => {
    console.log(
      "currency",
      currency,
      "brand",
      brand,
      "model",
      model,
      "vehicleType",
      vehicleType,
      "serviceFee",
      serviceFee,
      "month",
      month,
      "year",
      year
    );
  }, [currency, brand, model, vehicleType, serviceFee, month, year]);

  const resetValues = () => {
    setJValue(0);
    setMValue(0);
    setChecked(false);
    setChooseSite(1);
    setBrand(["Brand", null]);
    setModel(["Model", null]);
    setVehicleType(["Vehicle Type", null]);
    setServiceFee(["Service Fee", null]);
    setMonth(["Select Month", null]);
    setYear(["Select Year", null]);
    setShowResult(false);
  };
  // get currency rate
  const [currencyData, setCurrencyData] = useState(null);
  const [price_settings, setPriceSettings] = useState(null);
  useEffect(() => {
    // 4. Get currency
    const CalculateCurrency = async () => {
      try {
        const response = await axios.post(
          "calculators/currency",
          {
            currency: currency[1],
          },
          { headers_default }
        );
        setCurrencyData(response.data.data);
        setCurrencyRate(response.data.message.rate);
        // resetValues();
        // 5. Get price setting
        try {
          const response = await axios.get(
            "calculators/price-setting",
            {
              currency: currency[1],
            },
            { headers_default }
          );
          setPriceSettings(response.data);
        } catch (error) {
          console.error("error", error);
        }
      } catch (error) {
        console.error("error", error);
      }
    };
    if (!currencyData) {
      CalculateCurrency();
    }
    if (currency[1] === 2) {
      setChooseSite(1);
    } else {
      setChooseSite(1);
    }
  }, [currencyData]);

  // get cars
  const [carBrands, setCarBrands] = useState(null);
  useEffect(() => {
    // get all brands
    const getBrands = async () => {
      try {
        const response = await axios.get("calculators/duty-list-filter", {
          headers_default,
          params: {
            currency: currency[1],
          },
        });
        setCarBrands(response.data.brands);
      } catch (error) {
        console.error("error", error);
      }
    };
    getBrands();
    // console.log('cookies', cookies);
  }, []);

  // get models
  const [carModels, setCarModels] = useState(null);
  useEffect(() => {
    // get all models
    const getModels = async () => {
      try {
        const response = await axios.get("calculators/duty-list-filter", {
          headers_default,
          params: {
            currency: currency[1],
            brand: brand[1],
          },
        });
        setCarModels(response.data.models);
      } catch (error) {
        console.error("error", error);
      }
    };
    if (brand[1]) {
      getModels();
    }
  }, [brand]);

  function handleMYRconversion(event) {
    setJValue(addCommas(removeNonNumeric(event.target.value)));
    const cleanValue = removeCommas(event.target.value) * currencyRate;
    if (!isNaN(cleanValue)) {
      setMValue(addCommas(twoDp(cleanValue).toString()));
    }
  }
  function handleREVERSEconversion(event) {
    setMValue(addCommas(removeNonNumeric(event.target.value)));
    const cleanValue = removeCommas(event.target.value) / currencyRate;
    if (!isNaN(cleanValue)) {
      setJValue(addCommas(twoDp(cleanValue).toString()));
    }
  }

  // 6. Calculate import price
  const [import_price_1, setImportPrice1] = useState(null);
  const [show_Step2, setShowStep2] = useState(false);
  const getImportResult = async (referenceID) => {
    // console.log('importref',import_price_1?.reference);
    try {
      const resp = await axios.get(
        `calculators/import?reference=${referenceID}`,
        { headers_default }
      );
      // console.log('reference:',resp);
    } catch (error) {
      console.error("error", error);
    }
  };
  const calculateImport = async () => {
    const carAge = new Date(parseInt(year[0]), parseInt(month[1]) - 1, 1);
    const today = new Date();
    const diffInMonths =
      (today.getFullYear() - carAge.getFullYear()) * 12 +
      (today.getMonth() - carAge.getMonth());
    if (checked != false) {
      try {
        const response = await axios.post(
          `calculators/import`,
          {
            currency: currency[1], //int
            type: chooseSite, //int
            brand: brand[1], //int
            model: model[1], //int
            vehicle_type: vehicleType[1], //int
            original_price: removeCommas(jValue), //number
            month: month[1], //str
            year: year[1], //str
            service_charge: serviceFee[1], //str
          },
          {
            headers: {
              Authorization: `Bearer ${cookies?.accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-localization": "en",
            },
          }
        );
        console.log("reference", response.data.data.reference);
        console.log("data", response.data.data);
        setShowResult(true);
        setImportPrice1(response.data.data);
        setCarAge(diffInMonths);
        getImportResult(response.data.data.reference);
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.errors) {
          const firstError = Object.values(error.response?.data.errors)[0][0];
          if (firstError) {
            messageApi.open({
              type: "error",
              content: firstError,
            });
          } else {
            messageApi.open({
              type: "error",
              content: error.message,
            });
          }
        }
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Approve permit to proceed",
      });
    }
  };

  {
    /* FOR LOGIN and etc */
  }
  // login modal
  const [loginModal, setLoginModal] = useState(false);
  const handleCloseLoginModal = () => setLoginModal(false);
  const handleLoginModal = () => {
    setLoginModal(true);
    setRegisterModal(false);
    setForgotPassModal(false);
  };
  // register modal
  const [registerModal, setRegisterModal] = useState(false);
  const handleCloseRegisterModal = () => setRegisterModal(false);
  const handleRegisterModal = () => {
    setRegisterModal(true);
    setLoginModal(false);
    setForgotPassModal(false);
  };
  // forgotpassword
  const [forgotpassModal, setForgotPassModal] = useState(false);
  const handleClosePasswordModal = () => setForgotPassModal(false);
  const handlePasswordModal = () => {
    setForgotPassModal(true);
    setLoginModal(false);
    setRegisterModal(false);
  };
  // resetpassword modal
  const [showReset, setShowReset] = useState(false);
  const handleCloseReset = () => setShowReset(false);

  //update insurance and road tax
  useEffect(() => {
    // if both are true, call api then enable button
    if (roadTaxBool === true && insuranceBool === true) {
      try {
        const response = axios.post(
          `calculators/update-import-result`,
          {
            reference: import_price_1?.reference,
            road_tax_price: yearlyRoadtax,
            insurance_price: yearlypayment,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-localization": "en",
            },
          }
        );
        setBtnDisabled(false);
        console.log("update", response);
      } catch (err) {
        console.error(err);
      }
    }
  }, [roadTaxBool, insuranceBool]);

  const [agentNumber, setAgentNumber] = useState("");

  const getAgentNumber = async () => {
    try {
      const response = await axios.get("users", {
        headers: {
          Authorization: `Bearer ${cookies.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-localization": "en",
        },
      });
      setAgentNumber(response.data.data.referral.phone_number);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAgentNumber();
  }, []);

  return (
    <>
      {contextHolder}
      <div
        className={`fin-tab-pane-2 ${show_result === false ? "" : "hidden"} ${
          show_Step2 === false ? "" : "hidden"
        }`}
      >
        <div className="fin-tab-pane-2-header">
          <h3>Import Calculator</h3>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="imp-cal-item-2 fin-tab-pane-2-input">
            {/* <DropdownButton className="fin-cal-option" id="" title={ currency[0] ? currency[0] : "" }>
                            <Dropdown.Item onClick={() => {setCurrency( ["Japan (JPY)", 1] );setCurrencyRate(0.0325);setJValue(0);setMValue(0); setCurrencyData(null);} }>Japan (JPY)</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setCurrency( ["United Kingdom (Sterling Pound)", 2] );setCurrencyRate(5.4533);setJValue(0);setMValue(0); setCurrencyData(null);} }>United Kingdom (Sterling Pound)</Dropdown.Item>
                        </DropdownButton> */}
            <DropdownButton
              className="fin-cal-option"
              id=""
              title={currency[0] ? currency[0] : ""}
            >
              <Dropdown.Item
                onClick={() => {
                  setCurrency(["Japan (JPY)", 1]);
                  setCurrencyRate(0.0325);
                  setJValue(0);
                  setMValue(0);
                  setCurrencyData(null);
                }}
              >
                Japan (JPY)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCurrency(["United Kingdom (Sterling Pound)", 2]);
                  setCurrencyRate(6.11);
                  setJValue(0);
                  setMValue(0);
                  setCurrencyData(null);
                }}
              >
                United Kingdom (Sterling Pound)
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div
            className={`flex gap-x-4 flex-nowrap ${
              currency[1] == 1 ? "imp-cal-item-3 " : "hidden"
            }`}
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full relative">
              <input
                type="text"
                value="Bidding"
                readOnly
                className={`input_import ${
                  chooseSite == 1 ? "selected" : ""
                } cursor-pointer`}
                onClick={() => setChooseSite(1)}
                style={{ paddingLeft: "20px" }}
              ></input>
            </div>
            <div className="fin-tab-pane-2-input w-full relative">
              <input
                type="text"
                value="Asnet, Goonet & One Price"
                readOnly
                className={`input_import ${
                  chooseSite == 2 ? "selected" : ""
                } cursor-pointer`}
                onClick={() => setChooseSite(2)}
                style={{ paddingLeft: "20px" }}
              ></input>
            </div>
          </div>
          <div
            className="imp-cal-item-1 flex gap-x-4 max-sm:gap-x-2 flex-nowrap"
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full">
              <DropdownButton
                className="fin-cal-option imp_calculator"
                id=""
                title={brand[0] ? brand[0] : ""}
              >
                {carBrands?.map((brandName, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => {
                      setBrand([
                        brandName.title,
                        brandName.id,
                        brandName.models,
                      ]);
                    }}
                  >
                    {brandName.title}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>

            <div className="fin-tab-pane-2-input w-full">
              <DropdownButton
                className="fin-cal-option imp_calculator"
                id=""
                title={model[0] ? model[0] : ""}
              >
                {carModels?.map((modelName, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => {
                      setModel([modelName.title, modelName.id]);
                    }}
                  >
                    {modelName.title}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div className="fin-tab-pane-2-input imp-cal-item-2">
            <DropdownButton
              className="fin-cal-option"
              id=""
              title={vehicleType[0] ? vehicleType[0] : ""}
            >
              <Dropdown.Item onClick={() => setVehicleType(["Convertible", 1])}>
                Convertible
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType(["Coupe", 2])}>
                Coupe
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setVehicleType(["Electric Vehicle", 3])}
              >
                Electric Vehicle
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType(["SUV", 4])}>
                SUV
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType(["MPV", 5])}>
                MPV
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType(["Hatchback", 6])}>
                Hatchback
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType(["Sedan", 7])}>
                Sedan
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType(["Wagon", 8])}>
                Wagon
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div
            className="imp-cal-item-1 flex gap-x-4 flex-nowrap"
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="conversion_label">
                {currency[1] == 1 ? "JPY" : "GBP £"}
              </h4>
              <input
                onWheel={(e) => e.target.blur()}
                value={jValue === 0 ? "" : jValue}
                placeholder="Price"
                className="input_import"
                onChange={handleMYRconversion} // onChange={e => {setJValue(e.target.value);setMValue(e.target.value * currencyRate)}}
              />
            </div>
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="conversion_label">MYR</h4>
              <input
                onWheel={(e) => e.target.blur()}
                value={mValue === 0 ? "" : mValue}
                placeholder="Price"
                className="input_import"
                onChange={handleREVERSEconversion} // onChange={e => {setJValue(e.target.value / currencyRate );setMValue(e.target.value)}}
              />
              {/* <input type="number" value={mValue} onWheel={(e) => e.target.blur()} readOnly placeholder="Price" className='input_import'></input> */}
            </div>
          </div>
          <p className="calculator_note">
            {currency[1] == 1
              ? `JPY 100 = MYR ${Number(currencyRate * 100).toFixed(4)} `
              : `£ 1 = MYR ${Number(currencyRate).toFixed(4)}`}{" "}
            (Exchange rates are updated every Sunday following{" "}
            {currency[1] == 1 ? <b>CIMB & PBB rates</b> : <b>PBB rates</b>})
          </p>
          <div className="fin-tab-pane-2-input">
            <DropdownButton
              className="fin-cal-option imp_calculator"
              id=""
              title={month[0] ? month[0] : ""}
            >
              <Dropdown.Item onClick={() => setMonth(["January", "01"])}>
                January
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["February", "02"])}>
                February
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["March", "03"])}>
                March
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["April", "04"])}>
                April
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["May", "05"])}>
                May
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["June", "06"])}>
                June
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["July", "07"])}>
                July
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["August", "08"])}>
                August
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["September", "09"])}>
                September
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["October", "10"])}>
                October
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["November", "11"])}>
                November
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setMonth(["December", "12"])}>
                December
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <DropdownButton
              className="fin-cal-option"
              id=""
              title={year[0] ? year[0] : ""}
            >
              <Dropdown.Item onClick={() => setYear(["2018", 2018])}>
                2018
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setYear(["2019", 2019])}>
                2019
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setYear(["2020", 2020])}>
                2020
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setYear(["2021", 2021])}>
                2021
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setYear(["2022", 2022])}>
                2022
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setYear(["2023", 2023])}>
                2023
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div className="flex items-center gap-x-4 mb-4">
            <Form.Check
              type="checkbox"
              id="terms"
              className="flex items-center"
            >
              <Form.Check.Input
                type="checkbox"
                onChange={() => {
                  setChecked(!checked);
                }}
                required
                isValid
              />
              <Form.Check.Label htmlFor="terms" className="permit_label">
                Approve Permit
              </Form.Check.Label>
            </Form.Check>
            <p className={`${checked ? "show" : "hiding"} fix_rate`}>
              MYR {price_settings != null ? price_settings.approve_permit : "-"}
            </p>
          </div>

          <div className="fin-tab-pane-2-input mb-4">
            <DropdownButton
              className="fin-cal-option"
              id=""
              title={serviceFee[0] ? serviceFee[0] : ""}
            >
              <Dropdown.Item onClick={() => setServiceFee(["3%", "3"])}>
                <p className="row">
                  <span className="col-6">3%</span>
                  <span className="col-6">Service fee on cash payments</span>
                </p>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setServiceFee(["8%", "8"])}>
                <p className="row">
                  <span className="col-6">8%</span>
                  <span className="col-6">
                    Service fee on loans 90% and below
                  </span>
                </p>
              </Dropdown.Item>
            </DropdownButton>
          </div>

          {/* <div className="fin-tab-pane-2-input">
                        <div className="fin-tab-pane-payment">{import_price_1 != null ? `MYR ${import_price_1.breakdown.converted_price}` : "MYR 0"}</div>
                    </div> */}

          {!cookies.accessToken ? (
            <button
              type="button"
              onClick={() => setLoginModal(true)}
              className="fin-tab-pane-calculate-btn"
            >
              Calculate
            </button>
          ) : (
            <button
              type="button"
              onClick={calculateImport}
              className="fin-tab-pane-calculate-btn"
            >
              Calculate
            </button>
          )}
        </div>
      </div>

      <ImportResultComponent
        displayResult={show_result != false ? "" : "hidden"}
        Car_ModelSpecification={
          import_price_1 == null ? "Select model" : import_price_1.model
        }
        Car_Age={
          car_age != null
            ? `${car_age} Months Old [ ${month[1]} / ${year[0]} ]`
            : "-"
        }
        Car_Price={
          import_price_1 != null
            ? import_price_1.currency === "JPY"
              ? import_price_1.breakdown.original_price.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                ) + " JPY"
              : import_price_1.breakdown.original_price.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                ) + " GBP"
            : "-"
        }
        Shipment_Fees={
          import_price_1 != null
            ? import_price_1.currency === "JPY"
              ? `${(
                  parseFloat(import_price_1?.breakdown?.shipping_price) *
                  parseFloat(currencyRate)
                )
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} JPY`
              : `${(
                  parseFloat(import_price_1?.breakdown?.shipping_price) /
                  parseFloat(currencyRate)
                )
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GBP`
            : "-"
        }
        Port_Summary={
          import_price_1 != null
            ? import_price_1?.currency === "JPY"
              ? // `${
                //     parseFloat(import_price_1.breakdown.original_price) +
                //     parseFloat(import_price_1?.breakdown?.shipping_price) *
                //       parseFloat(currencyRate)
                //   } JPY × ${Number(currencyRate).toFixed(4)}`
                // : `${(
                //     parseFloat(import_price_1.breakdown.original_price) +
                //     parseFloat(import_price_1?.breakdown?.shipping_price) /
                //       parseFloat(currencyRate)
                //   ).toFixed(0)} GBP × ${Number(currencyRate).toFixed(4)}`

                // import_price_1.breakdown.original_price.replace(
                //   /\B(?=(\d{3})+(?!\d))/g,
                //   ",",
                // ) + " JPY"

                `${(
                  parseFloat(import_price_1.breakdown.original_price) +
                  parseFloat(import_price_1?.breakdown?.shipping_price) *
                    parseFloat(currencyRate)
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} JPY × ${Number(
                  currencyRate
                ).toFixed(4)}`
              : `${(
                  parseFloat(import_price_1.breakdown.original_price) +
                  parseFloat(import_price_1?.breakdown?.shipping_price) /
                    parseFloat(currencyRate)
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} GBP × ${Number(
                  currencyRate
                ).toFixed(4)}`
            : "-"
        }
        Port_Arrival_Fee={
          import_price_1 != null
            ? `MYR 
            ${
              // (
              //   parseFloat(import_price_1.breakdown.converted_price) +
              //   parseFloat(import_price_1?.breakdown?.shipping_price)
              // )
              //   .toFixed(2)
              //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              (
                parseFloat(import_price_1.breakdown.converted_price) +
                parseFloat(import_price_1?.breakdown?.shipping_price)
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
                `
            : "-"
        }
        Port_Conversion_Rate={
          import_price_1 != null
            ? import_price_1?.currency === "JPY"
              ? `JPY 100 = MYR ${Number(currencyRate * 100).toFixed(4)}`
              : `£ 1 = MYR ${Number(currencyRate).toFixed(4)}`
            : "-"
        }
        Tax_Duty={
          import_price_1 != null
            ? // ? `MYR ${import_price_1.breakdown.duty_price
              //     ?.toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              `MYR ${import_price_1.breakdown.duty_price.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`
            : "-"
        }
        Cost_Price={
          import_price_1 != null
            ? // ? `MYR ${(
              //     parseFloat(
              //       parseFloat(import_price_1.breakdown.converted_price) +
              //         parseFloat(import_price_1?.breakdown?.shipping_price)
              //     ) + parseFloat(import_price_1.breakdown.duty_price)
              //   )
              //     .toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              `MYR ${(
                parseFloat(
                  parseFloat(import_price_1.breakdown.converted_price) +
                    parseFloat(import_price_1?.breakdown?.shipping_price)
                ) + parseFloat(import_price_1.breakdown.duty_price)
              )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "-"
        }
        Approve_Permit={
          import_price_1 != null
            ? import_price_1?.breakdown?.approve_permit != null
              ? `MYR ${import_price_1?.breakdown?.approve_permit.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}`
              : "-"
            : "-"
        }
        Service_Fees={
          import_price_1 != null
            ? `MYR ${import_price_1?.breakdown?.service_charge.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )} (${serviceFee[0]})`
            : "-"
        }
        Import_Final_Price={
          import_price_1 != null
            ? // ? `MYR ${(
              //     parseFloat(import_price_1.first_payment) +
              //     parseFloat(import_price_1.second_payment)
              //   )
              //     ?.toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              `MYR ${(
                parseFloat(import_price_1.breakdown.converted_price) +
                parseFloat(import_price_1?.breakdown?.shipping_price) +
                parseFloat(import_price_1.breakdown.duty_price) +
                parseFloat(import_price_1.breakdown.service_charge) +
                parseFloat(import_price_1.breakdown.road_tax_price) +
                parseFloat(import_price_1.breakdown.insurance_price) +
                parseFloat(import_price_1.breakdown.approve_permit)
              )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "-"
        }
        First_Payment={
          import_price_1 != null
            ? // ? `MYR ${Number(import_price_1.first_payment)
              //     .toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              `MYR ${import_price_1.first_payment.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`
            : "-"
        }
        Second_Payment={
          import_price_1 != null
            ? // ? `MYR ${Number(import_price_1.second_payment)
              //     .toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              `MYR ${import_price_1.second_payment.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`
            : "-"
        }
        Next_Button={() => {
          setShowStep2(true);
          setShowResult(false);
        }}
      />

      <div className={`fin-tab-pane-2 ${show_Step2 === true ? "" : "hidden"}`}>
        <CarInsuranceCalculator
          setInsuranceBool={setInsuranceBool}
          setYearlyPayment={setYearlyPayment}
          yearlypayment={yearlypayment}
        />
        <hr className="fin-tab-pane-hr" />
        <RoadTaxCalculator
          setRoadTaxBool={setRoadTaxBool}
          setYearlyRoadtax={setYearlyRoadtax}
          yearlyRoadtax={yearlyRoadtax}
        />

        <div className="fin-tab-pane-2-content">
          {/* <button onClick={() => { setShowStep2(false); setShowResult(false); }} className="fin-tab-pane-recalculate-btn">
                        Re-calculate
                    </button> */}
          {/* <button className="fin-tab-pane-calculate-btn">
                        Message our agent now!
                    </button> */}
          <button
            disabled={btnDisabled}
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send/?phone=60${agentNumber}&text=Hi%20there,%20I%27m%20interesting%20to%20enquiry%20more%20details%20for%20the%20vehicles.%20Here%20is%20the%20link:%20https://www.autopitonline.com/import-reference?details=${import_price_1?.reference}`,
                "_blank"
              );
            }}
            className="fin-tab-pane-calculate-link"
          >
            Message our agent now!
          </button>
        </div>
      </div>

      <Prompt_Modal
        showloginModal={loginModal}
        onHideLoginModal={handleCloseLoginModal}
        login_Register_Btn={handleRegisterModal}
        login_ForgotPS_Btn={handlePasswordModal}
        showregisterModal={registerModal}
        onHideregisterModal={handleCloseRegisterModal}
        register_Login_Btn={handleLoginModal}
        register_ForgotPS_Btn={handlePasswordModal}
        showforgotpassModal={forgotpassModal}
        onHideforgotpassModal={handleClosePasswordModal}
        forgot_Login_Btn={handleLoginModal}
        showResetModal={showReset}
        onHideResetModal={handleCloseReset}
        reset_Login_Btn={handleLoginModal}
      />
    </>
  );
}

export default ImportCalculator;
