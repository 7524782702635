import React, { useEffect, useState } from "react";
import "./Profile_Item.scss";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import { Button, message } from "antd";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";

function MyProfileItem2() {
  const [cookies] = useCookies(["accessToken"]);
  const [messageApi, contextHolder] = message.useMessage();

  const [user, setUser] = useState({
    username: "",
    phonenumber: "",
    emailaddress: "",
  });
  const [username, setUsername] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [profileDefault, setProfileDefault] = useState([]);

  const [editable, setEditable] = useState(false);

  const [agentInfo, setAgentInfo] = useState({
    agent_name: "",
    agent_phone: "",
    agent_email: "",
    afilliate_code: "",
  });

  const getProfile = async () => {
    try {
      const response = await axios.get("users", {
        headers: {
          Authorization: "Bearer " + cookies.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("users", response.data.data);
      setProfileDefault(response.data.data);

      setUser({
        username: response.data.data.username,
        email: response.data.data.email,
        phonenumber: response.data.data.phone_number,
      });
      setUsername(response.data.data.username);
      setEmail(response.data.data.email);
      setPhoneNumber(response.data.data.phone_number);
      setAgentInfo({
        agent_name: response.data.data.referral.username,
        agent_phone: response.data.data.referral.phone_number,
        agent_email: response.data.data.referral.email,
        afilliate_code: response.data.data.referral.invitation_code,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const editProfile = () => {
    setEditable(true);
  };

  const updateProfile = async () => {
    try {
      const response = await axios.post(
        "users/me",
        JSON.stringify({
          username: username,
          email: email,
          phone_number: parseInt(phonenumber),
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + cookies.accessToken,
          },
        }
      );

      // console.log('updateProfile',response);
      setEditable(false);
      getProfile();

      messageApi.open({
        type: "success",
        content: "Profile updated successfully",
      });
    } catch (error) {
      console.log(error);

      if (error?.response?.data?.errors?.phone_number?.length === 1) {
        messageApi.open({
          type: "error",
          content: `${error?.response?.data?.errors?.phone_number[0]}`,
        });
      } else if (error?.response?.data?.errors?.username.length === 1) {
        messageApi.open({
          type: "error",
          content: `${error?.response?.data?.errors?.username[0]}`,
        });
      } else {
        messageApi.open({
          type: "error",
          content: `${error?.response?.data?.message}`,
        });
      }
    }
  };

  // change password
  const [showChgPass, setShowChgPass] = useState(false);
  const handleCloseChgPass = () => setShowChgPass(false);
  const handleShowChgPass = () => setShowChgPass(true);

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const [errormessage, setErrorMessage] = useState("");
  const [showChgPassFailed, setShowChgPassFailed] = useState(false);
  const postChangePassword = async () => {
    try {
      const response = await axios.post(
        "users/password",
        JSON.stringify({
          old_password: oldpassword,
          password: newpassword,
          password_confirmation: confirmpassword,
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + cookies.accessToken,
          },
        }
      );
      // console.log("postChangePassword", response);
      messageApi.open({
        type: "success",
        content: "Password updated successfully",
      });

      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);

      if (error.response?.data?.errors?.old_password == undefined) {
        var errors = error.response?.data?.errors?.password[0];
      } else {
        if (
          error.response?.data?.errors?.old_password[0] ==
          "api.old_password_not_match"
        ) {
          var errors = "Old password did not match";
        } else {
          var errors = error.response?.data?.errors?.old_password[0];
        }
      }
      let error_message = errors;

      messageApi.open({
        type: "error",
        content: error_message,
      });

      setTimeout(function () {
        setShowChgPassFailed(false);
      }, 2000);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="wrap-prof-hero ">
        <div className="v2-prof-container">
          <div className="v2-prof-content-2">
            <div className={`v2-prof-content-2-form`}>
              <h1>My Agent Info</h1>

              <div className="v2-prof-form-item">
                <label>Full Name</label>
                <input readOnly={true} value={agentInfo.agent_name} />
              </div>

              <div className="v2-prof-form-item">
                <label>Affiliate Code</label>
                <input readOnly={true} value={agentInfo.afilliate_code} />
              </div>

              <div className="v2-prof-form-item">
                <label>Phone Number</label>
                <input
                  readOnly={true}
                  value={agentInfo.agent_phone.toString()}
                />
              </div>

              <div className="v2-prof-form-item">
                <label>Email Address</label>
                <input value={agentInfo.agent_email} readOnly={true} />
              </div>

              <div className="v1-prof-form-btns">
                <button
                  className={`chgPs_profbtn mx-auto block`}
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send/?phone=60${agentInfo.agent_phone}&text=Hi%20there,%20I%20would%20like%20to%20change%20my%20agent%20for%20my%20account.`,
                      "_blank"
                    )
                  }
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>

          <div className="v2-prof-content-2">
            <div className={`v2-prof-content-2-form`}>
              <h1>Personal Info</h1>

              <div className="v2-prof-form-item profile-username">
                <label>Username</label>
                <input
                  readOnly={!editable}
                  onChange={(e) => setUsername((a) => e.target.value)}
                  placeholder={profileDefault.username}
                />
              </div>

              <div className="v2-prof-form-item profile-phone">
                <label>Phone Number</label>
                {/* <div> 
                    <span className={editable?'':'hidden'} >+60</span><input readOnly={!editable} onChange={(e)=>setPhoneNumber(a=>60+e.target.value)} placeholder={profileDefault.phone_number}/>
                </div> */}
                <input
                  readOnly={!editable}
                  onChange={(e) => setPhoneNumber((a) => e.target.value)}
                  placeholder={
                    editable === false
                      ? profileDefault.phone_number
                      : "Enter Country Code and Phone Number"
                  }
                />
              </div>

              <div className="v2-prof-form-item profile-email">
                <label>Email Address</label>
                <input
                  defaultValue={user.emailaddress}
                  readOnly={!editable}
                  onChange={(e) => setEmail((a) => e.target.value)}
                  placeholder={profileDefault.email}
                />
              </div>

              <div className="v2-prof-form-btns">
                {editable === false ? (
                  <>
                    <button className={`edit_profbtn`} onClick={editProfile}>
                      Edit Profile
                    </button>
                    <button
                      className={`chgPs_profbtn`}
                      onClick={handleShowChgPass}
                    >
                      Change Password
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={`saveChg_profbtn`}
                      onClick={updateProfile}
                    >
                      Save Changes
                    </button>
                    <button
                      className={`cancel_profbtn`}
                      onClick={() => window.location.reload()}
                    >
                      Cancel Edit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* changepassword  */}
      <Modal
        show={showChgPass}
        onHide={handleCloseChgPass}
        className="login-modal chg-pass-modal"
        backdrop="static"
        centered
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>Change Password </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <Alert
            variant="danger"
            show={showChgPassFailed}
            className="login-alert-message"
          >
            <span
              className="login-alert-icon"
              aria-hidden="true"
              data-icon="&#xe90f;"
            ></span>
            <span className="login-alert-text">&nbsp;{errormessage}</span>
          </Alert>
          <div className="login-form-container">
            <div className="login-form-ps">
              <label for="psw">Old Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Type Old Password"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
            </div>
            <div className="login-form-ps">
              <label for="psw">New Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Type New Password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
            <div className="login-form-ps">
              <label for="psw">Confirm Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Re-enter New Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>

            <div className="login-form-etc">
              <button onClick={() => window.location.reload()}>Cancel</button>
            </div>
            <div className="login-buttons">
              <button type="submit" onClick={postChangePassword}>
                Confirm Password{" "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyProfileItem2;
