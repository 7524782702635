import React, { useEffect } from "react";
import "../../App.css";
import { Helmet } from "react-helmet";
import MyOrders from "../profile/MyOrder_Item";

function MyOrdersPage() {
  useEffect(() => {
    document.title = "My Orders | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/profile/orders" />

        <meta name="canonical" content="/profile/orders" />
        <meta property="og:url" content="/profile/orders" />
      </Helmet>

      <MyOrders />
    </>
  );
}

export default MyOrdersPage;
