import React, { useState } from "react";
import "../../App.css";
import "./Finance.scss";
import axios from "../../api/axios";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";

function CarInsuranceCalculator() {
  const [carPrice, setCarPrice] = useState(100000);
  const [coverageType, setCoverageType] = useState(["Comprehensive", 1]);
  const [location, setLocation] = useState(["Peninsular Malaysia", "West", 1]);
  const [enginecapacity, setEngineCapacity] = useState(["0 - 1400 cc", 1]);
  const [claimsdiscount, setClaimsDiscount] = useState(["None - 0%", 1]);
  const [yearlypayment, setYearlyPayment] = useState(0);

  const CalculateInsurance = async () => {
    try {
      const response = await axios.post(
        "calculators/insurance",
        {
          cc: enginecapacity[1],
          region: location[2],
          type: coverageType[1],
          market_price: carPrice,
          no_claims_discount: claimsdiscount[1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-localization": "en",
          },
        },
      );
      setYearlyPayment(response.data.data?.yearly_payment);
      console.log("setYearlyPayment: ", response);
      // console.log('setYearlyPayment', response.data.data?.yearly_payment);
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Car Insurance Calculator (Optional)</h3>
          <p>
            Calculate how much you will have to spend on your car insurance.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Market Price</h4>
            <input
              type="number"
              onChange={(e) => setCarPrice(e.target.value)}
              placeholder="MYR 100,000"
            ></input>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Coverage Type</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={coverageType[0] ? coverageType[0] : "Comprehensive "}
            >
              <Dropdown.Item
                onClick={() => setCoverageType(["Comprehensive", 1])}
              >
                Comprehensive
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setCoverageType( ["Third Party", 2] ) }>Third Party</Dropdown.Item> */}
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Location</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={location[0] ? location[0] : "Peninsular Malaysia "}
            >
              <Dropdown.Item
                onClick={() => setLocation(["Peninsular Malaysia", "West", 1])}
              >
                Peninsular Malaysia
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setLocation(["Sabah / Sarawak / Labuan", "East", 2])
                }
              >
                Sabah / Sarawak / Labuan
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Engine Capacity</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={enginecapacity[0] ? enginecapacity[0] : "0 - 1400 cc"}
            >
              <Dropdown.Item
                onClick={() => setEngineCapacity(["0 - 1400 cc", 1])}
              >
                0 - 1400 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["1401 - 1650 cc", 2])}
              >
                1401 - 1650 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["1651 - 2200 cc", 3])}
              >
                1651 - 2200 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["2201 - 3050 cc", 4])}
              >
                2201 - 3050 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["3051 - 4100 cc", 5])}
              >
                3051 - 4100 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["4101 - 4250 cc", 6])}
              >
                4101 - 4250 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["4251 - 4400 cc", 7])}
              >
                4251 - 4400 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["Over 4400 cc", 8])}
              >
                Over 4400 cc
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>No Claims Discount</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={claimsdiscount[0] ? claimsdiscount[0] : "None - 0%"}
            >
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["None - 0%", 1])}
              >
                None - 0%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["First Year - 25%", 2])}
              >
                First Year - 25%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Second Year - 30%", 3])}
              >
                Second Year - 30%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Third Year - 38.33%", 4])}
              >
                Third Year - 38.33%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Fourth Year - 45%", 5])}
              >
                Fourth Year - 45%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setClaimsDiscount(["Five Years or more - 55%", 6])
                }
              >
                Five Years or more - 55%
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <button
            onClick={CalculateInsurance}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>

          <div className="fin-tab-pane-2-input">
            <h4>Yearly Insurance Payment</h4>
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {yearlypayment
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function RoadTaxCalculator() {
  const [enginecapacity, setEngineCapacity] = useState(1600);
  const [location, setLocation] = useState(["Peninsular Malaysia", "West", 1]);
  const [ownerType, setOwnerType] = useState([
    "Saloon Cars / Individual Owned",
    1,
  ]);
  const [yearlyRoadtax, setYearlyRoadtax] = useState(0);

  const CalculateRoadTax = async () => {
    try {
      const response = await axios.post(
        "calculators/road-tax",
        {
          cc: enginecapacity,
          region: location[2],
          type: ownerType[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      setYearlyRoadtax(response.data.data?.yearly_payment);
      console.log("response", response);
      console.log("setYearlyRoadtax", response.data.data?.yearly_payment);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Road Tax Calculator (Optional)</h3>
          <p>
            Latest JPJ formula - calculate how much your vehicle’s road tax will
            cost.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Engine Capacity (cc)</h4>
            <input
              type="number"
              onChange={(e) => setEngineCapacity(e.target.value)}
              placeholder="1600"
            ></input>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Region</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={location[0] ? location[0] : "Peninsular Malaysia "}
            >
              <Dropdown.Item
                onClick={() => setLocation(["Peninsular Malaysia", "West", 1])}
              >
                Peninsular Malaysia
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setLocation(["Sabah / Sarawak", "East", 2])}
              >
                Sabah / Sarawak{" "}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setLocation(["Pulau Pangkor & Langkawi", "Langkawi", 3])
                }
              >
                Pulau Pangkor & Langkawi
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setLocation(["Labuan", "Labuan", 4])}
              >
                Labuan
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4>Car / Ownership Type</h4>
            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={
                ownerType[0] ? ownerType[0] : "Saloon Cars / Individual Owned "
              }
            >
              <Dropdown.Item
                onClick={() =>
                  setOwnerType(["Saloon Cars / Individual Owned", 1])
                }
              >
                Saloon Cars / Individual Owned
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setOwnerType(["Saloon Cars / Company Owned", 2])}
              >
                Saloon Cars / Company Owned{" "}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setOwnerType([
                    "Non-Saloon Cars / Individual Or Company Owned",
                    3,
                  ])
                }
              >
                Non-Saloon Cars / Individual Or Company Owned
              </Dropdown.Item>
            </DropdownButton>
            <div className="fin-tab-pane-2-saloon-type">
              <div className="fin-tab-pane-2-saloon-type-1">
                <h5>Saloon:</h5>
                <p>Sedan / Hatchback / Coupe / Wagon / Converible</p>
              </div>
              <div className="fin-tab-pane-2-saloon-type-1">
                <h5>Non-Saloon:</h5>
                <p>MPV / SUV / Pick-up / Commercial</p>
              </div>
            </div>
          </div>

          <button
            onClick={CalculateRoadTax}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>
          <div className="fin-tab-pane-2-input">
            <h4>Yearly Road Tax Payment</h4>
            {/* <div className="fin-tab-pane-payment">MYR {yearlyRoadtax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> */}
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {yearlyRoadtax
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ImportResult2Component() {
  const [step1, setStep1] = useState(true);

  const [carPrice, setCarPrice] = useState(100000);
  const [downPayment, setDownPayment] = useState(10000);
  const [interestRate, setInterestRate] = useState(350);
  const [currencyRate, setCurrencyRate] = useState(0.0325);
  const [jValue, setJValue] = useState(0);
  const [mValue, setMValue] = useState(0);
  const [chooseSite, setChooseSite] = useState(0);
  const [currency, setCurrency] = useState(["Japan (Yen)", 1]);
  const [brand, setBrand] = useState(["Brand", 0]);
  const [model, setModel] = useState(["Model", 0]);
  const [vehicleType, setVehicleType] = useState(["Vehicle Type", 0]);
  const [month, setMonth] = useState(["Select Month", 0]);
  const [year, setYear] = useState(["Select Year", 0]);

  const [monthlypayment, setMonthlyPayment] = useState(0);

  return (
    <>
      <div className={`fin-tab-pane-2 ${step1 == true ? "" : "hidden"}`}>
        <div className="fin-tab-pane-2-header">
          <h3>Import Calculator</h3>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Car Model Specification</h4>
            <input
              type="text"
              value="(Q0010) ALFA ROMEO Stelvio BiTurbo Quadrifoglio Nring ( S/S ) SUV 5D AWD ( 510 BHP ) AT [ 2,891cc ]"
            />
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Car Age</h4>
            <input type="text" value="56 Months Old [ 05/2018 ]" />
          </div>
          <div
            className="flex gap-x-4 flex-nowrap"
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">Car Price</h4>
              <input
                type="text"
                value="106,991.00 UK"
                className="input_import font-bold"
                style={{ paddingLeft: "20px" }}
              />
            </div>
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">Shipment Fees</h4>
              <input
                type="text"
                value="5,000.00 UK"
                className="input_import font-bold"
                style={{ paddingLeft: "20px" }}
              />
            </div>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">
              Port arrival Total CIF Fees before clearance{" "}
              <span>( 109,291 UK × 5.45330 )</span>
            </h4>
            <input type="text" value="MYR 595,996.61" />
            <p
              className="calculator_note"
              style={{ border: "none", paddingBottom: "0" }}
            >
              £ 1 = MYR 5.45330 (Exchange rates are updated every Sunday
              following PBB rates)
            </p>
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Tax duty with documentation</h4>
            <input type="text" value="MYR 176,439.70" />
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Cost Price</h4>
            <input type="text" value="MYR 772,436.31" />
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">Service Fees (代购费)</h4>
            <input type="text" value="3%" />
          </div>
          <div className="fin-tab-pane-2-input">
            <h4 className="input_title_import">
              Import Final Price / Bidding Won Price
            </h4>
            <input type="text" value="MYR 795,609.40" />
          </div>

          <div
            className="flex gap-x-4 flex-nowrap"
            style={{ marginBottom: "30px" }}
          >
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">1st Payment</h4>
              <input
                type="text"
                value="MYR 595,996.61"
                className="input_import font-bold"
                style={{ paddingLeft: "20px" }}
              />
              <p
                className="calculator_note"
                style={{ border: "none", paddingBottom: "0" }}
              >
                *Shall be paid within 3 working days upon car purchased
              </p>
            </div>
            <div className="fin-tab-pane-2-input w-full relative">
              <h4 className="input_title_import">
                2nd Payment (Remaining balance)
              </h4>
              <input
                type="text"
                value="MYR 199,612.79"
                className="input_import font-bold"
                style={{ paddingLeft: "20px" }}
              />
              <p
                className="calculator_note"
                style={{ border: "none", paddingBottom: "0" }}
              >
                *Shall be paid upon car arrival in our showroom
              </p>
            </div>
          </div>

          <button
            onClick={() => setStep1(false)}
            className="fin-tab-pane-calculate-btn"
          >
            Next
          </button>

          {/* <button  className="fin-tab-pane-calculate-btn">
                        Next
                    </button> */}
        </div>
      </div>

      <div className={`fin-tab-pane-2 ${step1 == false ? "" : "hidden"}`}>
        <CarInsuranceCalculator />
        <hr className="fin-tab-pane-hr" />
        <RoadTaxCalculator />

        <div className="fin-tab-pane-2-content">
          <button className="fin-tab-pane-calculate-btn">
            Message to our agent now!
          </button>
        </div>
      </div>
    </>
  );
}

export default ImportResult2Component;
