import React, { useState } from "react";
import "../../App.css";
import "./Finance.scss";
import axios from "../../api/axios";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const twoDp = (num) => Math.round(num * 100) / 100;
const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeCommas = (num) => num.toString().replace(/,/g, "");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

function CarInsuranceCalculator() {
  const [carPrice, setCarPrice] = useState(100000);
  const [carPricePlaceholder, setCarPricePlaceholder] = useState(null);
  const [coverageType, setCoverageType] = useState(["Comprehensive", 1]);
  const [location, setLocation] = useState(["Peninsular Malaysia", "West", 1]);
  const [enginecapacity, setEngineCapacity] = useState(["0 - 1400 cc", 1]);
  const [claimsdiscount, setClaimsDiscount] = useState(["None - 0%", 1]);

  const [yearlypayment, setYearlyPayment] = useState(0);

  function handlePriceChange(e) {
    const value = removeNonNumeric(e.target.value);
    setCarPrice(value);
    setCarPricePlaceholder(addCommas(value));
  }

  const CalculateInsurance = async () => {
    try {
      const response = await axios.post(
        "calculators/insurance",
        {
          cc: enginecapacity[1],
          region: location[2],
          type: coverageType[1],
          market_price: carPrice,
          no_claims_discount: claimsdiscount[1],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setYearlyPayment(response.data.data?.yearly_payment);

      console.log("response", response);
      console.log("setYearlyPayment", response.data.data?.yearly_payment);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="fin-tab-pane-2">
        <div className="fin-tab-pane-2-header">
          <h3>Car Insurance Calculator</h3>
          <p>
            Calculate how much you will have to spend on your car insurance.
          </p>
        </div>

        <div className="fin-tab-pane-2-content">
          <div className="fin-tab-pane-2-input">
            <h4>Market Price</h4>

            <input
              value={carPricePlaceholder}
              onChange={handlePriceChange}
              placeholder="MYR 100,000"
            ></input>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Coverage Type</h4>

            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={coverageType[0] ? coverageType[0] : "Comprehensive "}
            >
              <Dropdown.Item
                onClick={() => setCoverageType(["Comprehensive", 1])}
              >
                Comprehensive
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => setCoverageType( ["Third Party", 2] ) }>Third Party</Dropdown.Item> */}
            </DropdownButton>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Location</h4>

            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={location[0] ? location[0] : "Peninsular Malaysia "}
            >
              <Dropdown.Item
                onClick={() => setLocation(["Peninsular Malaysia", "West", 1])}
              >
                Peninsular Malaysia
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setLocation(["Sabah / Sarawak / Labuan", "East", 2])
                }
              >
                Sabah / Sarawak / Labuan
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Engine Capacity</h4>

            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={enginecapacity[0] ? enginecapacity[0] : "0 - 1400 cc"}
            >
              <Dropdown.Item
                onClick={() => setEngineCapacity(["0 - 1400 cc", 1])}
              >
                0 - 1400 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["1401 - 1650 cc", 2])}
              >
                1401 - 1650 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["1651 - 2200 cc", 3])}
              >
                1651 - 2200 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["2201 - 3050 cc", 4])}
              >
                2201 - 3050 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["3051 - 4100 cc", 5])}
              >
                3051 - 4100 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["4101 - 4250 cc", 6])}
              >
                4101 - 4250 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["4251 - 4400 cc", 7])}
              >
                4251 - 4400 cc
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEngineCapacity(["Over 4400 cc", 8])}
              >
                Over 4400 cc
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>No Claims Discount</h4>

            <DropdownButton
              className="fin-cal-option"
              id="dropdown-basic-button uncontrolled-tab-example"
              title={claimsdiscount[0] ? claimsdiscount[0] : "None - 0%"}
            >
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["None - 0%", 1])}
              >
                None - 0%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["First Year - 25%", 2])}
              >
                First Year - 25%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Second Year - 30%", 3])}
              >
                Second Year - 30%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Third Year - 38.33%", 4])}
              >
                Third Year - 38.33%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setClaimsDiscount(["Fourth Year - 45%", 5])}
              >
                Fourth Year - 45%
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setClaimsDiscount(["Five Years or more - 55%", 6])
                }
              >
                Five Years or more - 55%
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div className="fin-tab-pane-2-input">
            <h4>Yearly Insurance Payment</h4>
            <div className="fin-tab-pane-payment">
              MYR{" "}
              {yearlypayment
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>

          <button
            onClick={CalculateInsurance}
            className="fin-tab-pane-calculate-btn"
          >
            Calculate
          </button>
        </div>
        <p className="disclaimer">
          * The premium quote provided by this Car Insurance Calculator is just
          an estimated premium amount, and the actual premium might differ from
          the amount shown here depending on the insurance provider and other
          factors.
        </p>
      </div>
    </>
  );
}

export default CarInsuranceCalculator;
