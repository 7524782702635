import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBBRkprVPsVxTZ_u7hYC2xDLx0ZOHz8I9s",
  authDomain: "autopit-61dcf.firebaseapp.com",
  projectId: "autopit-61dcf",
  storageBucket: "autopit-61dcf.appspot.com",
  messagingSenderId: "195406000519",
  appId: "1:195406000519:web:caca5af2f58d175d8de322",
  measurementId: "G-RVZED0VH63",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
