import React from "react";
import "./Policies.scss";

function DataDeletionGuide() {
  document.title = "Data Deletion Guide | AutoPit";

  return (
    <>
      <div className="wrap-policies">
        <div className="policies-block"></div>

        <div className="policies-header">
          <h1>Data Deletion Guide</h1>
        </div>

        <div className="policies-container-1">
          <p>
            According to the Facebook Platform rules, we have to provide{" "}
            <b>User Data Deletion Callback URL</b> or{" "}
            <b>Data Deletion Instructions URL</b>. If you want to delete your
            activities for Autopit, following these instructions:
          </p>

          <p>
            <ol>
              <li>
                Go to Your Facebook Account's Setting & Privacy. Click ” Setting
                “.
              </li>
              <li>
                Then, go to ” Apps and Websites” and you will see all of your
                Apps activities.
              </li>
              <li>Select the option box for Autopit.</li>
              <li>Click ” Remove” button.</li>
            </ol>
          </p>

          <p>
            If you wish to delete user account data, you have to request us to
            delete your account. Subject to your Autopit user account is no
            longer required in the future, please perform account deletion in
            the Autopit app by navigating to “
            <b>Profile &gt; Personal Info &gt; Delete Account</b>” and complete
            the required steps.
          </p>
        </div>
      </div>
    </>
  );
}

export default DataDeletionGuide;
