import React, { useEffect } from "react";
import "../../App.css";

import { Helmet } from "react-helmet";
import ImportPayment from "../import-car/ImportPayment";

function ImportPaymentPage() {
  useEffect(() => {
    document.title = "Import Payment | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/import-payment" />

        <meta name="canonical" content="/import-payment" />
        <meta property="og:url" content="/import-payment" />
      </Helmet>

      <ImportPayment />
    </>
  );
}

export default ImportPaymentPage;
