import React, { useState, useEffect, Fragment } from "react";
import "../../App.css";
import "./Cars.scss";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FaSearch, FaChevronRight, FaTimes } from "react-icons/fa";
import { Slider, Switch } from "antd";

// FilterComponent
const FilterComponent = (props) => {
  const base_url = `https://api.autopitonline.com/api/v1/filters`; // ?brand=18
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // filters
  const [filterType, setFilterType] = useState([]);

  const FilterType = () => {
    fetch(base_url)
      .then((res) => res.json())
      .then(
        (response) => {
          setIsLoaded(true);
          setFilterType(response);
          // console.log('results', response.brands);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        },
      );
  };

  // for model group
  const [cars_Data, setCarsData] = useState([]);
  const AllCarsData = () => {
    fetch(`https://api.autopitonline.com/api/v1/cars?per_page=100`)
      .then((res) => res.json())
      .then(
        (response) => {
          setCarsData(response.data);
          console.log("results", response);
        },
        (error) => {
          console.error(error);
        },
      );
  };

  useEffect(() => {
    FilterType();
    // AllCarsData();
  }, []);

  // filter_attribute
  let filter_attribute = "";

  const [bodyTypes, setBodyTypes] = useState([]);
  const [bodyTypes_Popover, setBodyTypesPopover] = useState(false);
  if (bodyTypes[0] === true) {
    filter_attribute = filter_attribute + `body_type=${bodyTypes[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [brands, setBrands] = useState([]);
  const [brands_Popover, setBrandsPopover] = useState(false);
  if (brands[0] === true) {
    filter_attribute = filter_attribute + `&brand=${brands[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [colours, setColours] = useState([]);
  const [colours_Popover, setColoursPopover] = useState(false);
  if (colours[0] === true) {
    filter_attribute = filter_attribute + `&colour=${colours[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [conditions, setConditions] = useState([]);
  const [conditions_Popover, setConditionsPopover] = useState(false);
  if (conditions[0] === true) {
    filter_attribute = filter_attribute + `&condition=${conditions[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [countries, setCountries] = useState([]);
  const [countries_Popover, setCountriesPopover] = useState(false);
  if (countries[0] === true) {
    filter_attribute = filter_attribute + `&country=${countries[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [driven_wheels, setDrivenWheels] = useState([]);
  const [driven_wheels_Popover, setDrivenWheelsPopover] = useState(false);
  if (driven_wheels[0] === true) {
    filter_attribute = filter_attribute + `&driven_wheel=${driven_wheels[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [fuel_types, setFuelTypes] = useState([]);
  const [fuel_types_Popover, setFuelTypesPopover] = useState(false);
  if (fuel_types[0] === true) {
    filter_attribute = filter_attribute + `&fuel_type=${fuel_types[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [model_groups, setModelGroups] = useState([]);
  if (model_groups[0] === true) {
    filter_attribute = filter_attribute + `&model_group=${model_groups[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [transmissions, setTransmission] = useState([]);
  const [transmissions_Popover, setTransmissionPopover] = useState(false);
  if (transmissions[0] === true) {
    filter_attribute = filter_attribute + `&transmission=${transmissions[1]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }

  const [minprice, setMinPrice] = useState(0);
  const [maxprice, setMaxPrice] = useState(2500000);
  const [priceFilter, setPriceFilter] = useState([]);
  if (priceFilter[0] === true) {
    filter_attribute =
      filter_attribute +
      `&min_price=${priceFilter[1]}` +
      `&max_price=${priceFilter[2]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }
  const priceRange_Event = (value) => {
    // ANTD range
    // console.log('onChange: ', value);
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
  };
  const onAfterChange = (value) => {
    // console.log('onAfterChange: ', value);
    // setMaxPrice(value[1]);
  };

  const [minmileage, setMinMileage] = useState(0);
  const [maxmileage, setMaxMileage] = useState(200000);
  const [mileageFilter, setMileageFilter] = useState([]);
  if (mileageFilter[0] === true) {
    filter_attribute =
      filter_attribute +
      `&min_mileage=${mileageFilter[1]}` +
      `&max_mileage=${mileageFilter[2]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }
  const mileageRange_Event = (value) => {
    // ANTD range
    setMinMileage(value[0]);
    setMaxMileage(value[1]);
  };

  const [minyear, setMinYear] = useState(1900);
  const [maxyear, setMaxYear] = useState(new Date().getFullYear());
  const [yearFilter, setYearFilter] = useState([]);
  if (yearFilter[0] === true) {
    filter_attribute =
      filter_attribute +
      `&min_year=${yearFilter[1]}` +
      `&max_year=${yearFilter[2]}`;
  } else {
    filter_attribute = filter_attribute + "";
  }
  const yearRange_Event = (value) => {
    // ANTD range
    setMinYear(value[0]);
    setMaxYear(value[1]);
  };

  const handleSearch = () => {
    window.location.href = `/cars?${filter_attribute}`;
  };

  const handleReset = () => {
    window.location.href = `/cars`;
  };

  // close all popover
  const closePopovers = () => {
    setBodyTypesPopover(false);
    setBrandsPopover(false);
    setColoursPopover(false);
    setConditionsPopover(false);
    setCountriesPopover(false);
    setDrivenWheelsPopover(false);
    setFuelTypesPopover(false);
    setTransmissionPopover(false);
  };

  // search input
  // const [query, setQuery] = useState("");
  const [brands_query, setBrandsQuery] = useState("");
  const [country_query, setCountryQuery] = useState("");

  // mobile offcanvas
  const [mobilefilter, setMobileFilter] = useState(false);

  const handleFilterClose = () => setMobileFilter(false);
  const handleFilterShow = () => setMobileFilter(true);

  const [activeBodyTypes, setActiveBodyTypes] = useState("");
  const [activeBrands, setActiveBrands] = useState("");
  const [activeColours, setActiveColours] = useState("");
  const [activeConditions, setActiveConditions] = useState("");
  const [activeCountries, setActiveCountries] = useState("");
  const [activeDrivenWheels, setActiveDrivenWheels] = useState("");
  const [activeTransmission, setActiveTransmission] = useState("");
  const [activeYear, setActiveYear] = useState("");

  // console.log('priceFilter', priceFilter);

  // search cars
  const [searchCars, setSearchCars] = useState("");
  // const handleKeyDown = (e) => {
  //     if (e.key === 'Enter') {
  //         window.location.href=`/cars?search_title=${searchCars}`;
  //     }
  // }

  return (
    <>
      <div className="cars-c-1-item">
        <div className="cars-c-1-item-header">
          <span>Search Filters</span>
        </div>

        <div className="cars-c-1-input-search input-group">
          <span className="input-group-text" id="search_bar">
            <FaSearch />
          </span>

          <input
            id="search"
            type="text"
            className="form-control"
            placeholder={props.item_SearchPlaceholder}
            aria-label="Search"
            aria-describedby="search_bar"
            autocomplete="off"
            onChange={(e) => props.item_SetSearchCars(e.target.value)}
            //  onChange={e => setSearchCars(e.target.value)} onKeyDown={handleKeyDown}
          />
        </div>

        {error ? (
          // <div>Error: {error.message}</div>
          <>
            {/* {console.log('error',error.message)} */}
            <div className="prod-content">
              <Alert variant="danger">
                <Alert.Heading className="ErrorRequest_Head">
                  Too many requests!{" "}
                </Alert.Heading>
                <hr />
                <p>Please try again later. </p>
              </Alert>
            </div>
          </>
        ) : !isLoaded ? (
          // <div>Loading...</div>
          <div className="LoadingSpinner">
            <Spinner animation="border" role="status" variant="info">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="cars-c-1-item-body">
            {/* <DropdownButton className={`cars-c-1-filter-item ${bodyTypes[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={bodyTypes[2] ? "Body Type: "+bodyTypes[2] : "Body Type"} >
                                    <h3>Body Type</h3>
                                        {filterType.body_types.map((item, i) => 
                                            <>
                                                <Dropdown.Item key={i} onClick={() => setBodyTypes( [true, item.id, item.title] ) } >
                                                    {item.title}
                                                </Dropdown.Item>
                                            </>
                                            )   
                                        }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={bodyTypes_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Body Type{" "}
                    <FaTimes
                      onClick={() => {
                        setBodyTypesPopover(!bodyTypes_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    {filterType.body_types.map((item, i) => (
                      <button
                        className={`${
                          bodyTypes
                            ? bodyTypes[1] === item.id
                              ? "selectedFilter"
                              : ""
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setBodyTypes([true, item.id, item.title]);
                          setBodyTypesPopover(!bodyTypes_Popover);
                        }}
                      >
                        {item.title}
                      </button>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setBodyTypesPopover(!bodyTypes_Popover);
                }}
              >
                <p className={`${bodyTypes[2] ? "haveFilter" : ""}`}>
                  Body Type
                </p>
                {bodyTypes[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{bodyTypes[2]}</span>{" "}
                    </div>
                    <FaTimes
                      onClick={() => {
                        setBodyTypes([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${brands[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={brands[2] ? "Brand: "+brands[2] : "Brand"} >
                                    <h3>Brand</h3>
                                    <div className="cars-filter-options-item-search input-group">
                                        <span className="input-group-text" id="search_bar"><FaSearch /></span>

                                        <input id="search3" type="text" className="form-control" placeholder="Search brands..." 
                                            aria-label="Search" aria-describedby="search_bar" autocomplete="off" 
                                            onChange={event => setQuery(event.target.value)} 
                                        />
                                    </div>
                                    <div className="cars-filter-item-linediv"></div>
                                    {filterType.brands.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.title.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        } 
                                    }).map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setBrands( [true, item.brand_id, item.brand_model_group.brand.title] ) } >
                                                {item.brand_model_group.brand.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={brands_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Brands{" "}
                    <FaTimes
                      onClick={() => {
                        setBrandsPopover(!brands_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    <div className="cars-filter-options-item-search input-group">
                      <span className="input-group-text" id="search_bar">
                        <FaSearch />
                      </span>
                      <input
                        id="search3"
                        type="text"
                        className="form-control"
                        placeholder="Search brands..."
                        aria-label="Search"
                        aria-describedby="search_bar"
                        autocomplete="off"
                        onChange={(e) => setBrandsQuery(e.target.value)}
                      />
                    </div>
                    {filterType.brands
                      .filter((item) => {
                        if (brands_query === "") {
                          return item;
                        } else if (
                          item.brand_model_group.brand.title
                            .toLowerCase()
                            .includes(brands_query.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, i) => (
                        <button
                          key={i}
                          className={`${
                            brands
                              ? brands[1] === item.brand_id
                                ? "selectedFilter"
                                : ""
                              : ""
                          }`}
                          onClick={() => {
                            setBrands([
                              true,
                              item.brand_id,
                              item.brand_model_group.brand.title,
                            ]);
                            setBrandsPopover(!brands_Popover);
                          }}
                        >
                          {item.brand_model_group.brand.title}
                        </button>
                      ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setBrandsPopover(!brands_Popover);
                }}
              >
                <p className={`${brands[2] ? "haveFilter" : ""}`}>Brands</p>
                {brands[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{brands[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setBrands([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${colours[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={colours[2] ? "Colour: "+colours[2] : "Colour"} >
                                    <h3>Colour</h3>
                                    {filterType.colours.map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setColours( [true, item.id, item.title] ) } >
                                                {item.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={colours_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Colours{" "}
                    <FaTimes
                      onClick={() => {
                        setColoursPopover(!colours_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    {filterType.colours.map((item, i) => (
                      <button
                        className={`${
                          colours
                            ? colours[1] === item.id
                              ? "selectedFilter"
                              : ""
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setColours([true, item.id, item.title]);
                          setColoursPopover(!colours_Popover);
                        }}
                      >
                        {item.title}
                      </button>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setColoursPopover(!colours_Popover);
                }}
              >
                <p className={`${colours[2] ? "haveFilter" : ""}`}>Colours</p>
                {colours[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{colours[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setColours([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${conditions[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={conditions[2] ? "Condition: "+conditions[2] : "Condition"} >
                                    <h3>Condition</h3>
                                    {filterType.conditons.map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setConditions( [true, item.id, item.title] ) } >
                                                {item.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={conditions_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Condition{" "}
                    <FaTimes
                      onClick={() => {
                        setConditionsPopover(!conditions_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    {filterType.conditons.map((item, i) => (
                      <button
                        className={`${
                          conditions
                            ? conditions[1] === item.id
                              ? "selectedFilter"
                              : ""
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setConditions([true, item.id, item.title]);
                          setConditionsPopover(!conditions_Popover);
                        }}
                      >
                        {item.title}
                      </button>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setConditionsPopover(!conditions_Popover);
                }}
              >
                <p className={`${conditions[2] ? "haveFilter" : ""}`}>
                  Condition
                </p>
                {conditions[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{conditions[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setConditions([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${countries[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={countries[2] ? "Country: "+countries[2] : "Country"} >
                                    <h3>Country</h3>
                                    <div className="cars-filter-options-item-search input-group">
                                        <span className="input-group-text" id="search_bar"><FaSearch /></span>

                                        <input id="search3" type="text" className="form-control" placeholder="Search country..." 
                                            aria-label="Search" aria-describedby="search_bar" autocomplete="off" 
                                            onChange={event => setQuery(event.target.value)} 
                                        />
                                    </div>
                                    <div className="cars-filter-item-linediv"></div>
                                    {filterType.countries.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.country_name.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        } 
                                    }).map((item, i) => 
                                            <Dropdown.Item key={i} onClick={() => setCountries( [true, item.id, item.country_name] ) } >
                                                {item.country_name}
                                            </Dropdown.Item>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={countries_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Country{" "}
                    <FaTimes
                      onClick={() => {
                        setCountriesPopover(!countries_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    <div className="cars-filter-options-item-search input-group">
                      <span className="input-group-text" id="search_bar">
                        <FaSearch />
                      </span>
                      <input
                        id="search3"
                        type="text"
                        className="form-control"
                        placeholder="Search country..."
                        aria-label="Search"
                        aria-describedby="search_bar"
                        autocomplete="off"
                        onChange={(e) => setCountryQuery(e.target.value)}
                      />
                    </div>
                    {filterType.countries
                      .filter((item) => {
                        if (country_query === "") {
                          return item;
                        } else if (
                          item.country_name
                            .toLowerCase()
                            .includes(country_query.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, i) => (
                        <button
                          key={i}
                          className={`${
                            countries
                              ? countries[1] === item.id
                                ? "selectedFilter"
                                : ""
                              : ""
                          }`}
                          onClick={() => {
                            setCountries([true, item.id, item.country_name]);
                            setCountriesPopover(!countries_Popover);
                          }}
                        >
                          {item.country_name}
                        </button>
                      ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setCountriesPopover(!countries_Popover);
                }}
              >
                <p className={`${countries[2] ? "haveFilter" : ""}`}>Country</p>
                {countries[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{countries[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setCountries([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${driven_wheels[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={driven_wheels[2] ? "Driven Wheels: "+driven_wheels[2] : "Driven Wheels"} >
                                    <h3>Driven Wheels</h3>
                                    {filterType.driven_wheels.map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setDrivenWheels( [true, item.id, item.title] ) } >
                                                {item.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={driven_wheels_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Driven Wheels{" "}
                    <FaTimes
                      onClick={() => {
                        setDrivenWheelsPopover(!driven_wheels_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    {filterType.driven_wheels.map((item, i) => (
                      <button
                        className={`${
                          driven_wheels
                            ? driven_wheels[1] === item.id
                              ? "selectedFilter"
                              : ""
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setDrivenWheels([true, item.id, item.title]);
                          setDrivenWheelsPopover(!driven_wheels_Popover);
                        }}
                      >
                        {item.title}
                      </button>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setDrivenWheelsPopover(!driven_wheels_Popover);
                }}
              >
                <p className={`${driven_wheels[2] ? "haveFilter" : ""}`}>
                  Driven Wheels
                </p>
                {driven_wheels[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{driven_wheels[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setDrivenWheels([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${fuel_types[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={fuel_types[2] ? "Fuel Types: "+fuel_types[2] : "Fuel Types"} >
                                    <h3>Fuel Types</h3>
                                    {filterType.fuel_types.map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setFuelTypes( [true, item.id, item.title] ) } >
                                                {item.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={fuel_types_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Fuel Types{" "}
                    <FaTimes
                      onClick={() => {
                        setFuelTypesPopover(!fuel_types_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    {filterType.fuel_types.map((item, i) => (
                      <button
                        className={`${
                          fuel_types
                            ? fuel_types[1] === item.id
                              ? "selectedFilter"
                              : ""
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setFuelTypes([true, item.id, item.title]);
                          setFuelTypesPopover(!fuel_types_Popover);
                        }}
                      >
                        {item.title}
                      </button>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setFuelTypesPopover(!fuel_types_Popover);
                }}
              >
                <p className={`${fuel_types[2] ? "haveFilter" : ""}`}>
                  Fuel Types
                </p>
                {fuel_types[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{fuel_types[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setFuelTypes([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className={`cars-c-1-filter-item ${transmissions[2] ? "haveFilter" : ""}`} id="dropdown-basic-button uncontrolled-tab-example" title={transmissions[2] ? "Transmission: "+transmissions[2] : "Transmission"} >
                                    <h3>Transmissions</h3>
                                    {filterType.transmissions.map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setTransmission( [true, item.id, item.title] ) } >
                                                {item.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}
            <OverlayTrigger
              trigger="click"
              placement="right"
              className="v2-filter-main"
              show={transmissions_Popover}
              overlay={
                <Popover className="v2-filter-popover" id="popover-basic">
                  <Popover.Header as="h3">
                    Transmissions{" "}
                    <FaTimes
                      onClick={() => {
                        setTransmissionPopover(!transmissions_Popover);
                      }}
                    />
                  </Popover.Header>
                  <Popover.Body>
                    {filterType.transmissions.map((item, i) => (
                      <button
                        className={`${
                          transmissions
                            ? transmissions[1] === item.id
                              ? "selectedFilter"
                              : ""
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          setTransmission([true, item.id, item.title]);
                          setTransmissionPopover(!transmissions_Popover);
                        }}
                      >
                        {item.title}
                      </button>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                className="v2-filter-triggerBtn"
                onClick={() => {
                  closePopovers();
                  setTransmissionPopover(!transmissions_Popover);
                }}
              >
                <p className={`${transmissions[2] ? "haveFilter" : ""}`}>
                  Transmissions
                </p>
                {transmissions[2] ? (
                  <div className="v2-applied-filter">
                    <div>
                      <span>{transmissions[2]}</span>
                    </div>
                    <FaTimes
                      onClick={() => {
                        setTransmission([]);
                      }}
                    />
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </button>
            </OverlayTrigger>

            {/* <DropdownButton className="cars-c-1-filter-item" id="dropdown-basic-button uncontrolled-tab-example" title={model_groups[2] ? "Model Group: "+model_groups[2] : "Model Group"} >
                                    <h3>Model Group</h3>
                                    {cars_Data.map((item, i) => 
                                        <>
                                            <Dropdown.Item key={i} onClick={() => setModelGroups( [true, item.product_attribute.brand_model_group.model_group_id, item.title] ) } >
                                                {item.title}
                                            </Dropdown.Item>
                                        </>
                                        )   
                                    }
                                </DropdownButton> */}

            <DropdownButton
              className={`cars-c-1-filter-item ${
                priceFilter[2] ? "haveFilter" : ""
              }`}
              id="dropdown-basic-button uncontrolled-tab-example"
              title={
                priceFilter[2]
                  ? "Price: " + priceFilter[1] + " to " + priceFilter[2]
                  : "Price"
              }
              onClick={() => closePopovers()}
            >
              <h3>Price (MYR)</h3>
              <div className="cars-c-1-filter-range">
                <Slider
                  range
                  step={1000}
                  min={0}
                  max={9999999}
                  defaultValue={[0, 2500000]}
                  onChange={priceRange_Event}
                  onAfterChange={onAfterChange}
                />
              </div>

              <div className="cars-c-1-filter-details">
                <p>
                  Min. Price:{" "}
                  <span>
                    {minprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </p>
                <p>
                  Max. Price:{" "}
                  <span>
                    {maxprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </p>
              </div>

              <div className="cars-c-1-filter-option">
                <button
                  onClick={() => setMinPrice("0") + setMaxPrice("100000")}
                  className="cars-c-1-filter-option-item"
                  value="30000"
                >
                  Under 100,000
                </button>
                <button
                  onClick={() => setMinPrice("100000") + setMaxPrice("500000")}
                  className="cars-c-1-filter-option-item"
                  value="100000"
                >
                  100,000 - 500,000
                </button>
                <button
                  onClick={() => setMinPrice("500000") + setMaxPrice("1000000")}
                  className="cars-c-1-filter-option-item"
                  value="250000"
                >
                  500,000 - 1,000,000
                </button>
                <button
                  onClick={() =>
                    setMinPrice("1000000") + setMaxPrice("10000000")
                  }
                  className="cars-c-1-filter-option-item"
                  value="250000"
                >
                  Above 1,000,000
                </button>
              </div>
              <button
                className="save-filter-option-btn"
                onClick={() => setPriceFilter([true, minprice, maxprice])}
              >
                Apply
              </button>
            </DropdownButton>

            <DropdownButton
              className={`cars-c-1-filter-item ${
                mileageFilter[2] ? "haveFilter" : ""
              }`}
              id="dropdown-basic-button uncontrolled-tab-example"
              title={
                mileageFilter[2]
                  ? "Mileage: " + mileageFilter[1] + " to " + mileageFilter[2]
                  : "Mileage"
              }
              onClick={() => closePopovers()}
            >
              <h3>Mileage (km)</h3>
              <div className="cars-c-1-filter-range">
                <Slider
                  range
                  step={1000}
                  min={0}
                  max={999999}
                  defaultValue={[0, 250000]}
                  onChange={mileageRange_Event}
                />
              </div>
              <div className="cars-c-1-filter-details">
                <p>
                  Min. Mileage:{" "}
                  <span>
                    {minmileage
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </p>
                <p>
                  Max. Mileage:{" "}
                  <span>
                    {maxmileage
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </p>
              </div>
              <button
                className="save-filter-option-btn"
                onClick={() => setMileageFilter([true, minmileage, maxmileage])}
              >
                Apply
              </button>
            </DropdownButton>

            <DropdownButton
              className={`cars-c-1-filter-item ${
                yearFilter[2] ? "haveFilter" : ""
              }`}
              id="dropdown-basic-button uncontrolled-tab-example"
              title={
                yearFilter[2]
                  ? "Year: " + yearFilter[1] + " to " + yearFilter[2]
                  : "Year"
              }
              onClick={() => closePopovers()}
            >
              <h3>Year</h3>
              <div className="cars-c-1-filter-range">
                <Slider
                  range
                  step={5}
                  min={1900}
                  max={new Date().getFullYear()}
                  defaultValue={[0, new Date().getFullYear()]}
                  onChange={yearRange_Event}
                />
              </div>
              <div className="cars-c-1-filter-details">
                <p>
                  Min. Year: <span>{minyear}</span>
                </p>
                <p>
                  Max. Year: <span>{maxyear}</span>
                </p>
              </div>
              <button
                className="save-filter-option-btn"
                onClick={() => setYearFilter([true, minyear, maxyear])}
              >
                Apply
              </button>
            </DropdownButton>

            <button onClick={handleSearch} className="cars-c-1-item-search">
              Search
            </button>
          </div>
        )}
      </div>

      <div className="cars-c-1-item-mobile">
        <div className="cars-c-1-item-header">
          <span>Search Filters</span>
        </div>

        <div className="cars-c-1-item-header-item">
          <div className="cars-c-1-input-search input-group">
            <span className="input-group-text" id="search_bar">
              <FaSearch />
            </span>

            <input
              id="search2"
              type="text"
              className="form-control"
              placeholder={props.item_SearchPlaceholder}
              aria-label="Search"
              aria-describedby="search_bar"
              autocomplete="off"
              onChange={(e) => props.item_SetSearchCars(e.target.value)} // onKeyDown={handleKeyDown}
            />
          </div>

          <button onClick={handleFilterShow} className="cars-c-1-filters-btn">
            Filters
          </button>
        </div>

        {error ? (
          // <div>Error: {error.message}</div>
          <>
            {/* {console.log('error',error.message)} */}
            <div className="prod-content LoadingSpinnerMobile">
              <Alert variant="danger">
                <Alert.Heading className="ErrorRequest_Head">
                  Too many requests!{" "}
                </Alert.Heading>
                <hr />
                <p>Please try again later. </p>
              </Alert>
            </div>
          </>
        ) : !isLoaded ? (
          // <div>Loading...</div>
          <div className="LoadingSpinner LoadingSpinnerMobile">
            <Spinner animation="border" role="status" variant="info">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Modal
              show={mobilefilter}
              onHide={handleFilterClose}
              className="cars-filter-modal"
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header className="cars-filter-modal-header" closeButton>
                <h3>All Filters</h3>
              </Modal.Header>
              <Modal.Body className="cars-filter-modal-body">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <Row>
                    <Col>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="1">Body Type</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="2">Brand</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="3">Colour</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="4">Condition</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="5">Country</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="6">Driven Wheels</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="7">Transmissions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="8">Price</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="9">Mileage</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="10">Year</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>

                    <Col className="cars-filter-modal-col2">
                      <Tab.Content>
                        <Tab.Pane eventKey="1">
                          <div className="cars-filter-modal-item">
                            {filterType.body_types.map((item, i) => (
                              <button
                                key={i}
                                onClick={() =>
                                  setBodyTypes([true, item.id, item.title]) +
                                  setActiveBodyTypes(item.id)
                                }
                                className={
                                  activeBodyTypes == item.id
                                    ? "selected-item"
                                    : ""
                                }
                              >
                                {item.title}
                              </button>
                            ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="2">
                          <div className="cars-filter-modal-item">
                            {filterType.brands.map((item, i) => (
                              <button
                                key={i}
                                onClick={() =>
                                  setBrands([
                                    true,
                                    item.brand_id,
                                    item.brand_model_group.brand.title,
                                  ]) + setActiveBrands(item.brand_id)
                                }
                                className={
                                  activeBrands == item.brand_id
                                    ? "selected-item"
                                    : ""
                                }
                              >
                                {item.brand_model_group.brand.title}
                              </button>
                            ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="3">
                          <div className="cars-filter-modal-item">
                            {filterType.colours.map((item, i) => (
                              <button
                                key={i}
                                onClick={() =>
                                  setColours([true, item.id, item.title]) +
                                  setActiveColours(item.id)
                                }
                                className={
                                  activeColours == item.id
                                    ? "selected-item"
                                    : ""
                                }
                              >
                                {item.title}
                              </button>
                            ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="4">
                          <div className="cars-filter-modal-item cfmi-type-2">
                            {filterType.conditons.map((item, i) => (
                              <button
                                key={i}
                                onClick={() =>
                                  setConditions([true, item.id, item.title]) +
                                  setActiveConditions(item.id)
                                }
                                className={
                                  activeConditions == item.id
                                    ? "cars-filter-var-btn selected-item"
                                    : "cars-filter-var-btn"
                                }
                              >
                                {item.title}
                              </button>
                            ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="5">
                          <div className="cars-filter-modal-item cfmi-type-2">
                            <div className="cars-filter-modal-item-search input-group">
                              <span
                                className="input-group-text"
                                id="search_bar"
                              >
                                <FaSearch />
                              </span>

                              <input
                                id="search4"
                                type="text"
                                className="form-control"
                                placeholder="Search country..."
                                aria-label="Search"
                                aria-describedby="search_bar"
                                autocomplete="off"
                                onChange={(event) =>
                                  setCountryQuery(event.target.value)
                                }
                              />
                            </div>

                            <h6>Country: {activeCountries} </h6>
                            {filterType.countries
                              .filter((item) => {
                                if (country_query === "") {
                                  return item;
                                } else if (
                                  item.country_name
                                    .toLowerCase()
                                    .includes(country_query.toLowerCase())
                                ) {
                                  return item;
                                }
                              })
                              .map((item, i) => (
                                <>
                                  <button
                                    key={i}
                                    onClick={() =>
                                      setCountries([
                                        true,
                                        item.id,
                                        item.country_name,
                                      ]) + setActiveCountries(item.country_name)
                                    }
                                    className={
                                      activeCountries == item.country_name
                                        ? "selected-item"
                                        : ""
                                    }
                                  >
                                    {item.country_name}
                                  </button>
                                </>
                              ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="6">
                          <div className="cars-filter-modal-item">
                            {filterType.driven_wheels.map((item, i) => (
                              <button
                                key={i}
                                onClick={() =>
                                  setDrivenWheels([true, item.id, item.title]) +
                                  setActiveDrivenWheels(item.id)
                                }
                                className={
                                  activeDrivenWheels == item.id
                                    ? "selected-item"
                                    : ""
                                }
                              >
                                {item.title}
                              </button>
                            ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="7">
                          <div className="cars-filter-modal-item">
                            {filterType.transmissions.map((item, i) => (
                              <button
                                key={i}
                                onClick={() =>
                                  setTransmission([true, item.id, item.title]) +
                                  setActiveTransmission(item.id)
                                }
                                className={
                                  activeTransmission == item.id
                                    ? "selected-item"
                                    : ""
                                }
                              >
                                {item.title}
                              </button>
                            ))}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="8">
                          <div className="modal-filter-range-title">
                            Price: <br />
                            <span>
                              {priceFilter[0] === undefined ? (
                                <>RM 0</>
                              ) : (
                                "RM " +
                                priceFilter[1]
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " - " +
                                priceFilter[2]
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              )}
                            </span>
                          </div>

                          <div className="cars-filter-modal-item-2">
                            <Slider
                              range
                              step={1000}
                              min={0}
                              max={9999999}
                              defaultValue={[0, 2500000]}
                              onChange={priceRange_Event}
                              onAfterChange={onAfterChange}
                            />
                            <div className="cars-c-1-filter-details">
                              <p>
                                Min. Price:{" "}
                                <span>
                                  RM{" "}
                                  {minprice
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </p>
                            </div>
                            <div className="cars-c-1-filter-details">
                              <p>
                                Max. Price:{" "}
                                <span>
                                  RM{" "}
                                  {maxprice
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </p>
                            </div>
                            <button
                              className="save-filter-option-btn"
                              onClick={() =>
                                setPriceFilter([true, minprice, maxprice])
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="9">
                          <div className="modal-filter-range-title">
                            Mileage (km): <br />
                            <span>
                              {mileageFilter[0] === undefined ? (
                                <>0 km</>
                              ) : (
                                mileageFilter[1]
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " - " +
                                mileageFilter[2]
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " km"
                              )}
                            </span>
                          </div>
                          <div className="cars-filter-modal-item-2">
                            <Slider
                              range
                              step={1000}
                              min={0}
                              max={999999}
                              defaultValue={[0, 250000]}
                              onChange={mileageRange_Event}
                            />
                            <div className="cars-c-1-filter-details">
                              <p>
                                Min. Mileage:{" "}
                                <span>
                                  {minmileage
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </p>
                            </div>
                            <div className="cars-c-1-filter-details">
                              <p>
                                Max. Mileage:{" "}
                                <span>
                                  {maxmileage
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </p>
                            </div>
                            <button
                              className="save-filter-option-btn"
                              onClick={() =>
                                setMileageFilter([true, minmileage, maxmileage])
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="10">
                          <div className="modal-filter-range-title">
                            Year: <br />
                            <span>
                              {yearFilter[0] === undefined ? (
                                <>-</>
                              ) : (
                                yearFilter[1] + " - " + yearFilter[2]
                              )}
                            </span>
                          </div>
                          <div className="cars-filter-modal-item-2">
                            <Slider
                              range
                              step={5}
                              min={1900}
                              max={new Date().getFullYear()}
                              defaultValue={[0, new Date().getFullYear()]}
                              onChange={yearRange_Event}
                            />
                            <div className="cars-c-1-filter-details">
                              <p>
                                Min. Year: <span>{minyear}</span>
                              </p>
                            </div>
                            <div className="cars-c-1-filter-details">
                              <p>
                                Max. Year: <span>{maxyear}</span>
                              </p>
                            </div>
                            <button
                              className="save-filter-option-btn"
                              onClick={() =>
                                setYearFilter([true, minyear, maxyear])
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Modal.Body>

              <Modal.Footer className="cars-filter-modal-footer">
                <Col className="cars-filter-modal-footer-1"></Col>

                <div className="cars-filter-modal-footer-2">
                  <button onClick={handleReset}>Reset</button>
                  <button onClick={handleSearch}>Apply Filters</button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  );
  // }
};

export default FilterComponent;
