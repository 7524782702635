import React from "react";
import "./Policies.scss";

function RefundPolicy() {
  document.title = "Refund Policy | AutoPit";

  return (
    <>
      <div className="wrap-policies">
        <div className="policies-block"></div>

        <div className="policies-header">
          <h1>Refund Policy</h1>
        </div>

        <div className="policies-container-2">
          <h2>Cancellation of Autopit's Deal:</h2>
          <p>
            Should user decide to terminate user's deal, Autopit kindly requests
            that user provide a written notice. Subsequently, user will be
            eligible for a refund from user's selected deal, the amount of which
            will be determined based on the remaining duration of user's deal,
            provided that no claims have been initiated.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>Refund and Policy Cancellation Procedures:</h2>
          <p>
            For all inquiries regarding the refund process and policy
            termination, Autopit requests that user direct user's written
            notifications to Autopit. These notifications will be forwarded to
            the admin, who will manage the cancellation request and the
            associated refund.
          </p>
        </div>

        <div className="policies-container-2">
          <h2>User's Right to Terminate:</h2>
          <p>
            It is important to note that the user retains the right to terminate
            this policy. In the event of such a decision, User will receive a
            written notification via registered post at least fourteen (14) days
            prior to the termination date. In this circumstance, User will be
            eligible for a premium refund, contingent upon the absence of any
            filed claims before the policy's cancellation.
          </p>
        </div>
      </div>
    </>
  );
}

export default RefundPolicy;
