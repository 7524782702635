import React, { useEffect } from "react";
import "../../App.css";
import "./NotFoundPage.scss";

function NotFoundPage() {
  useEffect(() => {
    document.title = "Page not found | Autopit";
  }, []);

  return (
    <>
      <div className="PageError">
        <h1>404</h1>
        <div>
          <p>Sorry! The page you're looking for cannot be found!</p>
          <a href="/" className="b-to-homepage-btn" rel="noreferrer">
            Back to Home
          </a>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
