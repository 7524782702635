import React, { useState } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import "./Navbar.scss";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";

import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import { HiMenu } from "react-icons/hi";
import { RiAccountCircleLine } from "react-icons/ri";
import { FaGoogle, FaFacebook, FaFacebookF } from "react-icons/fa";
import { message, Input, Form, Select } from "antd";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";

export const NavLink = styled(Link)`
  &.active {
    color: #fff;
    font-weight: bold;
    border-radius: 0 0 10px 10px;
  }

  @media screen and (max-width: 960px) {
    &.active {
      color: #fff;
    }
  }
`;

const Navbar = () => {
  const location = useLocation();
  // console.log(location);
  const [messageApi, contextHolder] = message.useMessage();

  // bg color change on scroll
  const [bgcolor, setBgColor] = useState(false);
  const changeBgColor = () => {
    if (window.scrollY >= 10) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  };
  window.addEventListener("scroll", changeBgColor);

  // mobile nav
  const [showmenu, setShowMenu] = useState(false);
  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => {
    if (showmenu === true) {
      setShowMenu(false);
      setLoginDropdown(false);
    } else {
      setShowMenu(true);
      setLoginDropdown(false);
    }
  };
  // login dropdown
  const [loginDropdown, setLoginDropdown] = useState(false);
  const handleCloseLoginDropdown = () => setLoginDropdown(false);
  const handleShowLoginDropdown = () => {
    if (loginDropdown == true) {
      setLoginDropdown(false);
    } else {
      setLoginDropdown(true);
      setShowMenu(false);
    }
  };

  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

  // login modal
  const [loginModal, setLoginModal] = useState(false);
  const handleCloseLoginModal = () => setLoginModal(false);
  const handleLoginModal = () => {
    setLoginModal(true);
    setRegisterModal(false);
    setLoginDropdown(false);
    setForgotPassModal(false);
  };

  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [showLoginFailed, setShowLoginFailed] = useState(false);

  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginDeviceType, setLoginDeviceType] = useState();

  const login = async (e) => {
    e.preventDefault();

    var successTimeout = null,
      failedTimeout = null;

    // return 0;
    setLoginDeviceType(4);

    try {
      const response = await axios.post(
        "users/token",
        JSON.stringify({
          username: loginUsername,
          password: loginPassword,
          device_type: 4,
          register_token: "",
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const accessToken = response?.data?.token;
      const downloadKey = response?.data?.data.download_key;

      // setCookie( 'accessToken', accessToken, { path: '/', expires: new Date( new Date().getTime() + ( 7*24*60*60*1000 ) ) } );
      setCookie("accessToken", accessToken, {
        path: "/",
        expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      });
      setCookie("downloadKey", downloadKey, {
        path: "/",
        expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      });

      messageApi.open({
        type: "success",
        content: " Login Success",
      });
      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (error) {
      messageApi.open({
        type: "error",
        // content: error.response.data.message,
        content: "Login Failed",
      });
      // console.log('error', error.response.data.message);

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowLoginFailed(false);
      }, 2000);
    }
  };

  // register modal
  const [registerModal, setRegisterModal] = useState(false);
  const handleCloseRegisterModal = () => setRegisterModal(false);
  const handleRegisterModal = () => {
    setRegisterModal(true);
    setLoginModal(false);
    setLoginDropdown(false);
    setForgotPassModal(false);
  };

  const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
  const [showRegisterFailed, setShowRegisterFailed] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");

  const [registerUsername, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState(0);
  const [registerAffiliate, setRegisterAffiliate] = useState("");
  const [registerDeviceType, setRegisterDeviceType] = useState(0);
  const [registerOTPcode, setRegisterOTPCode] = useState(null);

  const [veri_SendBtn, setVeriSendBtn] = useState(false);
  const [regist_step1, setRegistStep1] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [counter, setCounter] = useState(60);

  const socialLogin = async (values) => {
    var successTimeout = null;
    try {
      axios
        .post("users/social", {
          identifier: values.identifier,
          platform: values.platform,
          uuid: values.uuid,
          device_type: 3,
          register_token: "",
        })
        .then((res) => {
          console.log(res, "SOCIAL_LOGIN");
          const accessToken = res?.data?.token;
          setCookie("accessToken", accessToken, {
            path: "/",
            expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
          });
          messageApi.open({
            type: "success",
            content: " Login Success",
          });

          clearTimeout(successTimeout);
          successTimeout = setTimeout(function () {
            window.location.reload();
          }, 2000);
        });
    } catch (error) {
      console.log(error, "ERR_SOCIAL_LOGIN");
      messageApi.open({
        type: "error",
        content: "Login Failed",
      });
    }
  };

  const handleGoogle = async (e) => {
    const provider = await new GoogleAuthProvider();
    return signInWithPopup(auth, provider).then((result) => {
      console.log(result, "GOOGLE");
      if (!result)
        return messageApi.open({ type: "error", content: "Login Failed" });
      socialLogin({
        identifier: result?._tokenResponse.email,
        platform: 2,
        uuid: result?.user?.uid,
      });
    });
  };

  const handleFacebook = async () => {
    const provider = await new FacebookAuthProvider();
    return signInWithPopup(auth, provider).then((result) => {
      console.log(result, "FACEBOOK");
      if (!result)
        return messageApi.open({ type: "error", content: "Login Failed" });
      socialLogin({
        identifier: result?.user?.email,
        platform: 1,
        uuid: result?.user?.uid,
      });
    });
  };

  const regist_requestOTP = async (e) => {
    e.preventDefault();
    if (registerPhoneNumber != 0) {
      try {
        const response = await axios.post(
          "otp",
          { phone_number: parseInt(0 + registerPhoneNumber) },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        console.log(response.data.data.otp_code);
        messageApi.open({
          type: "success",
          content: "Verification code has been sent",
        });
        setRegistStep1(response.data.data);
        setVeriSendBtn(true);
        setCountdown(true);
        var i = counter;
        const timer =
          counter > 0 &&
          setInterval(() => {
            setCounter((counter) => counter - 1);
            i--;
            if (i <= 0) {
              clearInterval(timer);
              setCountdown(false);
              setCounter(60);
            }
          }, 1000);
      } catch (error) {
        // var errors = error.response.data.errors;
        // let errorMessage = '';
        // for( var key of Object.keys( errors ) ) {
        //     errorMessage += errors[key][0] + ' ';
        // }
        // if (errorMessage == "api.phone_number_is_taken") {
        //     messageApi.open({
        //         type: 'error',
        //         content: "Phone number is taken",
        //     });
        // } else {
        //     messageApi.open({
        //         type: 'error',
        //         content: errorMessage,
        //     });
        // }
        if (error?.response?.data?.errors) {
          const firstError = Object.values(error.response?.data.errors)[0][0];
          const splitMessage = firstError.split(".");
          const errorMessage = splitMessage[splitMessage.length - 1]
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          if (firstError) {
            messageApi.open({
              type: "error",
              content: errorMessage,
            });
          } else {
            messageApi.open({
              type: "error",
              content: error.message,
            });
          }
        }
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Phone number is required",
      });
    }
  };
  const register = async (e) => {
    e.preventDefault();
    var successTimeout = null,
      failedTimeout = null;
    setRegisterErrorMessage((pv) => "");
    setRegisterDeviceType(4);

    try {
      const response = await axios.post(
        "users",
        {
          otp_code: registerOTPcode,
          tmp_user: regist_step1.tmp_user,
          username: registerUsername,
          email: registerEmail,
          password: registerPassword,
          phone_number: parseInt(0 + registerPhoneNumber),
          affiliate: registerAffiliate,
          device_type: 4,
          register_token: "",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // setShowRegisterSuccess(true);
      // console.log('device_type', response);
      messageApi.open({
        type: "success",
        content: "Register Success",
      });
      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        setShowRegisterSuccess(false);
        setRegisterModal(false);
        setLoginModal(true);
        setForgotPassModal(false);
      }, 2000);
    } catch (error) {
      if (error?.response?.data?.errors) {
        const firstError = Object.values(error.response?.data.errors)[0][0];
        // const splitMessage = firstError.split(".");
        // const errorMessage = splitMessage[splitMessage.length - 1].split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

        if (firstError) {
          messageApi.open({
            type: "error",
            content: firstError,
          });
        } else {
          messageApi.open({
            type: "error",
            content: error.message,
          });
        }
      }
      // setShowRegisterFailed(true);

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowRegisterFailed(false);
      }, 2000);
    }
  };

  // logout
  const logout = () => {
    messageApi.open({
      type: "loading",
      content: "Logging out",
    });
    setTimeout(() => {
      messageApi.open({
        type: "success",
        content: "Logged out successfully",
      });
      // window.location.reload();
      window.location.href = window.location.href;

      removeCookie("accessToken", { path: "/" });
      removeCookie("downloadKey", { path: "/" });
    }, 2000);
  };

  const showcookies = () => {
    // console.log('cookies', cookies);
  };

  // forgotpassword modal
  const [showForgotFailed, setShowForgotFailed] = useState(false);
  const [forgotErrorMessage, setForgotErrorMessage] = useState("");

  const [forgotpassModal, setForgotPassModal] = useState(false);
  const handleClosePasswordModal = () => setForgotPassModal(false);
  const [fpass_phonenum, setFPassPhoneNumber] = useState("");
  const handlePasswordModal = () => {
    setForgotPassModal(true);
    setLoginModal(false);
    setLoginDropdown(false);
    setRegisterModal(false);
  };
  const [verifyOTP, setVerifyOTP] = useState(true);

  const forgotpassword = async (e) => {
    e.preventDefault();
    var successTimeout = null,
      failedTimeout = null;

    try {
      const response = await axios.post(
        "users/" + "forgot",
        {
          phone_number: parseInt(60 + fpass_phonenum),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log('message',response.data);
      setVerifyOTP(false);
    } catch (error) {
      // console.log('error',error.response.data.errors.phone_number[0]);
      var errors = error.response.data.errors;
      let errorMessage = "";

      if (error.response.data.errors.phone_number[0] === "api.user_not_exist") {
        errorMessage += `User does not exist`;
      } else {
        errorMessage += `Please ensure data is correct`;
      }

      messageApi.open({
        type: "error",
        content: errorMessage,
      });

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowForgotFailed(false);
      }, 2000);
    }
  };
  // verifyOTP
  const [otp_code, setOTPCode] = useState(null);
  const [reset_info, setResetInfo] = useState([]);
  const [otp_errormessage, setOTPErrorMessage] = useState("");
  const [showOTPFailed, setShowOTPFailed] = useState(false);
  const verifyOTPcode = async (e) => {
    e.preventDefault();
    var failedTimeout = null;

    try {
      const response = await axios.post(
        "users/" + "verify",
        {
          phone_number: parseInt(60 + fpass_phonenum),
          otp_code: otp_code,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log('otp_demo',response.data);
      // setResetInfo(response.data);
      setForgotPassModal(false);
      setShowReset(true);
    } catch (error) {
      // console.log('error',error.response)
      // var errors = error.response.data.errors?.otp_code[0];
      var errors = error.response.data.message;
      let errorMessage = errors;

      messageApi.open({
        type: "error",
        content: errorMessage,
      });
      setOTPErrorMessage(errorMessage);
      // setShowOTPFailed(true);

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowOTPFailed(false);
      }, 2000);
    }
  };

  // resetpassword modal
  const [showReset, setShowReset] = useState(false);
  const handleCloseReset = () => setShowReset(false);
  const handleShowReset = () => setShowReset(true);
  const [resetPasswordOne, setResetPasswordOne] = useState("");
  const [resetPasswordTwo, setResetPasswordTwo] = useState("");
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showResetAlert, setShowResetAlert] = useState(false);
  const [reset_message, setResetMessage] = useState("");

  const resetPasswordPost = async (e) => {
    e.preventDefault();

    var successTimeout = null,
      failedTimeout = null;

    try {
      const response = await axios.post(
        "users/" + "reset",
        {
          id: reset_info.user_tmp,
          password: resetPasswordOne,
          password_confirmation: resetPasswordTwo,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log('response',response.data);
      messageApi.open({
        type: "success",
        content: "Reset password success",
      });

      clearTimeout(successTimeout);
      successTimeout = setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (error) {
      // console.log('error',error.response.data.errors?.password[0]);
      var errors = error.response.data.errors?.password[0];
      let errorMessage = errors;
      messageApi.open({
        type: "error",
        content: errorMessage,
      });
      setResetMessage(errorMessage);
      // setShowResetAlert(true);

      clearTimeout(failedTimeout);
      failedTimeout = setTimeout(function () {
        setShowResetAlert(false);
      }, 2000);
    }
  };

  return (
    <>
      {/* <nav className="wrap-navbar"> */}
      <nav
        className={`${bgcolor ? "wrap-navbar nav-bgcolor" : "wrap-navbar"} ${
          location.pathname === "/profile" ? "nav-bgcolor" : ""
        }`}
      >
        {contextHolder}
        {/* <NavMenu > */}
        <div className="navbar-container">
          <div className="logo-container">
            <a href="/" className="navbar-logo" target="_parent">
              <img src="/Autopit-02.png" alt="Autopit" />
            </a>
          </div>

          {/* <ul className={click ? 'nav-menu active' : 'nav-menu'}> */}
          <ul className="nav-menu">
            <li className="nav-item">
              <NavLink to="/" className="nav-links" target="_top">
                Home
              </NavLink>
            </li>

            {/* <li className='nav-item'>
                            <NavLink to='/about-us' className='nav-links' target='_top'>
                                About Us
                            </NavLink>
                        </li> */}

            <li className="nav-item">
              <NavLink to="/cars" className="nav-links" target="_top">
                Cars
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/finance" className="nav-links" target="_top">
                Finance
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/import-car" className="nav-links" target="_top">
                Import Car
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/contact-us" className="nav-links" target="_top">
                Contact Us
              </NavLink>
            </li>
          </ul>

          <div className="nav-account">
            <div className="nav-acc-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  onClick={handleShowLoginDropdown}
                >
                  <RiAccountCircleLine onClick={showcookies} />
                  {/* <RiAccountCircleLine onClick={handleShowLoginDropdown}/> */}
                </Dropdown.Toggle>
              </Dropdown>
            </div>

            <div className="nav-mob-menu">
              <div className="mob-menu-item">
                <HiMenu className="menu-icon-burger" onClick={handleShowMenu} />
              </div>
            </div>
          </div>
        </div>{" "}
        {/*  close navbar-container */}
        {/* </NavMenu> */}
        {cookies?.accessToken ? (
          <Offcanvas
            className="offc-acc-dropdown-menu"
            show={loginDropdown}
            onHide={handleCloseLoginDropdown}
            placement="top"
            name="top"
            scroll="true"
          >
            <button onClick={() => (window.location.href = "/search-results")}>
              <span
                aria-hidden="true"
                data-icon="&#xe907;"
                className="nav-mb-icon-1"
                style={{ margin: "0 10px 0 0", fontSize: "12px" }}
              ></span>
              Search Result
            </button>
            <button onClick={() => (window.location.href = "/profile/orders")}>
              <span
                aria-hidden="true"
                data-icon="&#xe903;"
                className="nav-mb-icon-1"
                style={{ margin: "0 10px 0 0", fontSize: "11px" }}
              ></span>
              My Orders
            </button>
            <button onClick={() => (window.location.href = "/profile")}>
              <span
                aria-hidden="true"
                data-icon="&#xe905;"
                className="nav-mb-icon-1"
                style={{ margin: "0 12px 0 0", fontSize: "12px" }}
              ></span>
              My Profile
            </button>
            <button onClick={logout}>
              <span
                aria-hidden="true"
                data-icon="&#xe916;"
                className="nav-mb-icon-1"
                style={{ margin: "0 12px 0 0", fontSize: "14px" }}
              ></span>
              Logout
            </button>
            {/* <div className="dropdown-linediv"></div> */}
          </Offcanvas>
        ) : (
          <Offcanvas
            className="offc-acc-dropdown-menu"
            show={loginDropdown}
            onHide={handleCloseLoginDropdown}
            placement="top"
            name="top"
            scroll="true"
          >
            <Offcanvas.Body>
              <button onClick={handleLoginModal}>
                <span
                  aria-hidden="true"
                  data-icon="&#xe905;"
                  className="nav-mb-icon-1"
                  style={{ margin: "0 10px 0 0", fontSize: "12px" }}
                ></span>
                Login
              </button>
              <button onClick={handleRegisterModal}>
                <span
                  aria-hidden="true"
                  data-icon="&#xe910;"
                  className="nav-mb-icon-1"
                  style={{ margin: "0 10px 0 0", fontSize: "12px" }}
                ></span>
                Register
              </button>
              <button onClick={handleGoogle}>
                <FaGoogle
                  style={{ margin: "0 10px 0 0", fontSize: "12px" }}
                  size={12}
                />
                Google Login
              </button>
              <button onClick={handleFacebook}>
                <FaFacebookF
                  style={{ margin: "0 10px 0 0", fontSize: "12px" }}
                  size={12}
                />
                Facebook Login
              </button>
            </Offcanvas.Body>
          </Offcanvas>
        )}
        {/* Nav offcanvas  */}
        <Offcanvas
          show={showmenu}
          onHide={handleCloseMenu}
          placement="top"
          className="mob-menu-offcanvas"
        >
          <Offcanvas.Body className="mob-menu-body">
            <NavLink to="/" className="offcanvas-link" target="_top">
              Home
            </NavLink>
            {/* <NavLink to='/about-us' className='offcanvas-link' target="_top">
                            About Us
                        </NavLink> */}
            <NavLink to="/cars" className="offcanvas-link" target="_top">
              Cars
            </NavLink>
            <NavLink to="/finance" className="offcanvas-link" target="_top">
              Finance
            </NavLink>
            <NavLink to="/import-car" className="offcanvas-link" target="_top">
              Import Car
            </NavLink>
            <NavLink to="/contact-us" className="offcanvas-link" target="_top">
              Contact Us
            </NavLink>
          </Offcanvas.Body>
        </Offcanvas>
      </nav>

      <Modal
        show={loginModal}
        onHide={handleCloseLoginModal}
        className="login-modal"
        centered
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Customer Login
            <br />
            <p>Please sign in to continue</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-username">
              <label for="username">Username</label>
              <br />
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Type Username"
                onChange={(e) => {
                  setLoginUsername(e.target.value);
                }}
              />
            </div>

            <div className="login-form-ps">
              <label for="password">Password</label>
              <br />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Type Password"
                onChange={(e) => {
                  setLoginPassword(e.target.value);
                }}
              />
            </div>

            <div className="login-form-etc">
              <button onClick={handleRegisterModal}>Register</button>
              <button onClick={handlePasswordModal}>Forgot Password</button>
            </div>

            <div className="login-buttons">
              <button
                className="login-submit-btn"
                type="submit"
                onClick={login}
              >
                Login
              </button>
              {/* <button className="login-submit-btn" >
                                I am Autopit Agent
                            </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={registerModal}
        onHide={handleCloseRegisterModal}
        className="login-modal"
        centered
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Customer Register
            <br />
            <p>Please register to be a member</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-username">
              <label for="username">Username</label>
              <br />
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Type Username"
                onChange={(e) => {
                  setRegisterUsername(e.target.value);
                }}
              />
            </div>

            <div className="login-form-email">
              <label for="email">Email Address</label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Type Email Address"
                onChange={(e) => {
                  setRegisterEmail(e.target.value);
                }}
              />
            </div>

            <div className="login-form-ps">
              <label for="psw">Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Type Password"
                onChange={(e) => {
                  setRegisterPassword(e.target.value);
                }}
              />
            </div>

            <div className="login-form-phone">
              <label for="phone">Phone Number</label>
              <br />
              <div>
                {/* <Select defaultValue="60" style={{ width: 70, }} dropdownMatchSelectWidth={false} placement={"bottomLeft"}
                                    options={[ {
                                        value: '60',
                                        label: '60',
                                    }, ]}
                                /> */}
                <span>+60</span>
                <Input
                  className="login-form-phone-input"
                  showCount
                  maxLength={10}
                  onChange={(e) => {
                    setRegisterPhoneNumber(e.target.value);
                  }}
                  placeholder="Type Phone Number"
                />
              </div>
            </div>

            <div className="login-form-username">
              <label for="username">Affiliate Code</label>
              <br />
              <input
                type="text"
                id="affiliate"
                name="affiliate"
                placeholder="Type Affiliate Code"
                onChange={(e) => {
                  setRegisterAffiliate(e.target.value);
                }}
              />
            </div>

            <div className={`register-verification`}>
              <div className="register-veri-header">
                <div className="veri-h5">Verification</div>
                <p className="">
                  6 digit code will sent to your registered phone number for
                  verification click/tap sent TAC code.
                </p>
              </div>
              <div className="register-veri-otp">
                <label for="verification">Verification Code</label>
                <br />
                <div>
                  <Input
                    className="login-veri-otp-input"
                    maxLength={6}
                    onChange={(e) => {
                      setRegisterOTPCode(e.target.value);
                    }}
                    placeholder="Type Verification Code"
                  />
                  <button
                    onClick={regist_requestOTP}
                    className={`${
                      veri_SendBtn == false ? "" : "hidden"
                    } login-veri-submit `}
                  >
                    Send Code
                  </button>
                  {counter < 60 ? (
                    <button
                      className={`${
                        veri_SendBtn == false ? "hidden" : ""
                      } login-veri-submit-disabled`}
                    >
                      {counter} seconds
                    </button>
                  ) : (
                    <button
                      onClick={regist_requestOTP}
                      className={`${
                        veri_SendBtn == false ? "hidden" : ""
                      } login-veri-submit`}
                    >
                      Resend Code
                    </button>
                  )}
                </div>
                {/* <p className={`resend-counter ${counter == 60 ? 'hidden' : ''}`}>Resend in {counter} seconds</p> */}
              </div>
            </div>

            <div className="login-form-etc">
              <button onClick={handleLoginModal}>Login</button>
              <button onClick={handlePasswordModal}>Forgot Password</button>
            </div>

            <div className="login-buttons">
              {veri_SendBtn == false ? (
                <div className={`register-disabled-btn`}>Register Now</div>
              ) : (
                <button
                  className={`login-submit-btn`}
                  type="submit"
                  onClick={register}
                  disabled={disabled}
                >
                  Register Now
                </button>
              )}
              {/* <button className="login-submit-btn" type="submit" >
                                Register as Autopit Agent
                            </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={forgotpassModal}
        onHide={handleClosePasswordModal}
        className="login-modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Forgot Password
            <br />
            <p>Reset your password</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-phone">
              <label for="phone">Phone Number</label>
              <br />
              <div>
                {/* +60<input type="tel" id="phone" name="phone" placeholder="Type Phone Number" onChange={(e)=> {setFPassPhoneNumber(e.target.value)}}></input> */}
                +60
                <Input
                  className="login-form-phone-input"
                  showCount
                  maxLength={10}
                  onChange={(e) => {
                    setFPassPhoneNumber(e.target.value);
                  }}
                  placeholder="Type Phone Number"
                />
              </div>
              <span className="fpass-input-info">
                *Request OTP to reset your password
              </span>
            </div>
            <div
              className={verifyOTP ? "hidden login-form-otp" : "login-form-otp"}
            >
              <label for="phone">Enter OTP</label>
              <br />
              <Input
                className="login-form-otp-input"
                showCount
                maxLength={6}
                onChange={(e) => {
                  setOTPCode(e.target.value);
                }}
                placeholder="Type OTP"
              />
              {/* <input type="text" id="otp" name="otp" placeholder="Type OTP" onChange={(e)=> {setOTPCode(e.target.value)}} ></input> */}
            </div>
            <div className="login-form-etc">
              <button onClick={handleLoginModal}>Login</button>
              <button onClick={() => window.location.reload()}>
                Cancel Reset
              </button>
            </div>
            <div className="login-buttons">
              <button
                className={
                  verifyOTP ? "request-otp-btn" : "hidden request-otp-btn"
                }
                type="submit"
                onClick={forgotpassword}
              >
                Request OTP{" "}
              </button>
              <button
                className={
                  verifyOTP ? "hidden verify-otp-btn" : "verify-otp-btn"
                }
                onClick={verifyOTPcode}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showReset}
        onHide={handleCloseReset}
        className="login-modal"
        centered
        backdrop="static"
      >
        <Modal.Header className="login-modal-header" closeButton>
          <Modal.Title>
            Reset Password
            <br />
            <p>Type your new password</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-form-container">
            <div className="login-form-ps">
              <label for="psw">New Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Type New Password"
                onChange={(e) => {
                  setResetPasswordOne(e.target.value);
                }}
              />
            </div>

            <div className="login-form-ps">
              <label for="psw">Confirm New Password</label>
              <br />
              <input
                type="password"
                id="psw"
                name="psw"
                placeholder="Re-enter New Password"
                onChange={(e) => {
                  setResetPasswordTwo(e.target.value);
                }}
              />
            </div>

            <div className="login-form-etc">
              <button onClick={handleLoginModal}>Login</button>
              <button onClick={() => window.location.reload()}>
                Cancel Reset
              </button>
            </div>
            <div className="login-buttons">
              <button type="submit" onClick={resetPasswordPost}>
                Reset Password{" "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
