import React, { useEffect } from "react";
import "../../App.css";
import CarsItem from "../car/Cars_Item";

import { Helmet } from "react-helmet";

function Cars_Page() {
  useEffect(() => {
    document.title = "Cars | AutoPit";
    if (window.scrollY > 0) window.scrollTo(0, 700);
    else window.scrollBy(0, 700);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/cars" />

        <meta name="canonical" content="/cars" />
        <meta property="og:url" content="/cars" />
      </Helmet>

      <CarsItem />
    </>
  );
}

export default Cars_Page;
