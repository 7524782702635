import React, { useEffect } from "react";
import "../../App.css";
import ContactUs from "../ContactUs";

import { Helmet } from "react-helmet";

function ContactUsPage() {
  useEffect(() => {
    document.title = "Contact Us | AutoPit";
    if (window.scrollY > 0) window.scrollTo(0, 600);
    else window.scrollBy(0, 600);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/contact-us" />

        <meta name="canonical" content="/contact-us" />
        <meta property="og:url" content="/contact-us" />
      </Helmet>

      <ContactUs />
    </>
  );
}

export default ContactUsPage;
