import React, { useEffect } from "react";
import "../../App.css";
import ImportCarContent from "../import-car/ImportCarJapan";

import { Helmet } from "react-helmet";

function ImportCarJapan() {
  useEffect(() => {
    document.title = "Import Car | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/import-car-country" />

        <meta name="canonical" content="/import-car-country" />
        <meta property="og:url" content="/import-car-country" />
      </Helmet>

      <ImportCarContent />
    </>
  );
}

export default ImportCarJapan;
