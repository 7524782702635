import React, { useEffect } from "react";
import "../../App.css";
import { Helmet } from "react-helmet";
// import MyProfileItem from '../profile/Profile_Item';
import MyProfileItem2 from "../profile/EditProfile_v2";

function MyProfilePage() {
  useEffect(() => {
    document.title = "My Profile | AutoPit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/profile" />

        <meta name="canonical" content="/profile" />
        <meta property="og:url" content="/profile" />
      </Helmet>

      <MyProfileItem2 />
    </>
  );
}

export default MyProfilePage;
