import React, { useEffect } from "react";
import "../../App.css";
import "./Finance.scss";
import { HiChevronDown } from "react-icons/hi";

function CarValueMarket() {
  return (
    <>
      <div className="fin-tab-pane-1">
        <div className="fin-tab-pane-2-header">
          <h3>Car Value Market</h3>
        </div>

        <div className="fin-tab-pane-1-content">
          <div className="fin-tab-pane-1-input">
            <select id="type">
              <option value="type" selected disabled>
                Select Country
              </option>
              <option value="type">Country 1</option>
              <option value="type">Country 2</option>
            </select>
          </div>

          <div className="fin-tab-pane-1-type1">
            <div className="fin-tab-pane-1-type1-input">
              <span>Bidding</span>
            </div>
            <div className="fin-tab-pane-1-type1-input">
              <span>Asnet, Goonet & One Price</span>
            </div>
          </div>

          <div className="fin-tab-pane-1-linediv"></div>

          <div className="fin-tab-pane-1-input">
            <select id="brand">
              <option value="brand" selected disabled>
                Brand{" "}
              </option>
              <option value="audi">Audi</option>
              <option value="bmw">BMW</option>
            </select>
          </div>

          <div className="fin-tab-pane-1-input">
            <select id="model">
              <option value="model" selected disabled>
                Model
              </option>
              <option value="model">Model 1</option>
              <option value="model">Model 2</option>
            </select>
          </div>

          <div className="fin-tab-pane-1-input">
            <select id="type">
              <option value="type" selected disabled>
                Vehicle Type
              </option>
              <option value="type">Type 1</option>
              <option value="type">Type 2</option>
            </select>
          </div>

          <div className="fin-tab-pane-1-type1">
            <div className="fin-tab-pane-1-type1-input">
              <span>JPN</span>
              <input type="number" placeholder="Price"></input>
            </div>
            <div className="fin-tab-pane-1-type1-input">
              <span>MYR</span>
              <input type="number" placeholder="Price"></input>
            </div>
          </div>

          <div className="fin-tab-pane-1-linediv"></div>

          <div className="fin-tab-pane-1-input">
            <select id="month">
              <option value="type" selected disabled>
                Select Month
              </option>
              <option value="type">January</option>
              <option value="type">February</option>
              <option value="type">March</option>
              <option value="type">April</option>
              <option value="type">May</option>
              <option value="type">June</option>
              <option value="type">July</option>
              <option value="type">August</option>
              <option value="type">September</option>
              <option value="type">October</option>
              <option value="type">November</option>
              <option value="type">December</option>
            </select>
          </div>

          <div className="fin-tab-pane-1-input">
            <select id="year">
              <option value="type" selected disabled>
                Select Year
              </option>
              <option value="type">2022</option>
            </select>
          </div>

          <button className="fin-tab-pane-calculate-btn">Calculate</button>
        </div>
      </div>
    </>
  );
}

export default CarValueMarket;
