import React, { useEffect } from "react";
import "../../App.css";
import Landing from "../Landing";

import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    document.title = "Home | Autopit";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="/" />

        <meta name="canonical" content="/" />
        <meta property="og:url" content="/" />
      </Helmet>

      <Landing />
    </>
  );
}

export default Home;
