import React, { useEffect } from "react";
import "../../App.css";
import "./Import.css";
import ImportResult2Component from "./ImportResult2Component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// main
function ImportResult2() {
  return (
    <>
      <div className="wrap-fin-hero">
        <div className="fin-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-fin">
        <div className="fin-container-1">
          <div className="fin-header">
            <h2>Finance</h2>
          </div>
          <ImportResult2Component />
        </div>

        <div className="fin-container-2">
          <h3>Useful Links</h3>

          <div className="fin-cont-2-item">
            <div className="fin-cont-2-item-1">
              <a href="https://scrut.my/" target="_blank" rel="noreferrer">
                <h4>Japan Auction History Checker</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="https://www.vehiclecheck.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                <h4>HPI Check</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="https://www.oto.my/tool/car-market-value-guide/"
                target="_blank"
                rel="noreferrer"
              >
                <h4>Car Market Value Guide</h4>
              </a>
            </div>
            <div className="fin-cont-2-item-1">
              <a
                href="http://www.jpj.my/JPJ_Latest_Number_Plates.htm"
                target="_blank"
                rel="noreferrer"
              >
                <h4>JPJ Car Plate Checker Calculators</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportResult2;
