import React, { useState } from "react";
import "../App.css";
import "./ContactUs.scss";

import Form from "react-bootstrap/Form";
import axios from "../api/axios";
import { message } from "antd";

function ContactUs() {
  const headers_default = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-localization": "en",
  };
  const car_1 = require("../images/BG-26.png");

  const [validated, setValidated] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    // if the form is valid, send the data to the backend
    if (form.checkValidity() === true) {
      try {
        axios
          .post("misc/contact-us", {
            first_name: e.target[0].value,
            last_name: e.target[1].value,
            email: e.target[2].value,
            phone_number: e.target[3].value,
            message: e.target[4].value,
          })
          .then((res) => {
            console.log(res);
            // clear the form
            setValidated(false);
            form.reset();

            messageApi.success(
              "Thank you for contacting us. We will get back to you soon."
            );
          });
      } catch (error) {
        console.log(error, "CONTACT_US");
      }
    }

    setValidated(true);
  };

  return (
    <>
      {contextHolder}
      <div className="wrap-contus-hero">
        <div className="contus-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-contus">
        <div className="contus-header">
          <h2>Contact Us</h2>
        </div>

        <div className="contus-container-1">
          <div className="contus-item-1">
            {/* <form className="contus-form-container">
                            <input type="text" name="fname" placeholder="First Name" className="contus-form-input-1" required />
                            
                            <input type="text" name="lname" placeholder="Last Name" className="contus-form-input-1" required />
                            
                            <input type="email" name="email" placeholder="E-mail" className="contus-form-input-1" required />
                            
                            <input type="tel "name="phone" placeholder="Phone Number" className="contus-form-input-1" />

                            <textarea type="text" id="message" name="message" placeholder="Message" required/>

                            <button type="submit" value="Submit">Submit</button>
                        </form> */}
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="contus-form-container"
            >
              <Form.Group md="4" controlId="validationCustom01">
                {/* <Form.Label>First name</Form.Label> */}
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  defaultValue=""
                  className="contus-form-input-1"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your first name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group md="4" controlId="validationCustom02">
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  defaultValue=""
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your last name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group md="6" controlId="validationCustom03">
                <Form.Control type="email" placeholder="E-mail" required />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group md="3" controlId="validationCustom04">
                <Form.Control
                  type="tel"
                  placeholder="Phone Number"
                  // pattern can be 6012-34567890 or 012-34567890. Max 10-11 digits
                  pattern="^(601[0-9]{1}-?[0-9]{7,8}|01[0-9]{1}-?[0-9]{7,8})$"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid phone number (Format: 6012-3456789 or
                  012-3456789).
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group md="3" controlId="validationCustom05">
                <Form.Control
                  as="textarea"
                  placeholder="Your message"
                  rows={3}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please write a message.
                </Form.Control.Feedback>
              </Form.Group>

              <button type="submit">Submit</button>
            </Form>
          </div>

          <div className="contus-item-2">
            <img src={car_1} alt="Contact Us - AutoPit" />
          </div>
        </div>

        <div className="contus-container-2">
          <p>
            Leave us your details, and our friendly salespeople will contact you
            shortly.
          </p>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
