import React, { useEffect, useState } from "react";
import "../../App.css";
import "./MyOrder_Item.scss";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";

import { GrLocation } from "react-icons/gr";
import Accordion from "react-bootstrap/Accordion";
import { Button, Empty, Pagination } from "antd";
// import Pagination from 'react-bootstrap/Pagination';

const OrderStatus = (props) => {
  // images
  const attri_1 = require("../../images/BG-29.png");
  const attri_2 = require("../../images/BG-30.png");
  const attri_3 = require("../../images/BG-31.png");
  const attri_4 = require("../../images/BG-32.png");
  const attri_5 = require("../../images/BG-33.png");
  const attri_6 = require("../../images/BG-34.png");
  const attri_7 = require("../../images/BG-35.png");
  return (
    <>
      <Accordion.Item eventKey={props.item_eventKey}>
        <Accordion.Header className="myord-accord-header">
          <div className="myord-a-h-item-1">
            <p>NO: {props.item_orderNumber}</p>
            <div
              className={
                "myord-order-status" + " " + props.item_orderStatusStyle
              }
            >
              {props.item_orderStatus}
            </div>
          </div>

          <div className="myord-a-h-item-2">
            <figure>
              <img src={props.item_imgsrc} alt={props.item_imgalttext} />
            </figure>

            <div className="myord-a-h-item-2-1">
              <p>{props.item_carBrand}</p>
              <h3>{props.item_carTitle}</h3>
              <div className="myord-a-h-item-2-1-country">
                <GrLocation className="myord-a-h-item-2-1-country-icon" />
                {props.item_carCountry}
              </div>
            </div>
          </div>
          <div className="myord-a-h-linediv" />

          <div className="myord-a-h-item-3">
            <div className="myord-a-h-item-3-1">
              <p className="myord-a-h-item-3-1-p1">Car Price</p>
              <p className="myord-a-h-item-3-1-p1">RM {props.item_carPrice}</p>
            </div>
            <div className="myord-a-h-item-3-1">
              <p className="myord-a-h-item-3-1-p1">Grand Total</p>
              <p className="myord-a-h-item-3-1-p1">
                RM {props.item_carGrandTotle}
              </p>
            </div>
            <div className="myord-a-h-item-3-1">
              <p className="myord-a-h-item-3-1-p1">Deposit Fee</p>
              <p className={"myord-a-h-item-3-1-p2 " + props.item_orderStatus}>
                RM {props.item_carDepositPrice}
              </p>
            </div>
          </div>
        </Accordion.Header>

        <Accordion.Body className="myord-accord-body">
          <div className="myord-item-attribute">
            <div className="myord-attri_item">
              <img src={attri_1} alt={props.order_imgalttext} />

              <p>{props.condition_result}</p>
              <h5>Condition</h5>
            </div>
            <div className="myord-attri_item">
              <img src={attri_2} alt={props.order_imgalttext} />

              <p>{props.manufacture_result}</p>
              <h5>Manufacture Year</h5>
            </div>
            <div className="myord-attri_item">
              <img src={attri_3} alt={props.order_imgalttext} />

              <p>{props.mileage_result}</p>
              <h5>Mileage</h5>
            </div>
            <div className="myord-attri_item">
              <img src={attri_4} alt={props.order_imgalttext} />

              <p>{props.colour_result}</p>
              <h5>Colour</h5>
            </div>
            <div className="myord-attri_item">
              <img src={attri_5} alt={props.order_imgalttext} />

              <p>{props.capacity_result}</p>
              <h5>Engine Capacity</h5>
            </div>
            <div className="myord-attri_item">
              <img src={attri_6} alt={props.order_imgalttext} />

              <p>{props.transmission_result}</p>
              <h5>Transmission</h5>
            </div>
            <div className="myord-attri_item">
              <img src={attri_7} alt={props.order_imgalttext} />

              <p>{props.seats_result}</p>
              <h5>Car Seats</h5>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

// main
function MyOrders() {
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const [orders, setOrders] = useState([]);

  const [apiOrderStatus, setOrderStatus] = useState([]);

  const getOrderItems = async () => {
    try {
      const response = await axios.get("orders" + "?per_page=50", {
        headers: {
          Authorization: "Bearer " + cookies.accessToken,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      setOrders(response.data.data);
      console.log("response", response.data.data);
      // console.log('orders', response.data.data );
      console.log("apiOrderStatus", apiOrderStatus);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOrderItems();
    setOrderStatus(orders.map((item) => item.status));
  }, []);

  // pagination
  // react-bootstrap
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 1;
  // const pageCount = Math.ceil(orders.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedData = orders.slice(startIndex, endIndex);
  // function handlePageClick(e, pageNumber) {
  //     e.preventDefault();
  //     setCurrentPage(pageNumber);
  // }

  // antd
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };
  const filteredData = orders
    .filter((item) => item.car !== null)
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const showTotal = (total) => `Total ${total} orders`;

  return (
    <>
      <div className="wrap-myord-hero">
        <div className="myord-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-myord">
        <div className="myord-header">
          <div className="myord-breadcrumbs">
            <p className="myord-breadcrumbs-1">Profile </p>
            <span className="myord-breadcrumbs-divider">/</span>
            <h2 className="myord-breadcrumbs-2">My Orders </h2>
          </div>
        </div>

        <div className="myord-container">
          {/* or if car is null */}
          {orders.length === 0 ? (
            <Empty
              imageStyle={{
                height: 90,
              }}
              description={<span>No orders yet</span>}
            />
          ) : (
            <>
              <Accordion
                className="myord-accord-1"
                defaultActiveKey={["0"]}
                flush
              >
                {/* {orders.map((item, i) => ( */}
                {/* {paginatedData.map((item, i) => ( */}
                {filteredData.map((item, i) =>
                  item.status == "to_ship" ? (
                    <OrderStatus
                      key={i}
                      item_eventKey={i}
                      item_orderNumber={item.reference}
                      item_orderStatus="To Ship"
                      item_orderStatusStyle="to_ship"
                      item_imgsrc={item?.car?.thumbnail}
                      item_imgalttext={item?.car?.title + " | Autopit"}
                      // item_carBrand={item?.car.product_attribute?.}
                      item_carTitle={item?.car?.title}
                      item_carCountry={
                        item?.car.product_attribute?.country.iso_alpha2_code +
                        ", " +
                        item?.car.product_attribute?.country.country_name
                      }
                      item_carPrice={item?.car_price}
                      item_carDepositPrice={item?.deposit_amount}
                      item_carGrandTotle={item?.grand_total}
                      order_imgalttext={item?.car.title + " | Autopit"}
                      condition_result={
                        item?.car.product_attribute?.condition.title
                      }
                      manufacture_result={
                        item?.car.product_attribute?.manufacture_year
                      }
                      mileage_result={item?.car?.product_attribute?.mileage}
                      colour_result={item?.car?.product_attribute?.colour.title}
                      capacity_result={
                        item?.car?.product_attribute?.engine_capacity
                      }
                      transmission_result={
                        item?.car?.product_attribute?.transmission.title
                      }
                      seats_result={item?.car?.product_attribute?.seats}
                    />
                  ) : item.status == "pending" ? (
                    <OrderStatus
                      key={i}
                      item_eventKey={i}
                      item_orderNumber={item.reference}
                      item_orderStatus="pending"
                      item_orderStatusStyle="pending"
                      item_imgsrc={item?.car?.thumbnail}
                      item_imgalttext={item?.car?.title + " | Autopit"}
                      // item_carBrand={item?.car.product_attribute?.}
                      item_carTitle={item?.car?.title}
                      item_carCountry={
                        item?.car.product_attribute?.country.iso_alpha2_code +
                        ", " +
                        item?.car.product_attribute?.country.country_name
                      }
                      item_carPrice={item.car_price}
                      item_carDepositPrice={item.deposit_amount}
                      item_carGrandTotle={item.grand_total}
                      order_imgalttext={item?.car?.title + " | Autopit"}
                      condition_result={
                        item?.car?.product_attribute?.condition.title
                      }
                      manufacture_result={
                        item?.car?.product_attribute?.manufacture_year
                      }
                      mileage_result={item?.car?.product_attribute?.mileage}
                      colour_result={item?.car?.product_attribute?.colour.title}
                      capacity_result={
                        item?.car?.product_attribute?.engine_capacity
                      }
                      transmission_result={
                        item?.car?.product_attribute?.transmission.title
                      }
                      seats_result={item?.car?.product_attribute?.seats}
                    />
                  ) : item.status == "completed" ? (
                    <OrderStatus
                      key={i}
                      item_eventKey={i}
                      item_orderNumber={item.reference}
                      item_orderStatus="completed"
                      item_orderStatusStyle="completed"
                      item_imgsrc={item?.car.thumbnail}
                      item_imgalttext={item?.car.title + " | Autopit"}
                      // item_carBrand={item?.car.product_attribute?.}
                      item_carTitle={item?.car.title}
                      item_carCountry={
                        item?.car.product_attribute?.country.iso_alpha2_code +
                        ", " +
                        item?.car.product_attribute?.country.country_name
                      }
                      item_carPrice={item.car_price}
                      item_carDepositPrice={item.deposit_amount}
                      item_carGrandTotle={item.grand_total}
                      order_imgalttext={item?.car.title + " | Autopit"}
                      condition_result={
                        item?.car.product_attribute?.condition.title
                      }
                      manufacture_result={
                        item?.car.product_attribute?.manufacture_year
                      }
                      mileage_result={item?.car.product_attribute?.mileage}
                      colour_result={item?.car.product_attribute?.colour.title}
                      capacity_result={
                        item?.car.product_attribute?.engine_capacity
                      }
                      transmission_result={
                        item?.car.product_attribute?.transmission.title
                      }
                      seats_result={item?.car.product_attribute?.seats}
                    />
                  ) : (
                    <OrderStatus
                      key={i}
                      item_eventKey={i}
                      item_orderNumber={item.reference}
                      item_orderStatus="cancelled"
                      item_orderStatusStyle="cancelled"
                      item_imgsrc={item?.car.thumbnail}
                      item_imgalttext={item?.car.title + " | Autopit"}
                      // item_carBrand={item?.car.product_attribute?.}
                      item_carTitle={item?.car.title}
                      item_carCountry={
                        item?.car.product_attribute?.country.iso_alpha2_code +
                        ", " +
                        item?.car.product_attribute?.country.country_name
                      }
                      item_carPrice={item.car_price}
                      item_carDepositPrice={item.deposit_amount}
                      item_carGrandTotle={item.grand_total}
                      order_imgalttext={item?.car.title + " | Autopit"}
                      condition_result={
                        item?.car.product_attribute?.condition.title
                      }
                      manufacture_result={
                        item?.car.product_attribute?.manufacture_year
                      }
                      mileage_result={item?.car.product_attribute?.mileage}
                      colour_result={item?.car.product_attribute?.colour.title}
                      capacity_result={
                        item?.car.product_attribute?.engine_capacity
                      }
                      transmission_result={
                        item?.car.product_attribute?.transmission.title
                      }
                      seats_result={item?.car.product_attribute?.seats}
                    />
                  )
                )}
              </Accordion>
            </>
          )}

          {orders.length === 0 ? (
            <></>
          ) : (
            <Pagination
              className="orders-antd-pagination"
              simple
              current={currentPage}
              onChange={handlePageChange}
              pageSize={1}
              onShowSizeChange={handlePageSizeChange}
              total={orders.length}
              // showTotal={showTotal}
            />
          )}

          {/* <Pagination>
                        <Pagination.Prev onClick={e => handlePageClick(e, currentPage - 1)} />

                        {Array.from({ length: pageCount }, (_, i) => (
                            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={e => handlePageClick(e, i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next onClick={e => handlePageClick(e, currentPage + 1)}/>
                    </Pagination> */}
        </div>
      </div>
    </>
  );
}

export default MyOrders;

// template
// <Accordion className="myord-accord-1" defaultActiveKey={['0']} flush>
//     <OrderStatus
//         item_eventKey={0}
//         item_orderNumber="111111"
//         item_orderStatus="Pending"
//         item_orderStatusStyle="pending"
//         item_imgsrc="https://api.autopitonline.com/storage/products/eF881puqlqFpa2YnGCmsnzMFYGYwfcArMWAFJb5v.jpg"
//         item_imgalttext="Toyota | Autopit"
//         item_carBrand="Toyota"
//         item_carTitle="86 GT LIMITED HIGH PERFORMANCE PACKAGE (DBA-ZN6)"
//         item_carCountry="JP, Japan"
//         item_carPrice="250000"
//         item_carDepositPrice="50000"

//         order_imgalttext="86 GT LIMITED HIGH PERFORMANCE PACKAGE (DBA-ZN6) | My Order Autopit"
//         condition_result="Recon Car"
//         manufacture_result="2018"
//         mileage_result="8921"
//         colour_result="White"
//         capacity_result="1998"
//         transmission_result="Automatic"
//         seats_result="2"
//     />
// </Accordion>
