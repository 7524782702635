import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import "./Import.css";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

// main
function ImportCar() {
  const japan_flag = require("../../images/BG 43.png");
  const uk_flag = require("../../images/uk_flag.png");
  const [blink, setBlink] = useState(false);
  const box = useRef(null);

  useEffect(() => {
    function blinkBox() {
      box.current.classList.add("blink");
      setTimeout(() => {
        box.current.classList.remove("blink");
      }, 700);
    }

    // if the blink state is true, blink the box
    if (blink) {
      blinkBox();
      setTimeout(() => {
        blinkBox();
      }, 900);
      setBlink(false);
    }
  }, [blink]);

  return (
    <>
      <div className="wrap-fin-hero">
        <div className="fin-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-fin">
        <div className="fin-container-1">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="fin-con-1-tab-row">
              <Col sm={12}>
                <div className="fin-header">
                  <h2>CAR IMPORT</h2>
                </div>
                <Nav
                  variant="pills"
                  className="flex gap-x-4 justify-between flex-nowrap mobile-flex-wrap"
                >
                  <Nav.Item className="fin-con-1-nav-item w-full">
                    <Nav.Link
                      href="/import-car-japan"
                      className="pills_tab"
                      eventKey="first"
                    >
                      <img
                        src={japan_flag}
                        alt="Japan flag"
                        className=""
                        width={200}
                        height={120}
                      />
                      <h4 className="import-tab-p">Japan</h4>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="fin-con-1-nav-item w-full">
                    <Nav.Link
                      className="pills_tab"
                      eventKey="second"
                      href="#uk"
                      onClick={() => setBlink(true)}
                    >
                      <img
                        src={uk_flag}
                        alt="UK flag"
                        className=""
                        width={200}
                        height={120}
                      />
                      <h4 className="import-tab-p">United Kingdom</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <div id="uk" />
                </Nav>
                <Tab.Content className="mt-5 mb-5">
                  <Tab.Pane eventKey="second">
                    <div className="import_container" ref={box}>
                      <p className="import_container_content">
                        Browse for your dream car from our partners at{" "}
                        <a
                          href="https://www.autotrader.co.uk"
                          className="import_link"
                          target="_blank"
                        >
                          https://www.autotrader.co.uk.
                        </a>
                        &nbsp; Make sure postcode is set to{" "}
                        <span className="font-bold">HA98NF</span> when browsing
                      </p>
                    </div>
                    <div className="import_container">
                      <p className="import_container_content">
                        Sign up to access our calculator to get a full
                        transparency breakdown of the On The Road Price
                      </p>
                      <p className="import_container_content mt-2">
                        Our calculator can be found at{" "}
                        <a
                          href="/import-calculator"
                          className="import_link"
                          target="_blank"
                        >
                          https://www.autopitonline.com/import-calculator
                        </a>
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default ImportCar;
