import React, { useState, useEffect } from "react";
import "../../App.css";
import "./SearchResult.scss";
import axios from "../../api/axios";
import { useCookies } from "react-cookie";
import { message, Empty, Pagination } from "antd";
import Accordion from "react-bootstrap/Accordion";

function ImportDetailsComponent(props) {
  const right_arrow = require("../../images/right-arrow-01.png");

  return (
    <>
      <Accordion.Item eventKey={props.item_eventKey}>
        <div className="searRe-accord-header">
          <Accordion.Header>
            <h3>{props.ReferenceID}</h3>
          </Accordion.Header>
          <div>
            {props.status === 1 ? (
              <div className="searRe-status-indicator">Pending</div>
            ) : (
              <div className="searRe-status-indicator searRe-status-indicator-2">
                Approved
              </div>
            )}
          </div>
        </div>
        <Accordion.Header className="searRe-accord-header-2">
          <div className={`searRe-accord-header-content`}>
            <div className="searRe-accord-header-content-1">
              <h4 className="searRe-item-title-1">Car Model Specification</h4>
              <p className="searRe-item-desc-1">{props.carModel}</p>
            </div>
            <div className="searRe-accord-header-content-2">
              <h4 className="searRe-item-title-1">Car Age</h4>
              <p className="searRe-item-desc-1">{props.carAge}</p>
            </div>
          </div>
        </Accordion.Header>

        <Accordion.Body className="searRe-accord-body">
          <div className="searRe-accord-body-content-1">
            <div>
              <h4 className="searRe-item-title-1">
                Port arrival Total CIF Fees before clearance
              </h4>
              <p className="searRe-item-desc-1">{props.portArrivalTotal}</p>
            </div>
            <div>
              <h4 className="searRe-item-title-1">
                Tax duty with documentation
              </h4>
              <p className="searRe-item-desc-1">
                {props.taxDutywithDocumentation}
              </p>
            </div>
          </div>
          <div className="searRe-accord-body-content-2">
            <div>
              <h4 className="searRe-item-title-1">Cost Price</h4>
              <p className="searRe-item-desc-1">{props.costPrice}</p>
            </div>
            <div>
              <h4 className="searRe-item-title-1">Service Fees (代购费)</h4>
              <p className="searRe-item-desc-1">{props.serviceFees}</p>
            </div>
          </div>
          <a href={props.fullresultLink}>
            <button className="searRe-link-button">
              <span>Full Details</span>
              <img src={right_arrow} alt="right arrow" width={10} height={10} />
            </button>
          </a>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}

// main
function SearchResult() {
  const [messageApi, contextHolder] = message.useMessage();
  const [cookies, setCookie] = useCookies(["accessToken"]);

  const [import_results, setImportResults] = useState([]);
  const [get_ImportResult, setGetImportResult] = useState(false);
  const [car_ageInfo, setCarAgeInfo] = useState(null);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  useEffect(() => {
    const showValidResults = [];
    const todayDate = new Date();
    async function getImportDetails() {
      try {
        const response = await axios.get(
          `calculators/import/results?per_page=100`,
          {
            headers: {
              Authorization: `Bearer ${cookies.accessToken}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          },
        );
        showValidResults.push(
          ...response.data.data.filter(
            (item) => new Date(item.valid_till) > new Date(),
          ),
        );
        setImportResults(showValidResults);
        setGetImportResult(true);
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }

    if (get_ImportResult === false) {
      getImportDetails();
    }
  }, [get_ImportResult]);

  const filteredData = import_results?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );
  const showTotal = (total) => `Total ${total} orders`;

  return (
    <>
      {contextHolder}
      <div className="wrap-searRe-hero">
        <div className="searRe-hero-container">
          <h1>
            Find your match with <span>AutoPit</span>
          </h1>

          <p>Get your car’s best offer today!</p>
        </div>
      </div>

      <div className="wrap-searRe">
        <div className="searRe-header">
          <div className="searRe-breadcrumbs">
            <h2>Search Results </h2>
          </div>
        </div>

        <div className="searRe-content-1">
          <p className="searRe-footnote">
            *All calculation results are only valid for three days.
          </p>
          <Accordion className="searRe-accord-1" defaultActiveKey={["0"]} flush>
            {import_results?.length > 0 ? (
              filteredData.map((item, i) => (
                <ImportDetailsComponent
                  key={i}
                  item_eventKey={item.id}
                  status={item.status}
                  ReferenceID={item.reference}
                  carModel={item.model_group.title}
                  carAge={` ${
                    (new Date().getFullYear() - item.year) * 12 +
                    (new Date().getMonth() + 1 - parseInt(item.month))
                  } Months Old [ ${item.month} / ${item.year} ] `}
                  portArrivalTotal={`MYR ${(
                    parseFloat(item.converted_price) +
                    parseFloat(item.shipping_price)
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  taxDutywithDocumentation={`MYR ${parseFloat(item.duty_price)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  costPrice={`MYR ${(
                    parseFloat(item.converted_price) +
                    parseFloat(item.shipping_price) +
                    parseFloat(item.duty_price)
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  // costPrice={`MYR ${ parseFloat(item.final_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }`}
                  serviceFees={`${parseInt(item.service_charge_rate).toFixed(
                    0,
                  )}%`}
                  fullresultLink={`/import-reference?details=${item.reference}`}
                />
              ))
            ) : (
              <Empty
                imageStyle={{
                  height: 90,
                }}
                description={
                  <span className="empty-results-span">No Results</span>
                }
              />
            )}
          </Accordion>

          {import_results.length > 5 && (
            <>
              <Pagination
                className="searRe-antd-pagination"
                // simple
                current={currentPage}
                onChange={handlePageChange}
                pageSize={5}
                onShowSizeChange={handlePageSizeChange}
                total={import_results.length}
                // showTotal={showTotal}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchResult;
